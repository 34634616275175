// Chakra imports
import { Box, Divider, Flex, Heading, useColorModeValue, VStack } from '@chakra-ui/react';
// Custom components
import DefaultAuth from 'layouts/auth/Default';
// Assets
import { getVariable } from 'whitelabel';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import InputForm from 'components/InputForm';
import CustomButton from 'components/CustomButton';
import { NextOnboarding, nextOnboarding } from 'services/api.service';
import { useMutation } from '@tanstack/react-query';

function CredentialsRegister() {
  const loc = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(loc.search);
  const id = params.get('id');

  const textColor = useColorModeValue('brand.700', 'white');
  const { control, handleSubmit } = useForm<{
    password: string;
    masterPassword: string;
  }>();

  const { isLoading: isLoadingSave, mutateAsync: nextOnboardingMutate } = useMutation(
    ['nextOnboarding'],
    (data: NextOnboarding) => nextOnboarding(id, data),
  );
  const handleNext = (data: any) => {
    nextOnboardingMutate({
      step: 'credentials',
      credentials: {
        password: data.password,
        masterPassword: data.masterPassword,
      },
    }).then(() => {
      history.push(`/auth/register/complete?id=${id}`);
    });
  };

  return (
    <DefaultAuth illustrationBackground={getVariable('banner')} image={getVariable('banner')}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        my={{ base: 'auto', lg: '150' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '30px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '0', md: '4vh' }}
        flexDirection="column"
      >
        <Box w="full" alignItems="center" display="flex" flexDirection="column">
          <Heading color={textColor} fontSize="24px" mb="10px" mt={10}>
            Credenciais
          </Heading>
          <VStack spacing={4} alignItems="flex-start" w="100%">
            <InputForm
              control={control}
              label="Senha"
              name="password"
              type="password"
              placeholder="Informe a senha"
              rules={{ required: 'Campo obrigatório' }}
            />
            <Divider />
            <InputForm
              control={control}
              name="masterPassword"
              label="Código de autorização"
              type="password"
              placeholder="Código de 6 dígitos. Somente números"
              rules={{
                pattern: {
                  value: /^[0-9]*$/,
                  message: `O código deve conter apenas números`,
                },
                required: `Informe o código de autorização`,
                minLength: {
                  value: 6,
                  message: `O código deve ter no mínimo 6 caracteres`,
                },
                maxLength: {
                  value: 6,
                  message: `O código deve ter no máximo 6 caracteres`,
                },
              }}
            />
            <CustomButton isLoading={isLoadingSave} onClick={handleSubmit(handleNext)} variant="solid">
              Continuar
            </CustomButton>
          </VStack>
        </Box>
      </Flex>
    </DefaultAuth>
  );
}

export default CredentialsRegister;
