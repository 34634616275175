import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import CustomButton from 'components/CustomButton';
import { useTwoFaHelper } from 'contexts/TwoFaCheckContext';
import useAuth from 'contexts/useAuth';
import { useState } from 'react';
import { deleteCustomer } from 'services/api.service';

const DeleteAccount = () => {
  const { onClose, onOpen, isOpen } = useDisclosure();
  const { updateUserData } = useAuth();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { requestTwoFa } = useTwoFaHelper();

  const handleDelete = async () => {
    const data = await requestTwoFa();

    if (!data?.id) {
      return;
    }

    return deleteCustomer(data.id)
      .then(() => {
        setLoading(true);
        toast({
          title: 'Conta excluída com sucesso',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();
        updateUserData();
      })
      .catch(() => {
        setLoading(false);
        toast({
          title: 'Erro ao excluir conta',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Alert status="error">
        <AlertIcon />
        <AlertTitle>Zona perigosa!</AlertTitle>
        <CustomButton colorScheme="red" variant="outline" onClick={onOpen}>
          Desativar conta
        </CustomButton>
      </Alert>
      {isOpen && (
        <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent pb="var(--safe-area-inset-bottom)">
            <DrawerCloseButton />
            <DrawerHeader>Excluir conta</DrawerHeader>

            <DrawerBody>
              <Alert mb={2} status="error" variant="top-accent" flexDirection="column">
                <AlertIcon />
                <AlertTitle>Tem certeza que deseja excluir sua conta?</AlertTitle>
                <AlertDescription>
                  Esta ação é irreversível e você perderá todos os seus dados. Dados de transações e informações
                  pessoais serão perdidos.
                </AlertDescription>
              </Alert>
              <CustomButton onClick={handleDelete} colorScheme="red" variant="outline" isLoading={loading}>
                Excluir conta
              </CustomButton>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

export default DeleteAccount;
