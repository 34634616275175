// Chakra imports
import { Badge, Box, Flex, HStack, SimpleGrid } from '@chakra-ui/react';

import CustomButton from 'components/CustomButton';
import TableComponent from 'components/TableComponent';
import { queryClient } from 'index';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { findAllRecurrencesClients } from 'services/api.service';
import { maskCurrency } from 'utils/number';
import AddClientWithoutRecurrence from './components/AddClientWithoutRecurrence';
import { RecurrencyPeriodDescription } from './components/recurrency-type';
import ShowPaymentsRecurrence from './components/ShowPayments';

export default function Recurrency() {
  const history = useHistory();
  const columns = useMemo(
    () =>
      [
        {
          Header: 'Client',
          accessor: 'client.name',
        },
        {
          Header: 'Nome do Plano',
          accessor: 'plan.description',
        },
        {
          Header: 'Valor Recorrência',
          accessor: 'plan.amount',
          sortType: 'basic',
          Cell: ({ value }) => {
            return maskCurrency(value);
          },
        },
        {
          Header: 'Período',
          accessor: 'period',
          Cell: ({ value }) => {
            return RecurrencyPeriodDescription[value];
          },
        },
        {
          Header: 'Valor pago',
          accessor: 'totalPaid',
          Cell: ({ value }) => {
            return maskCurrency(value);
          },
        },
        {
          Header: 'Valor pendente',
          accessor: 'totalPending',
          Cell: ({ value }) => {
            return +value > 0 ? <Badge colorScheme="yellow">{maskCurrency(value)}</Badge> : maskCurrency(value);
          },
        },
        {
          accessor: 'lastDate',
          Header: 'Última cobrança',
          Cell: ({ value }) => {
            return value ? DateTime.fromISO(value).toFormat('dd/MM/yyyy HH:mm:ss') : '---';
          },
        },
        {
          accessor: 'nextDate',
          Header: 'Próxima cobrança',
          Cell: ({ value }) => {
            return value ? DateTime.fromISO(value).toFormat('dd/MM/yyyy HH:mm:ss') : '---';
          },
        },
        {
          accessor: 'createdAt',
          Header: 'Data',
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat('dd/MM/yyyy HH:mm:ss');
          },
        },
        {
          Header: 'Ações',
          accessor: 'actions',
        },
      ] as Column[],
    [],
  );

  const onClose = () => {
    queryClient.refetchQueries({
      queryKey: ['findAllRecurrencesClients'],
      type: 'active',
    });
  };

  const actions = (row: any) => (
    <Flex
      align="center"
      justify="flex-start"
      w="100%"
    >
      <ShowPaymentsRecurrence
        clientRecurrencyId={row.id}
        recurrencyName={row.plan.name}
        amount={row.plan.amount}
        oncloseMainModal={onClose}
      />
    </Flex>
  );

  return (
    <Box>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: '20px', xl: '20px' }}
      >
        <TableComponent
          label="Recorrências"
          columnsData={columns}
          right={
            <HStack>
              <AddClientWithoutRecurrence />
              <CustomButton
                onClick={() => history.push('/access/recurrence-plans')}
                colorScheme="brand"
              >
                Planos
              </CustomButton>
            </HStack>
          }
          queryFn={findAllRecurrencesClients}
          queryKey="findAllRecurrencesClients"
          actions={actions}
        />
      </SimpleGrid>
    </Box>
  );
}
