import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import CustomButton from 'components/CustomButton';
import FormRemoteSelectInput, { SelectOptionType } from 'components/FormRemoteSelectInput';
import InputForm from 'components/InputForm';
import SelectForm from 'components/SelectForm';
import { queryClient } from 'index';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { addClientToRecurrence, findAllClients, findAllRecurrences } from 'services/api.service';
import { RecurrencyPeriod } from './recurrency-type';

interface AddForm {
  clientId: SelectOptionType;
  recurrence: SelectOptionType;
  initDate: string;
  period: string;
  endDate: string;
}

const AddClientWithoutRecurrence: React.FC<{}> = () => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, control, reset } = useForm<AddForm>();

  const save = async (dataForm: AddForm) => {
    setLoading(true);
    addClientToRecurrence(dataForm.recurrence.value, {
      clientId: dataForm.clientId.value,
      period: dataForm.period,
      initDate: dataForm.initDate,
      endDate: dataForm.endDate,
    })
      .then(() => {
        onClose();
        queryClient.refetchQueries({
          queryKey: ['findAllRecurrencesClients'],
          type: 'active',
        });
        toast({
          title: 'Sucesso',
          description: 'Recorrência adicionado com sucesso',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: 'Erro',
          description: err?.response?.data?.message || 'Erro ao criar Recorrência',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    reset({});
  }, [reset]);

  return (
    <>
      <CustomButton
        onClick={() => {
          onOpen();
          reset();
        }}
        leftIcon={<FaPlus />}
        variant="outline"
        colorScheme="brand"
      >
        <Text>Vincular Cliente</Text>
      </CustomButton>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Criar Recorrência</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3}>
                <FormRemoteSelectInput
                  control={control}
                  label="Pagador"
                  noOptionsMessage={() => {
                    return <>Nenhuma opção encontrada. </>;
                  }}
                  registerOptions={{
                    required: 'a seleção do pagador é obrigatório',
                  }}
                  loadDataFn={({ value, cb }) =>
                    findAllClients({ filter: value, limit: 20, page: 0 }).then((retorno) => {
                      if (retorno?.registers?.length > 0) {
                        cb(
                          retorno.registers?.map((d: any) => ({
                            label: `${d.name} - ${d.document}`,
                            value: d.id,
                          })),
                        );
                      } else {
                        cb([]);
                      }
                    })
                  }
                  name="clientId"
                />
                <FormRemoteSelectInput
                  control={control}
                  label="Plano de Recorrência"
                  noOptionsMessage={() => {
                    return <>Nenhuma opção encontrada. </>;
                  }}
                  registerOptions={{
                    required: 'a seleção da recorrência é obrigatório',
                  }}
                  loadDataFn={({ value, cb }) =>
                    findAllRecurrences({
                      filter: value,
                      limit: 20,
                      page: 0,
                    }).then((retorno) => {
                      if (retorno?.registers?.length > 0) {
                        cb(
                          retorno.registers?.map((d: any) => ({
                            label: `${d.description}`,
                            value: d.id,
                          })),
                        );
                      } else {
                        cb([]);
                      }
                    })
                  }
                  name="recurrence"
                />
                <SelectForm
                  control={control}
                  name="period"
                  label="Período *"
                  options={[
                    { label: 'Selecione', value: '' },
                    { label: 'Diário', value: RecurrencyPeriod.DAILY },
                    { label: 'Semanal', value: RecurrencyPeriod.WEEKLY },
                    { label: 'Bi-Semanal', value: RecurrencyPeriod.BIWEEKLY },
                    { label: 'Mensal', value: RecurrencyPeriod.MONTHLY },
                    { label: 'Bi-Mensal', value: RecurrencyPeriod.BIMONTHLY },
                    { label: 'Trimestral', value: RecurrencyPeriod.TRIMESTER },
                    { label: 'Quadrimestral', value: RecurrencyPeriod.QUARTERLY },
                    { label: 'Semestral', value: RecurrencyPeriod.SEMIANNUALLY },
                    { label: 'Anual', value: RecurrencyPeriod.ANNUALLY },
                  ]}
                />
                <InputForm
                  control={control}
                  name="initDate"
                  label="Data Inicial *"
                  type="date"
                  rules={{
                    required: 'Campo obrigatório',
                  }}
                />
                <InputForm
                  control={control}
                  name="endDate"
                  label="Data Final *"
                  type="date"
                  rules={{
                    required: 'Campo obrigatório',
                  }}
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button colorScheme="green" isLoading={loading} onClick={handleSubmit(save)}>
                  Adicionar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default AddClientWithoutRecurrence;
