import { useCallback, useState } from 'react';
// form
import { func, string } from 'prop-types';
import { useForm } from 'react-hook-form';
import api from 'services/api.service';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import InputForm from 'components/InputForm';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const UtpRecoverPassword = ({
  recoverId,
  closeModal,
  showChangePassword,
  handleReCaptchaVerify,
}: {
  recoverId: string;
  closeModal: () => void;
  showChangePassword: (recoverId: string) => void;
  handleReCaptchaVerify: (type: string) => Promise<string>;
}) => {
  const [otp, setOtp] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const toast = useToast();

  const submitValided = async (inputValue: string) => {
    const captchaKey = await handleReCaptchaVerify('RECOVERY_CUSTOMER_PASSWORD_VALIDATE');
    setShowLoading(true);
    api
      .post(`auth/customer/recover/validate`, {
        recoverId,
        otp: inputValue,
        captchaKey,
      })
      .then(({ data }) => {
        showChangePassword(data.recoverId);
      })
      .catch((err) => {
        setOtp('');
        toast({
          title: 'Erro',
          isClosable: true,
          colorScheme: 'red',
          duration: 5000,
          position: 'top',
          description: err?.response?.data?.message ?? 'Código inválido',
        });
      })
      .finally(() => setShowLoading(false));
  };

  const resend = async () => {
    const captchaKey = await handleReCaptchaVerify('RECOVERY_CUSTOMER_PASSWORD_RESEND');
    setShowLoading(true);
    api
      .post(`auth/customer/recover/resend`, { recoverId, captchaKey })
      .then(() => {
        toast({
          title: 'Sucesso',
          isClosable: true,
          colorScheme: 'green',
          duration: 5000,
          position: 'top',
          description: 'Código de validação reenviado com sucesso',
        });
      })
      .catch((err) => {
        toast({
          title: 'Erro',
          description: err?.response?.data?.message || 'Erro ao reenviar código',
        });
      })
      .finally(() => setShowLoading(false));
  };

  return (
    !!recoverId && (
      <Modal isOpen={!!recoverId} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Recuperação de senha</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text sx={{ mb: 2 }} variant="body2" color="text.secondary">
              Foi enviado um código de validação para seu e-mail, por favor informe o código abaixo para prosseguir com
              a recuperação de senha.
            </Text>
            <HStack width={'100%'}>
              <PinInput autoFocus value={otp} onChange={setOtp}>
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>

            <Text variant="outlined" size="small" width="100%" textAlign="center" mt={5} onClick={resend}>
              Reenviar código
            </Text>
          </ModalBody>

          <Button
            variant="solid"
            colorScheme="brand"
            type="submit"
            isLoading={showLoading}
            m={5}
            onClick={() => submitValided(otp)}
          >
            Confirmar
          </Button>
        </ModalContent>
      </Modal>
    )
  );
};

const ChangePasswordRecover = ({
  recoverId,
  close,
  handleReCaptchaVerify,
}: {
  recoverId: string;
  close: () => void;
  handleReCaptchaVerify: (type: string) => Promise<string>;
}) => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const defaultValues = {
    password: '',
    confirmPassword: '',
  };

  const methods = useForm<{
    password: string;
    confirmPassword: string;
  }>({
    defaultValues,
  });

  const { handleSubmit, setError } = methods;

  const onSubmit = async (data: any) => {
    const captchaKey = await handleReCaptchaVerify('RECOVERY_CUSTOMER_PASSWORD_CHANGE');
    if (data.password !== data.confirmPassword) {
      setError('confirmPassword', { message: 'As senhas não conferem' });
      return;
    }

    setLoading(true);

    api
      .post(`auth/customer/recover/change`, {
        recoverId,
        password: data.password,
        captchaKey,
      })
      .then(() => {
        toast({
          title: 'Sucesso',
          description: 'Senha alterada com sucesso',
        });
        close();
      })
      .catch((err) => {
        toast({
          title: 'Erro',
          description: err?.response?.data?.message,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal isOpen={!!recoverId} onClose={close}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Recuperação de senha</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={5}>
            <Text variant="body2" color="text.secondary">
              Informe sua nova senha
            </Text>
            <InputForm
              control={methods.control}
              type="password"
              name="password"
              label="Nova senha"
              placeholder="Nova senha"
              rules={{
                required: 'Campo obrigatório',
              }}
            />
            <InputForm
              control={methods.control}
              type="password"
              name="confirmPassword"
              label="Confirme a senha"
              placeholder="Confirme a senha"
              rules={{
                required: 'Campo obrigatório',
              }}
            />

            <Button
              isLoading={loading}
              size="large"
              onClick={handleSubmit(onSubmit)}
              variant="solid"
              type="submit"
              colorScheme="brand"
              w="100%"
              h={'40px'}
              m={5}
            >
              Alterar Senha
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default function RecoverPasswordForm({ onCloseModal }: { onCloseModal: () => void }) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(
    async (type = 'RECOVERY_CUSTOMER_PASSWORD') => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }

      return await executeRecaptcha(type);
      // Do whatever you want with the token
    },
    [executeRecaptcha],
  );
  const [recoverData, setRecoverData] = useState<
    | {
        recoverId: string;
        type: string;
      }
    | undefined
  >();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: any) => {
    const captchaKey = await handleReCaptchaVerify();
    setLoading(true);
    api
      .post(`auth/customer/recover`, { ...data, captchaKey })
      .then(({ data: dataRequest }) => {
        setRecoverData({ ...dataRequest, type: data.tipo });
      })
      .catch((err) => {
        /* showError(
          isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message
        );*/
      })
      .finally(() => setLoading(false));
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{ email: string }>();

  return (
    <>
      {recoverData && recoverData.type !== 'changePassword' && (
        <UtpRecoverPassword
          closeModal={() => setRecoverData(undefined)}
          recoverId={recoverData.recoverId}
          handleReCaptchaVerify={handleReCaptchaVerify}
          showChangePassword={() => setRecoverData({ ...recoverData, type: 'changePassword' })}
        />
      )}
      {recoverData && recoverData.type === 'changePassword' && (
        <ChangePasswordRecover
          recoverId={recoverData.recoverId}
          handleReCaptchaVerify={handleReCaptchaVerify}
          close={() => {
            onCloseModal();
            setRecoverData(undefined);
          }}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <form>
            <VStack spacing={5}>
              <FormControl isInvalid={!!errors.email}>
                <FormLabel>Email:</FormLabel>
                <Input
                  autoComplete="email"
                  placeholder="Digite seu email..."
                  {...register('email', {
                    required: 'Campo obrigatório',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Email inválido',
                    },
                  })}
                />
                <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
              </FormControl>
              <Button
                isLoading={loading}
                onClick={handleSubmit(onSubmit)}
                colorScheme="brand"
                type="submit"
                width="100%"
              >
                Recuperar senha
              </Button>
            </VStack>
          </form>
        </Stack>
      </form>
    </>
  );
}

ChangePasswordRecover.propTypes = {
  recoverId: string.isRequired,
  close: func.isRequired,
};
UtpRecoverPassword.propTypes = {
  recoverId: string.isRequired,
  type: string.isRequired,
  closeModal: func.isRequired,
  showChangePassword: func.isRequired,
};
