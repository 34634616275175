import {
  ButtonGroup,
  Checkbox,
  Highlight,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import CustomButton from 'components/CustomButton';
import InputForm from 'components/InputForm';
import InputNumber from 'components/InputNumber';
import { InstallmentCalculatorModal } from 'components/InstallmentCalculatorModal';
import { queryClient } from 'index';
import { QRCodeCanvas } from 'qrcode.react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { createCharge } from 'services/api.service';
import { validCnpj, validCpf } from 'services/validDocument';
import { onlyNumbers } from 'utils/number';

const CreateCharge: React.FC<{}> = () => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [showPayer, setShowPayer] = React.useState(false);
  const [showIten, setShowIten] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [infoCobranca, setInfoCobranca] = React.useState<any>({});
  const [feeFromPayer, setFeeFromPayer] = React.useState(false);

  const { control, handleSubmit, reset, setValue } = useForm<{
    document: string;
    description: string;
    amount: number;
    name: string;
    dueDate: string;
    itens: {
      description: string;
      amount: number;
    }[];
  }>();

  const createNewCharge = async (data: any) => {
    setLoading(true);
    try {
      const charge = await createCharge({
        ...data,
        document: onlyNumbers(data?.document),
        feeType: feeFromPayer ? 'FROM_PAYER' : 'FROM_CUSTOMER',
      });
      setInfoCobranca(charge);
      queryClient.refetchQueries({
        queryKey: ['findAllCharges'],
        type: 'active',
      });
      toast({
        title: 'Sucesso',
        description: 'Cobrança criada com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: 'Erro',
        description: error?.response?.data?.message || 'Erro ao criar cobrança',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <CustomButton
        colorScheme="brand"
        variant="solid"
        onClick={() => {
          onOpen();
          reset();
          setInfoCobranca(null);
        }}
      >
        Criar cobrança
      </CustomButton>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setInfoCobranca(null);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Criar cobrança</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack
                spacing={5}
                alignItems="center"
              >
                <Text fontWeight="semibold">Dados da cobrança</Text>

                {infoCobranca?.id ? (
                  <>
                    <Text>Cobrança criada com sucesso, compartilhe o link abaixo:</Text>
                    <Text
                      onClick={() => {
                        navigator.clipboard.writeText(infoCobranca?.qrCode);
                        toast({
                          title: 'Sucesso',
                          description: 'Link copiado com sucesso',
                          status: 'success',
                          duration: 3000,
                          isClosable: true,
                        });
                      }}
                      _hover={{
                        cursor: 'pointer',
                      }}
                      w={'100%'}
                    >
                      <Highlight
                        query={infoCobranca?.qrCode}
                        styles={{
                          px: '1',
                          py: '1',
                          width: '100%',
                          bg: 'orange.100',
                          whiteSpace: 'wrap',
                        }}
                      >
                        {infoCobranca?.qrCode}
                      </Highlight>
                    </Text>

                    <Text fontWeight={700}>QR Code:</Text>
                    <QRCodeCanvas
                      size={320}
                      height={'320px'}
                      width={'320px'}
                      value={infoCobranca.qrCode}
                    />
                  </>
                ) : (
                  <>
                    <InputNumber
                      control={control}
                      placeholder="R$ 0,00"
                      label={'Valor da cobrança'}
                      name={`amount`}
                      rules={{
                        required: 'Campo obrigatório',
                        validate: {
                          positive: (value) => value > 0 || 'O valor deve ser maior que 0',
                        },
                      }}
                    />
                    <InputForm
                      control={control}
                      label="Descrição adicional"
                      name="description"
                      placeholder="Pagamento referencia a..."
                      type="text"
                      rules={{
                        required: 'Campo obrigatório',
                      }}
                    ></InputForm>

                    <InputForm
                      control={control}
                      label="Data de vencimento"
                      name="dueDate"
                      type="date"
                    ></InputForm>
                    <VStack w={'100%'}>
                      <HStack w={'100%'}>
                        <ButtonGroup
                          w="100%"
                          isAttached
                        >
                          <CustomButton
                            variant={feeFromPayer ? 'outline' : 'solid'}
                            colorScheme="blue"
                            onClick={() => {
                              setFeeFromPayer(false);
                            }}
                          >
                            Assumir taxas *
                          </CustomButton>
                          <CustomButton
                            variant={feeFromPayer ? 'solid' : 'outline'}
                            colorScheme="blue"
                            onClick={() => {
                              setFeeFromPayer(true);
                            }}
                          >
                            Repassar taxas *
                          </CustomButton>
                        </ButtonGroup>
                        <InstallmentCalculatorModal iconButton onlyOnline />
                      </HStack>
                      <Text
                        fontSize={'sm'}
                        alignSelf={'flex-start'}
                      >
                        * Somente taxas de cartão de crédito
                      </Text>
                    </VStack>

                    <Checkbox
                      width={'100%'}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          setValue('name', '');
                          setValue('document', '');
                        }
                        setShowPayer(e.target.checked);
                      }}
                    >
                      Informar dados do pagador?
                    </Checkbox>
                    {
                      // @ts-ignore
                      showPayer && (
                        <>
                          <Text fontWeight="semibold">Dados do pagador</Text>
                          <InputForm
                            control={control}
                            label="Nome"
                            name="name"
                            type="text"
                            placeholder="Nome completo"
                          ></InputForm>

                          <InputForm
                            control={control}
                            label="CPF/CNPJ"
                            placeholder="000.000.000-00"
                            name="document"
                            type="tel"
                            rules={{
                              required: true,
                              validate: (value) => {
                                value = onlyNumbers(value);
                                if (validCnpj(value)) return true;
                                if (validCpf(value)) return true;
                                return 'Documento não é válido';
                              },
                            }}
                          ></InputForm>
                        </>
                      )
                    }

                    <Checkbox
                      width={'100%'}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          setValue('itens', []);
                        }
                        setShowIten(e.target.checked);
                      }}
                    >
                      Adicionar item a cobrança?
                    </Checkbox>

                    {
                      // @ts-ignore
                      showIten && (
                        <>
                          <Text fontWeight="semibold">Itens</Text>

                          <InputForm
                            control={control}
                            label="Descrição"
                            name="itens[0].description"
                            type="text"
                            placeholder="Descrição do item"
                            rules={{
                              required: 'Campo obrigatório',
                            }}
                          ></InputForm>

                          <InputNumber
                            control={control}
                            placeholder="R$ 0,00"
                            label={'Valor do item'}
                            name={`itens[0].amount`}
                            rules={{
                              required: 'Campo obrigatório',
                              validate: {
                                positive: (value) => value > 0 || 'O valor deve ser maior que 0',
                              },
                            }}
                          />
                        </>
                      )
                    }
                  </>
                )}
              </VStack>
            </ModalBody>

            <ModalFooter>
              {!infoCobranca && (
                <VStack
                  spacing={2}
                  w="100%"
                >
                  <CustomButton
                    colorScheme="green"
                    variant="solid"
                    isLoading={loading}
                    onClick={handleSubmit(createNewCharge)}
                  >
                    Criar cobrança
                  </CustomButton>
                </VStack>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default CreateCharge;
