// Chakra imports
import { Box, Flex, HStack, Icon, IconButton, Image } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import Footer from 'components/footer/FooterAuth';
// Custom components
import { NavLink } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { useLocation } from 'react-use';
import { getVariable } from 'whitelabel';
// Assets

function AuthIllustration(props: { children: JSX.Element | string; illustrationBackground: string }) {
  const { children, illustrationBackground } = props;
  const toBack = () => {
    window.history.back();
  };
  const location = useLocation();
  const isMobile = window.innerWidth < 768;
  // Chakra color mode
  return (
    <Flex position="relative" h="max-content">
      {isMobile && !location.hash.includes('sign-in') && (
        <HStack
          w="calc(100% - 20px)"
          justifyContent="space-between"
          top="calc(var(--safe-area-inset-top) + 10px)"
          left="0px"
          px={2}
          m={0}
          ml={2}
          h={'60px'}
          position="fixed"
          borderRadius="xl"
          borderBottom="1px solid"
          borderColor={'gray.200'}
          backgroundColor="white"
          zIndex={100}
        >
          <IconButton
            aria-label="Toggle color mode"
            icon={<Icon as={FaArrowLeft} />}
            onClick={toBack}
            w="40px"
            colorScheme="brand"
            variant={'ghost'}
          />
          <Image mt={1} src={getVariable('logo')} width="130px" />
          <Box w="40px" />
        </HStack>
      )}
      <Flex
        h={{
          sm: 'initial',
          md: 'unset',
          lg: '100vh',
          xl: '97vh',
        }}
        w="100%"
        maxW={{ md: '66%', lg: '1313px' }}
        mx="auto"
        px={{ lg: '30px', xl: '0px' }}
        ps={{ xl: '70px' }}
        pt="calc(var(--safe-area-inset-top) + 20px)"
        justifyContent="start"
        direction="column"
      >
        <NavLink
          to="/access"
          style={() => ({
            width: 'fit-content',
            marginTop: '40px',
          })}
        ></NavLink>
        {children}
        <Box
          display={{ base: 'none', md: 'block' }}
          h="100%"
          minH="100vh"
          w={{ lg: '50vw', '2xl': '44vw' }}
          position="absolute"
          right="0px"
        >
          <Flex
            bg={`url(${illustrationBackground})`}
            justify="center"
            align="end"
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
          />
        </Box>
        <Footer />
      </Flex>
    </Flex>
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
