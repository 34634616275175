import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Button,
  useDisclosure,
  Tooltip,
  useToast,
  Text,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import InputNumber from 'components/InputNumber';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FaLink } from 'react-icons/fa';
import { realizarLevantamentoApi } from 'services/api.service';

const RealizarLevantamento: React.FC<{
  recurrencyId: string;
  oncloseMainModal: () => void;
}> = ({ recurrencyId, oncloseMainModal }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { mutateAsync: levantamento, isLoading: isLoadingNewRecurrence } = useMutation(realizarLevantamentoApi);

  const { control, handleSubmit, setValue } = useForm<{
    amount: number;
  }>();

  const handleLinkWallet = async ({ amount }: { amount: number }) => {
    try {
      await levantamento({
        recurrencyId: recurrencyId,
        amount,
      });
      toast({
        title: 'Levamentamento com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onClose();
      oncloseMainModal();
    } catch (error) {
      toast({
        title: isAxiosError(error) ? error?.response?.data?.message : 'Ocorreu um erro ao realizar o levantamento',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      setValue('amount', 0);
    }
  }, [isOpen, setValue]);

  return (
    <>
      <Tooltip label="Link com wallet">
        <Button
          onClick={() => {
            onOpen();
          }}
          size="sm"
          rounded="sm"
          variant="outline"
          colorScheme="brand"
        >
          <FaLink />
          Levantamento
        </Button>
      </Tooltip>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={() => {
            onClose();
            oncloseMainModal();
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Realizar Levantamento</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb={6}>
                O Levantamento de saldo é uma operação que retira um valor do saldo da recorrência.
                <br /> Útil para casos em que o saldo da recorrência está incorreto ou para retirar um valor específico
                para pagamentos e ter um controle sobre.
              </Text>
              <InputNumber control={control} name="amount" label="Valor" placeholder="Digite o valor do levantamento" />
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    onClose();
                    oncloseMainModal();
                  }}
                >
                  Fechar
                </Button>
                <Button onClick={handleSubmit(handleLinkWallet)} colorScheme="green" isLoading={isLoadingNewRecurrence}>
                  Realizar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default RealizarLevantamento;
