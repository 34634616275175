import React, { useCallback, useEffect } from 'react';
import { initMercadoPago } from '@mercadopago/sdk-react';
import { CardPayment } from '@mercadopago/sdk-react';
import { ICardPaymentBrickPayer, ICardPaymentFormData } from '@mercadopago/sdk-react/bricks/cardPayment/type';
import { initializePaymentMercadoPago, processPaymentMercadoPago } from 'services/api.service';
import { Button, Text, VStack, useToast } from '@chakra-ui/react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import InputForm from 'components/InputForm';
import InputMaskForm from 'components/InputMask';
import { validCpf } from 'services/validDocument';
import { onlyNumbers } from 'utils/number';
import { useForm } from 'react-hook-form';

interface DadosPessoa {
  name: string;
  document: string;
  email: string;
}

const MercadoPago: React.FC<{
  id: string;
  amount: number;
}> = ({ id, amount }) => {
  const toast = useToast();
  const [initialized, setInitialized] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [information, setInformation] = React.useState<DadosPessoa>(null);

  const { control, handleSubmit } = useForm<DadosPessoa>();

  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    return await executeRecaptcha('REGISTER_MERCADOPAGO');
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  const onSubmit = async (formData: ICardPaymentFormData<ICardPaymentBrickPayer>) => {
    const token = await handleReCaptchaVerify();

    processPaymentMercadoPago(id, {
      ...formData,
      recaptchaToken: token,
      document: formData.payer.identification.number,
      email: information.email,
      name: information.name,
    })
      .then((res) => {
        toast({
          title: 'Pagamento realizado com sucesso',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: err.response?.data?.message ?? 'Erro ao realizar pagamento',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      });
  };
  const onReady = async () => {};

  const onError = async (error) => {
    toast({
      title: error.message ?? 'Erro ao realizar pagamento',
      status: 'error',
      duration: 2000,
      isClosable: true,
    });
  };

  const nextPass = (data: DadosPessoa) => {
    setInformation({ ...data, document: onlyNumbers(data.document) });
  };

  useEffect(() => {
    if (!initialized && information) {
      initializePaymentMercadoPago(id)
        .then((res) => {
          initMercadoPago(res.publicKey, {
            locale: 'pt-BR',
            trackingDisabled: true,
            advancedFraudPrevention: true,
            frontEndStack: 'REACT',
          });
          setInitialized(true);
        })
        .catch((err) => {
          setError(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, information]);

  return (
    <>
      {error && <Text>Erro ao inicializar o pagamento</Text>}
      {information && !initialized && !error && <Text>Carregando...</Text>}
      {!information && (
        <VStack>
          <InputForm
            type="text"
            name="name"
            placeholder="Seu nome"
            control={control}
            label="Seu nome"
            rules={{
              required: 'Campo obrigatório',
            }}
          />
          <InputMaskForm
            type="text"
            name="document"
            placeholder="Seu CPF"
            control={control}
            mask="999.999.999-99"
            label="Seu CPF"
            rules={{
              required: 'Campo obrigatório',
              minLength: {
                value: 11,
                message: 'CPF inválido',
              },
              validate: {
                valid: (value) => {
                  value = onlyNumbers(value);

                  return validCpf(value) || 'Documento inválido';
                },
              },
            }}
          />
          <InputForm
            type="email"
            name="email"
            placeholder="Seu e-mail"
            control={control}
            label="Seu e-mail"
            rules={{
              required: 'Campo obrigatório',
            }}
          />

          <Button
            type="submit"
            onClick={handleSubmit(nextPass)}
            colorScheme="brand"
            size="sm"
            w={{ base: '100%', md: '100%' }}
            mt="8"
          >
            Prosseguir
          </Button>
        </VStack>
      )}
      {initialized && (
        <CardPayment
          customization={{
            paymentMethods: {
              maxInstallments: 10,
            },
          }}
          initialization={{
            amount,
          }}
          onSubmit={onSubmit}
          onReady={onReady}
          onError={onError}
        ></CardPayment>
      )}
    </>
  );
};

export default MercadoPago;
