import { Filesystem, Directory } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';

export const shareFileFromBuffer = async (filename: string, mimeType: string, buffer?: Buffer, blob?: Blob) => {
  try {
    // Convert Buffer to Blob
    if (buffer) {
      blob = new Blob([buffer], { type: mimeType });
    }

    // Convert Blob to Base64
    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64data = reader.result as string;

      // Save the file
      await Filesystem.writeFile({
        path: filename,
        data: base64data.split(',')[1], // Strip the "data:*/*;base64," part
        directory: Directory.Documents,
      });

      // Get the file URI
      const fileUri = await Filesystem.getUri({
        path: filename,
        directory: Directory.Documents,
      });

      // Share the file
      await Share.share({
        title: 'Arquivo',
        url: fileUri.uri,
      });
    };

    reader.readAsDataURL(blob); // Convert Blob to Base64
  } catch (error) {
    console.error('Error sharing file from buffer:', error);
  }
};

export const shareFileBase64 = async (filename: string, base64?: string) => {
  try {
    // Convert Buffer to Blob
    const base64data = base64;
    // Save the file
    await Filesystem.writeFile({
      path: filename,
      data: base64data.split(',')[1], // Strip the "data:*/*;base64," part
      directory: Directory.Documents,
    });

    // Get the file URI
    const fileUri = await Filesystem.getUri({
      path: filename,
      directory: Directory.Documents,
    });

    // Share the file
    await Share.share({
      title: 'Arquivo',
      url: fileUri.uri,
    });
  } catch (error) {
    console.error('Error sharing file from buffer:', error);
  }
};
