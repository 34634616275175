import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  HStack,
  Button,
  useToast,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import CustomButton from 'components/CustomButton';
import InputForm from 'components/InputForm';
import InputMaskForm from 'components/InputMask';
import { queryClient } from 'index';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { createClient } from 'services/api.service';
import { validCpf } from 'services/validDocument';
import { onlyNumbers } from 'utils/number';

export type CreateClientForm = {
  document: string;
  email: string;
  phone: string;
  name: string;
};

const CreateClient: React.FC<{}> = () => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, control, reset } = useForm<CreateClientForm>();

  const save = async (dataForm: CreateClientForm) => {
    setLoading(true);
    createClient({
      ...dataForm,
      document: onlyNumbers(dataForm.document),
      phone: onlyNumbers(dataForm.phone),
    })
      .then(() => {
        onClose();
        queryClient.refetchQueries({
          queryKey: ['findAllClients'],
          type: 'active',
        });
        toast({
          title: 'Sucesso',
          description: 'Cliente adicionado com sucesso',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: 'Erro',
          description: err?.response?.data?.message || 'Erro ao criar Cliente',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    reset({});
  }, [reset]);

  return (
    <>
      <CustomButton
        onClick={() => {
          onOpen();
          reset();
        }}
        leftIcon={<FaPlus />}
        variant="outline"
        colorScheme="brand"
      >
        <Text>Criar Cliente</Text>
      </CustomButton>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Criar Cliente</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3}>
                <InputForm
                  control={control}
                  name="name"
                  label="Nome *"
                  placeholder="Fulano Ciclano"
                  type="text"
                  rules={{
                    required: 'Campo obrigatório',
                  }}
                />
                <InputForm
                  control={control}
                  label="CPF"
                  name="document"
                  size="sm"
                  isRequired={true}
                  type="tel"
                  placeholder="CPF"
                  rules={{
                    validate: (value) => {
                      if (!value) return true;

                      return validCpf(onlyNumbers(value)) || 'CPF inválido';
                    },
                  }}
                  required
                />
                <InputForm control={control} name="email" label="Email" placeholder="cliente@email.com" type="email" />
                <InputMaskForm
                  control={control}
                  name="phone"
                  label="Telefone"
                  type="tel"
                  mask="(99) 99999-9999"
                  placeholder="Telefone"
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button colorScheme="green" isLoading={loading} onClick={handleSubmit(save)}>
                  Adicionar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default CreateClient;
