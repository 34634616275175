// chakra imports
import { Box, Flex, HStack, Image, Stack, Text, Tooltip, useColorModeValue, VStack } from '@chakra-ui/react';
import { InstallmentCalculatorModal } from 'components/InstallmentCalculatorModal';
import Links from 'components/sidebar/components/Links';
import SidebarCard from 'components/sidebar/components/SidebarCard';
import useAuth from 'contexts/useAuth';
import { FaSignOutAlt, FaWhatsapp } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { maskCurrency } from 'utils/number';
import { getVariable } from 'whitelabel';
import LoanCreditBalance from './LoanCreditBalance';

// FUNCTIONS

function SidebarContent(props: { routes: RoutesType[]; closeSidebar: () => void }) {
  const { routes } = props;
  const history = useHistory();
  const { userData, signOut } = useAuth();
  let textColor = useColorModeValue('gray.200', 'gray.200');

  // SIDEBAR
  return (
    <Flex
      direction="column"
      height="100%"
      pt="25px"
      borderRadius="30px"
    >
      <Text
        fontSize={35}
        padding={8}
        color={textColor}
      >
        <VStack mb="5px">
          <Image
            cursor="pointer"
            alt=""
            src={getVariable('logo_inverted')}
            maxWidth={200}
          />
        </VStack>
      </Text>

      <Stack
        direction="column"
        mt="8px"
        mb="auto"
      >
        <VStack
          alignItems="flex-start"
          pl={8}
          mb={8}
          display={{
            base: 'flex',
            md: 'none',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
          >
            <Tooltip
              label="Saldo da Conta"
              hasArrow
            >
              <Text
                color="gray.200"
                fontSize="lg"
                fontWeight="bold"
              >
                Saldo disponível: {maskCurrency(userData.balance)}
              </Text>
            </Tooltip>
            <Tooltip
              label="Em liberação"
              hasArrow
            >
              <Text
                color="gray.200"
                fontSize="md"
              >
                Saldo pendente: {maskCurrency(userData.balancePending)}
              </Text>
            </Tooltip>
            <Tooltip
              label="Saldo Bloqueado"
              hasArrow
            >
              <Text
                color="gray.200"
                fontSize="md"
              >
                Saldo bloqueado: {maskCurrency(userData.amountLocked)}
              </Text>
            </Tooltip>
          </Box>
        </VStack>

        <HStack
          mt={2}
          px="16px"
          w="full"
          borderRadius="12px"
          cursor="pointer"
          pr="34px"
        >
          <LoanCreditBalance />
        </HStack>
        <HStack
          mt={2}
          px="16px"
          w="full"
          borderRadius="12px"
          cursor="pointer"
          pr="34px"
        >
          <InstallmentCalculatorModal />
        </HStack>

        <Box
          ps="20px"
          pe={{ lg: '16px', '2xl': '16px' }}
        >
          <Box
            maxHeight={{
              base: 'calc(100vh - 200px)',
              md: 'calc(100vh - 200px)',
            }}
            overflowY="auto"
          >
            <Links
              routes={routes}
              closeSidebar={props.closeSidebar}
            />
          </Box>
          <Box>
            <HStack
              py="5px"
              ps="10px"
              onClick={() => {
                window.open(getVariable('supportWhatsapp'), '_blank');
              }}
              cursor="pointer"
            >
              <Flex
                w="100%"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  me="18px"
                  alignItems={'center'}
                  display={'flex'}
                  height={'22px'}
                  color={textColor}
                >
                  <FaWhatsapp />
                </Box>
                <Text
                  me="auto"
                  color={textColor}
                  fontWeight="normal"
                >
                  Suporte WhatsApp
                </Text>
              </Flex>
              <Box
                h="36px"
                w="4px"
                bg="transparent"
                borderRadius="5px"
              />
            </HStack>
            <HStack
              py="5px"
              ps="10px"
              onClick={() => signOut(history)}
              cursor="pointer"
            >
              <Flex
                w="100%"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  me="18px"
                  alignItems={'center'}
                  display={'flex'}
                  height={'22px'}
                  color={textColor}
                >
                  <FaSignOutAlt />
                </Box>
                <Text
                  me="auto"
                  color={textColor}
                  fontWeight="normal"
                >
                  Sair
                </Text>
              </Flex>
              <Box
                h="36px"
                w="4px"
                bg="transparent"
                borderRadius="5px"
              />
            </HStack>
          </Box>
        </Box>
      </Stack>

      <Box
        ps="20px"
        pe={{ lg: '16px', '2xl': '20px' }}
        mt="60px"
        mb="40px"
        borderRadius="30px"
      >
        <SidebarCard />
      </Box>
    </Flex>
  );
}

export default SidebarContent;
