import '@capacitor-community/safe-area';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import MasterContext from 'context';
import Charge from 'layouts/charge';
import LinkPage from 'layouts/link';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import './assets/css/App.css';
import AdminLayout from './layouts/access';
import AuthLayout from './layouts/auth';
import theme from './theme/theme';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ChakraProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <React.StrictMode>
        <HashRouter>
          <Switch>
            <Route path={`/charge/:id`} component={Charge} />
            <Route path={`/link/:id`} component={LinkPage} />
            <MasterContext>
              <>
                <Route path={`/auth`} component={AuthLayout} />
                <Route path={`/access`} component={AdminLayout} />
                <Redirect from="/" to="/access" />
              </>
            </MasterContext>
          </Switch>
        </HashRouter>
      </React.StrictMode>
    </QueryClientProvider>
  </ChakraProvider>,
);
