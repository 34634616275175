import {
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from 'index';
import { DateTime } from 'luxon';
import { FaTrash } from 'react-icons/fa';
import { findRecurrencyLink, removePlanFromRecurrencyLink } from 'services/api.service';
import { maskCurrency } from 'utils/number';
import { RecurrencyPeriodDescription } from '../components/recurrency-type';
import AddPlanToLink from './AddPlanToLink';
import EditLinkDetails from './EditLinkDetails';

interface ViewLinkRecurrenceProps {
  isOpen: boolean;
  onClose: () => void;
  linkId: string;
}

const ViewLinkRecurrence: React.FC<ViewLinkRecurrenceProps> = ({ isOpen, onClose, linkId }) => {
  const { data: link } = useQuery({
    queryKey: ['findRecurrencyLink', linkId],
    queryFn: () => findRecurrencyLink(linkId),
    enabled: isOpen,
  });

  const handleRemoveItem = (itemId: string) => {
    removePlanFromRecurrencyLink(linkId, { planId: itemId })
      .then(() => {
        queryClient.invalidateQueries(['findRecurrencyLink', linkId]);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex
            justify="space-between"
            align="center"
          >
            <Text>Detalhes do Link de Recorrência</Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {link && (
            <VStack
              align="stretch"
              spacing={4}
            >
              {link.image && (
                <Flex
                  justifyContent="center"
                  mb={4}
                >
                  {' '}
                  <Image
                    src={link.image}
                    alt={link.title}
                    maxH="200px"
                    objectFit="contain"
                  />
                </Flex>
              )}
              <EditLinkDetails linkId={linkId} />

              <Box>
                <Text fontWeight="bold">Título:</Text>
                <Text>{link.title}</Text>
              </Box>

              {link.description && (
                <Box>
                  <Text fontWeight="bold">Descrição:</Text>
                  <Text>{link.description}</Text>
                </Box>
              )}

              <Box>
                <Text fontWeight="bold">Data de Criação:</Text>
                <Text>{DateTime.fromISO(link.createdAt).toFormat('dd/MM/yyyy HH:mm:ss')}</Text>
              </Box>

              <Box>
                <Text
                  fontWeight="bold"
                  mb={2}
                >
                  Planos Vinculados:
                </Text>
                <AddPlanToLink linkId={linkId} />
                <Table
                  variant="simple"
                  size="sm"
                >
                  <Thead>
                    <Tr>
                      <Th>Descrição</Th>
                      <Th isNumeric>Valor</Th>
                      <Th>Período</Th>
                      <Th width="100px">Ações</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {link.itens.map((item) => (
                      <Tr key={item.id}>
                        <Td>{item.plan.description}</Td>
                        <Td isNumeric>{maskCurrency(Number(item.plan.amount))}</Td>
                        <Td>{RecurrencyPeriodDescription[item.plan.period]}</Td>
                        <Td>
                          <HStack spacing={2}>
                            <Tooltip label="Remover">
                              <IconButton
                                icon={<FaTrash />}
                                aria-label="Remover item"
                                size="sm"
                                colorScheme="red"
                                onClick={() => handleRemoveItem(item.id)}
                              />
                            </Tooltip>
                          </HStack>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ViewLinkRecurrence;
