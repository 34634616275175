export const maskCurrency = (value?: number) =>
  (value || 0).toLocaleString(`pt-br`, {
    style: `currency`,
    currency: `BRL`,
  });

export const unMaskCurrency = (value?: string) => Number((value || `0`).replace(/[^0-9,]/gi, ``).replace(/,/, `.`));

export function calcularParcelasMaximas(valorTotal: number, maxParcelas: number) {
  const parcelaMinima = 5;
  return Math.max(maxParcelas, Math.min(1, Math.floor(valorTotal / parcelaMinima)));
}
