import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import FormLabelCustom from 'components/FormLabel';
import FormRemoteSelectInput from 'components/FormRemoteSelectInput';
import InputNumber from 'components/InputNumber';
import useAuth from 'contexts/useAuth';
import { queryClient } from 'index';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FaPen } from 'react-icons/fa';
import { findAllParticipants, getAccounts, updateWithdrawel } from 'services/api.service';
import { maskCurrency, onlyNumbers } from 'utils/number';
import { Withdrawal } from '..';
import SelectForm from 'components/SelectForm';
import InputForm from 'components/InputForm';
import PixKeyInput from 'components/PixKeyInput';
import { unMaskPixKey } from 'utils/pix';
import { validCpf, validCnpj } from 'services/validDocument';

const EditWithdrawel: React.FC<{
  data: Withdrawal;
}> = ({ data: withdraw }) => {
  const toast = useToast();
  const { userData, updateUserData } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [type, setType] = React.useState<'DICT' | 'MANU' | 'BILLET'>('DICT');
  const [accountId, setAccountId] = React.useState<string | undefined>(withdraw.CustomerAccounts?.id);

  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm<{
    amount: number;
    description: string;
    fee: string;
    account: string;
    agency: string;
    keyPix: string;
    dueDate: string;
    accountId: { value: string; label: string };
  }>({
    defaultValues: {
      amount: +withdraw.amount,
      description: withdraw.description,
      fee: withdraw.fee,
      account: withdraw.CustomerAccounts?.account,
      agency: withdraw.CustomerAccounts?.agency,
      keyPix: withdraw.CustomerAccounts?.pix,
      accountId: {
        value: withdraw.CustomerAccounts?.id,
        label: ` ${
          withdraw?.CustomerAccounts?.pix ||
          `${withdraw?.CustomerAccounts?.account || ''} - ${withdraw?.CustomerAccounts?.agency || ''}`
        }`,
      },
    },
  });

  const editWithdrawel = (dataForm: any) => {
    setLoading(true);
    const pix = unMaskPixKey(dataForm.keyPix);

    updateWithdrawel(withdraw.id, {
      ...dataForm,
      customerAccountId: accountId,
      ispb: dataForm.ispb?.value,
      type,
      accountHolderDocument: dataForm.accountHolderDocument ? onlyNumbers(dataForm.accountHolderDocument) : undefined,
      keyPix: pix,
    })
      .then(() => {
        toast({
          title: 'Sucesso',
          description: `Saque atualizado com sucesso`,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        updateUserData();

        onClose();
        setLoading(false);
        queryClient.refetchQueries({
          queryKey: ['findAllWithdrawers'],
          type: 'active',
        });
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: 'Error',
          description: err.response.data.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      {withdraw.status === 'PENDING' && (
        <Tooltip label="Editar">
          <IconButton
            aria-label="Ver Saque"
            rounded="md"
            size="sm"
            variant="outline"
            icon={<FaPen />}
            onClick={() => {
              onOpen();
            }}
          />
        </Tooltip>
      )}
      {isOpen && (
        <>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Editar Saque</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <ModalBody>
                  <VStack spacing={5} alignItems="start">
                    <Text fontWeight="semibold">Dados bancários</Text>

                    <FormRemoteSelectInput
                      control={control}
                      label="Conta cadastrada?"
                      onChange={(e) => {
                        setAccountId(e?.value);
                      }}
                      loadDataFn={({ value, cb }) =>
                        getAccounts({
                          filter: value,
                          customerId: withdraw.customerId,
                        }).then((retorno) => {
                          if (retorno?.registers?.length > 0) {
                            cb([
                              {
                                label: 'Escolha uma conta',
                                value: '',
                              },
                              ...retorno.registers?.map((d: any) => ({
                                label: d.pix || d.agency + ' - ' + d.account,
                                value: d.id,
                              })),
                            ]);
                          } else {
                            cb([]);
                          }
                        })
                      }
                      name="accountId"
                    />
                    <FormLabelCustom label="Tipo de transfêrencia" props={undefined} name={''} />
                    <Select
                      onChange={(e) => {
                        setType(e.target.value as any);
                      }}
                      disabled={!!accountId}
                    >
                      <option value="DICT">Chave Pix</option>
                      <option value="MANU">Dados manuais</option>
                    </Select>

                    {type === 'BILLET' ? (
                      <>
                        <InputForm
                          control={control}
                          label="CÓDIGO DO BOLETO"
                          placeholder="CÓDIGO DO BOLETO"
                          name="billetCode"
                          type="text"
                          rules={{
                            required: 'Campo obrigatório',
                          }}
                        />
                        <InputForm
                          control={control}
                          label="Data de vencimento"
                          placeholder="Data de vencimento"
                          name="dueDate"
                          type="date"
                          rules={{
                            required: 'Campo obrigatório',
                          }}
                        />
                      </>
                    ) : (
                      <>
                        {!accountId && (
                          <>
                            <InputForm
                              control={control}
                              label="Documento recebedor"
                              placeholder="CPF ou CNPJ"
                              name="accountHolderDocument"
                              type="text"
                              rules={{
                                required: 'Campo obrigatório',
                                validate: {
                                  valid: (value) => {
                                    value = onlyNumbers(value);

                                    return validCpf(value) || validCnpj(value) || 'Documento inválido';
                                  },
                                },
                              }}
                            />
                            <InputForm
                              control={control}
                              placeholder="Nome do recebedor"
                              label="Nome do recebedor"
                              name="accountHolderName"
                              type="text"
                            />
                          </>
                        )}

                        {type === 'DICT' && !accountId && (
                          <>
                            <PixKeyInput
                              label="Chave Pix"
                              control={control}
                              placeholder="Chave pix"
                              name="keyPix"
                              type="text"
                              setValue={setValue}
                            />
                          </>
                        )}

                        {type === 'MANU' && !accountId && (
                          <>
                            <FormRemoteSelectInput
                              control={control}
                              label="Instituição financeira"
                              required
                              isDisabled={!!accountId}
                              registerOptions={{
                                required: !!accountId || 'Esse campo é obrigatório',
                              }}
                              loadDataFn={({ value, cb }) =>
                                findAllParticipants({ filter: value }).then((retorno) => {
                                  if (retorno?.registers?.length > 0) {
                                    cb(
                                      retorno.registers?.map((d: any) => ({
                                        label: d.reducedName,
                                        value: d.ispb,
                                      })),
                                    );
                                  } else {
                                    cb([]);
                                  }
                                })
                              }
                              name="ispb"
                            />
                            <SelectForm
                              control={control}
                              label="Tipo de conta"
                              name="accountType"
                              options={[
                                {
                                  label: 'Conta Corrente',
                                  value: 'CACC',
                                },
                                {
                                  label: 'Conta de Pagamento',
                                  value: 'TRAN',
                                },
                                {
                                  label: 'Conta Poupança',
                                  value: 'SVGS',
                                },
                              ]}
                            />
                            <FormControl isInvalid={!!errors.agency?.message}>
                              <FormLabel display="flex" ms="4px" fontSize="sm" mb="8px">
                                Agência<Text>*</Text>
                              </FormLabel>
                              <Input
                                isRequired={true}
                                variant="auth"
                                fontSize="sm"
                                maxLength={4}
                                isDisabled={!!accountId}
                                name="agency"
                                {...register('agency', {
                                  required: !!accountId,
                                  minLength: {
                                    message: 'Esse campo deve ter no mínimo 4 caracteres',
                                    value: 4,
                                  },
                                  maxLength: {
                                    message: 'Esse campo deve ter no máximo 4 caracteres',
                                    value: 4,
                                  },
                                })}
                                ms={{ base: '0px', md: '0px' }}
                                type="number"
                                placeholder="0001"
                                required
                              />
                              <FormErrorMessage>{errors.agency?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!errors.account?.message}>
                              <FormLabel display="flex" ms="4px" fontSize="sm" mb="8px">
                                Conta (com dígito)<Text>*</Text>
                              </FormLabel>
                              <Input
                                isRequired={true}
                                variant="auth"
                                isDisabled={!!accountId}
                                fontSize="sm"
                                name="account"
                                {...register('account', {
                                  required: !!accountId || 'Esse Campo é Obrigatório',
                                })}
                                ms={{ base: '0px', md: '0px' }}
                                type="tel"
                                placeholder="00101"
                              />
                              <FormErrorMessage>{errors.account?.message}</FormErrorMessage>
                            </FormControl>
                          </>
                        )}
                      </>
                    )}
                    <InputNumber
                      control={control}
                      percentage={false}
                      placeholder="R$ 0,00"
                      label={'Valor do saque'}
                      name={`amount`}
                      rules={{
                        required: 'Campo obrigatório',
                        validate: {
                          positive: (value) => value > 0 || 'O valor deve ser maior que 0',
                          max: (value) =>
                            value <= +userData.balance - +userData.amountLocked + +withdraw.amount ||
                            'O valor deve ser menor que o saldo disponível',
                        },
                      }}
                    />
                    <Text>
                      Saldo Disponível:{' '}
                      <b>{maskCurrency(userData.balance - +userData.amountLocked + +withdraw?.amount || 0)}</b>
                    </Text>

                    <FormControl isInvalid={!!errors.description?.message}>
                      <FormLabel display="flex" ms="4px" fontSize="sm" mb="8px">
                        Descrição<Text>*</Text>
                      </FormLabel>
                      <Input
                        isRequired={true}
                        variant="auth"
                        fontSize="sm"
                        {...register('description', {
                          maxLength: {
                            value: 100,
                            message: 'O limite de caracteres é de 100',
                          },
                        })}
                        ms={{ base: '0px', md: '0px' }}
                        type="tel"
                        placeholder="00101"
                      />
                      <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
                    </FormControl>
                  </VStack>
                </ModalBody>
              </ModalBody>

              <ModalFooter>
                <HStack spacing={4}>
                  <Button colorScheme="red" isLoading={loading} onClick={handleSubmit(editWithdrawel)}>
                    Editar
                  </Button>
                </HStack>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
};

export default EditWithdrawel;
