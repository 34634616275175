// Chakra imports
import { Box, Flex, Heading, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import CustomButton from 'components/CustomButton';
// Custom components
import DefaultAuth from 'layouts/auth/Default';
import { useHistory } from 'react-router-dom';
// Assets
import { getVariable } from 'whitelabel';

function CompletedOnboarding() {
  const textColor = useColorModeValue('brand.700', 'white');
  const history = useHistory();

  return (
    <DefaultAuth illustrationBackground={getVariable('banner')} image={getVariable('banner')}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        my={{ base: 'auto', lg: '150' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '30px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '0', md: '4vh' }}
        flexDirection="column"
      >
        <Box w="full" alignItems="center" display="flex" flexDirection="column">
          <Heading color={textColor} fontSize="24px" mb="10px" mt={10}>
            Onboarding em análise
          </Heading>
          <VStack spacing={4} alignItems="center" w="100%">
            <Text> Seu onboarding foi concluído com sucesso!</Text>
            <Text> Em breve você receberá um e-mail com mais informações.</Text>
            <CustomButton onClick={() => history.push('/auth/sign-in')}>Acessar sua conta</CustomButton>
          </VStack>
        </Box>
      </Flex>
    </DefaultAuth>
  );
}

export default CompletedOnboarding;
