interface CalculatedFeeProps {
  type: string;
  amount: string | number;
  value: string | number;
  maxFixed?: string | number;
  minFixed: string | number;
}

export const calculateFee = ({ type, amount, value, maxFixed, minFixed }: CalculatedFeeProps) => {
  const parsedAmount = parseFloat(amount.toString());
  const parsedValue = parseFloat(value.toString());
  const parsedMaxFixed = maxFixed ? parseFloat(maxFixed.toString()) : 0;
  const parsedMinFixed = minFixed ? parseFloat(minFixed.toString()) : 0;

  if (type === 'PERCENTUAL') {
    const calculatedFee = parsedAmount * (parsedValue / 100);

    const amount = parsedMaxFixed > 0 ? Math.min(calculatedFee, parsedMaxFixed) : calculatedFee;

    if (parsedMinFixed > 0) {
      return Math.max(amount, parsedMinFixed);
    }

    return amount;
  }

  return parsedAmount;
};

export const CalculatedFee = ({ type, amount, value, maxFixed, minFixed }: CalculatedFeeProps) => {
  return calculateFee({ type, amount, value, maxFixed, minFixed });
};
