import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { generateRecurrencyLink } from 'services/api.service';
import { validateDocument } from 'utils/document';
import { onlyNumbers } from 'utils/number';
import { z } from 'zod';

const purchaseSchema = z
  .object({
    name: z.string().min(1, 'Nome é obrigatório'),
    email: z.string().email('Email inválido'),
    phone: z
      .string()
      .min(11, 'Telefone inválido')
      .refine((value) => {
        return onlyNumbers(value).length === 11;
      }, 'Telefone inválido'),
    document: z
      .string()
      .min(11, 'CPF/CNPJ inválido')
      .refine((value) => {
        return validateDocument(value);
      }, 'CPF/CNPJ inválido'),
  })
  .required();

type PurchaseFormData = {
  name: string;
  email: string;
  document: string;
  phone: string;
};

interface PurchaseModalProps {
  isOpen: boolean;
  onClose: () => void;
  planId: string;
}

export function PurchaseModal({ isOpen, onClose, planId }: PurchaseModalProps) {
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<PurchaseFormData>({
    resolver: zodResolver(purchaseSchema),
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: PurchaseFormData) => generateRecurrencyLink(planId, data),
    onSuccess: (data) => {
      toast({
        title: 'Sucesso!',
        description: 'Seu link de pagamento foi gerado.',
        status: 'success',
      });
      reset();
      onClose();
      // You might want to redirect to the payment URL here
      window.location.href = data.link;
    },
    onError: () => {
      toast({
        title: 'Erro',
        description: 'Não foi possível gerar o link de pagamento.',
        status: 'error',
      });
    },
  });

  const onSubmit = (data: PurchaseFormData) => {
    mutate({ ...data, phone: onlyNumbers(data.phone), document: onlyNumbers(data.document) });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Informações para compra</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={4}>
              <FormControl isInvalid={!!errors.name}>
                <FormLabel>Nome completo</FormLabel>
                <Input {...register('name')} />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.email}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  {...register('email')}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.document}>
                <FormLabel>CPF</FormLabel>
                <InputMask
                  mask={'999.999.999-99'}
                  {...register('document')}
                  type="tel"
                >
                  {(inputProps) => (
                    <Input
                      {...inputProps}
                      type="tel"
                      fontSize={'sm'}
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="000.000.000-00"
                      required
                    />
                  )}
                </InputMask>
                <FormErrorMessage>{errors.document?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.phone}>
                <FormLabel>Telefone</FormLabel>
                <InputMask
                  mask={'(99) 99999-9999'}
                  {...register('phone')}
                  type="tel"
                >
                  {(inputProps) => (
                    <Input
                      {...inputProps}
                      type="tel"
                      fontSize={'sm'}
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="(00) 00000-0000"
                      required
                    />
                  )}
                </InputMask>
              </FormControl>
              <Button
                colorScheme="green"
                width="100%"
                type="submit"
                isLoading={isLoading}
              >
                Continuar para pagamento
              </Button>
            </VStack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
