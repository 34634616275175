import { MovementsStatus } from 'views/access/orders';

export interface Movement {
  Customer: Customer;
  Maquinetas: {
    number: string;
    name: string;
  };
  amount: string;
  amountLiquid: string;
  createdAt: string;
  orderAt: string;
  integrationType: string;
  chargeId: null;
  id: string;
  maquinetaId: null;
  transactionCode: string;
  taxes: string;
  firstDigits: null;
  lastDigits: null;
  authorizationCode: null;
  nsu: null;
  installmentCount: number;
  amountRefunded: null;
  flagCard: string;
  status: MovementsStatus;
  feePercent: string;
  payerName: null;
  payerDocument: null;
  type: string;
  txId: null;
  balance: Balance[];
}

export interface Balance {
  id: string;
  customerId: string;
  amount: string;
  amountRefunded: null;
  fee: string;
  type: string;
  origin: string;
  released: boolean;
  releasedOn: string;
  referenceId: string;
  balanceLockId: null;
  relatedBalanceId: null;
  deletedAt: null;
  createdAt: string;
  updatedAt: string;
  Customer: Customer2;
}

interface Customer2 {
  id: string;
  name: string;
  MainCustomer: MainCustomer;
}

interface Customer {
  id: string;
  name: string;
  document_number: string;
  MainCustomer: MainCustomer;
}

interface MainCustomer {
  id: string;
  name: string;
}
export enum CanalDeEntrada {
  'WE' = 'E-Commerce',
  'N' = 'PagSeguro NFC',
  'MD' = 'Venda Digitada',
  'MT' = 'Venda Tarja',
  'MP' = 'Venda com Leitor de Chip e Senha',
  'ME' = 'Venda pela Moderninha',
  'WT' = 'Aplicativo Carteira PagSeguro',
  'TF' = 'TEF',
  'QR' = 'QR Code',
  'LK' = 'Link',
  'AP' = 'Aplicativo PagSeguro',
  'TP' = 'Tap On Phone',
}

export enum MovementsType {
  CASH = 'CASH',
  PIX = 'PIX',
  CREDIT_CARD = 'CREDIT_CARD',
  CREDIT_PARCELLED = 'CREDIT_PARCELLED',
  DEBIT_CARD = 'DEBIT_CARD',
  OTHER = 'OTHER',
}

export enum MovementsTypeDescription {
  CASH = 'Dinheiro',
  PIX = 'Pix',
  CREDIT_CARD = 'Cartão de Crédito',
  CREDIT_PARCELLED = 'Crédito Parcelado',
  DEBIT_CARD = 'Cartão Débito',
  OTHER = 'Outros',
  TICKET = 'Boleto',
  VALE_ALIMENTACAO = 'Vale Alimentação',
  VALE_REFEICAO = 'Vale Refeição',
}
