export enum BalanceTypeDescription {
  LOCK = 'Bloqueio',
  FEE = 'Taxa',
  MOVEMENT = 'Venda',
  MOVEMENT_MAIN = 'Venda Cliente',
  SYSTEM_FEE = 'Taxa',
  SPLIT = 'Split',
  CUSTOMER_SPLIT = 'Split do Cliente',
  WITHDRAWAL = 'Saque',
  WITHDRAWAL_ANTECIPATION = 'Antecipação de Recebíveis',
  TRANSFER = 'Transferência',
  ACCOUNT_RECHARGE = 'Recarga de Conta',
}
