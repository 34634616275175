// Chakra imports
import { Box, Flex, Heading, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { getVariable } from 'whitelabel';
// Custom components
// Assets

function Privacy() {
  // Chakra color mode

  return (
    <Box p={3}>
      <Flex
        h={{
          sm: 'initial',
          md: 'unset',
          lg: '100vh',
          xl: '97vh',
        }}
        w="100%"
        maxW={{ md: '66%', lg: '1313px' }}
        mx="auto"
        pt={{ sm: '10px', md: '2px' }}
        px={{ lg: '30px', xl: '20px' }}
        ps={{ xl: '70px' }}
        justifyContent="start"
        direction="column"
      >
        <NavLink
          to="/auth"
          style={() => ({
            width: 'fit-content',
            marginTop: '20px',
          })}
        >
          Voltar
        </NavLink>
        <Heading my={5}>Política de Privacidade {getVariable('name')}</Heading>
        <Text>
          {getVariable('name')} é uma empresa de tecnologia financeira com sede em Chapecó, Santa Catarina. Fundada em
          2018, a empresa oferece soluções para o mercado de pagamentos, como máquinas de cartão, conta digital para
          pessoas físicas e jurídicas e um aplicativo para intermediar vendas com cartão.
        </Text>
        <Text>
          A {getVariable('name')} tem como objetivo democratizar o acesso aos serviços financeiros, oferecendo soluções
          que atendem tanto aos pequenos empreendedores quanto às grandes empresas. A empresa se destaca pelo
          atendimento personalizado e pela tecnologia de ponta, que garante a segurança e a confiabilidade das
          transações.
        </Text>
        <Text fontWeight="bold" my={3} as="h4">
          Informações Coletadas
        </Text>
        <Text>Nós coletamos as seguintes informações quando você utiliza o nosso aplicativo:</Text>
        <UnorderedList>
          <ListItem>
            Informações de cadastro: Quando você cria uma conta em nosso aplicativo, coletamos seu nome, endereço de
            e-mail, endereço físico e informações de contato.
          </ListItem>
          <ListItem>
            Informações de transações: Coletamos informações sobre as transações que você realiza por meio de nosso
            aplicativo, incluindo o valor da transação, o nome do vendedor e o tipo de cartão de crédito utilizado.
          </ListItem>
          <ListItem>
            Informações de dispositivo: Coletamos informações sobre o dispositivo que você usa para acessar nosso
            aplicativo, incluindo o tipo de dispositivo, sistema operacional e número de IP.
          </ListItem>
        </UnorderedList>
        <Text fontWeight="bold" my={3} as="h4">
          Uso das Informações Coletadas
        </Text>
        <Text>Utilizamos as informações coletadas para os seguintes propósitos:</Text>
        <UnorderedList>
          <ListItem>
            Fornecer serviços: Utilizamos as informações coletadas para processar as suas transações, enviar informações
            sobre suas transações e responder às suas perguntas.
          </ListItem>
          <ListItem>
            Melhorar nossos serviços: Utilizamos as informações coletadas para melhorar nossos serviços e desenvolver
            novos recursos.
          </ListItem>
          <ListItem>
            Comunicação: Utilizamos as informações coletadas para enviar informações importantes sobre nossos serviços,
            como atualizações e alterações na política de privacidade.
          </ListItem>
          <ListItem>
            Publicidade: Podemos utilizar as informações coletadas para enviar publicidade relevante aos nossos
            usuários.
          </ListItem>
        </UnorderedList>
        <Text fontWeight="bold" my={3} as="h4">
          Segurança das Informações
        </Text>
        <Text>
          Nós nos preocupamos com a segurança de suas informações e utilizamos medidas de segurança técnicas e
          administrativas para protegê-las contra acesso não autorizado, divulgação ou destruição. Todas as transações
          realizadas em nosso aplicativo são criptografadas e enviadas através de um canal seguro.
        </Text>
        <Text fontWeight="bold" my={3} as="h4">
          Exclusão de Conta
        </Text>
        <Text>
          {' '}
          Você tem o direito de solicitar a exclusão de sua conta e das informações pessoais que coletamos sobre você.
          Se você deseja excluir sua conta, entre em contato conosco através do endereço de e-mail fornecido no
          aplicativo.
        </Text>
        <Text fontWeight="bold" my={3} as="h4">
          Compartilhamento de Informações
        </Text>
        <Text>
          Nós não compartilhamos suas informações pessoais com terceiros, exceto nas seguintes circunstâncias:
        </Text>
        <UnorderedList>
          <ListItem>
            Parceiros de processamento de pagamentos: Compartilhamos informações com parceiros de processamento de
            pagamentos para processar suas transações.
          </ListItem>
          <ListItem>
            Autoridades governamentais: Podemos compartilhar informações com as autoridades governamentais, se exigido
            por lei ou para cumprir uma ordem judicial.
          </ListItem>
        </UnorderedList>
        <Text fontWeight="bold" my={3} as="h4">
          Segurança das Informações
        </Text>
        <Text>
          Utilizamos medidas de segurança técnicas e administrativas para proteger suas informações pessoais contra
          acesso não autorizado, divulgação ou destruição.
        </Text>
        <Text fontWeight="bold" my={3} as="h4">
          Alterações na Política de Privacidade
        </Text>
        <Text>
          Podemos atualizar esta política de privacidade de tempos em tempos. Notificaremos nossos usuários sobre as
          alterações significativas na política de privacidade enviando uma notificação por e-mail ou através de uma
          mensagem no aplicativo.
        </Text>{' '}
        <Text fontWeight="bold" my={3} as="h4">
          Entre em Contato
        </Text>
        <Text>
          Se você tiver dúvidas sobre esta política de privacidade ou sobre como usamos suas informações pessoais, entre
          em contato conosco através do endereço de e-mail fornecido no aplicativo.
        </Text>
      </Flex>
    </Box>
  );
}

export default Privacy;
