import {
  Box,
  Code,
  Divider,
  HStack,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import CustomButton from 'components/CustomButton';
import InputForm from 'components/InputForm';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiCheck, FiCopy, FiX } from 'react-icons/fi';
import { QRCode } from 'react-qrcode-logo';
import { useCopyToClipboard, useKeyPressEvent } from 'react-use';
import { active2FaData, request2FaData } from 'services/api.service';
import { getVariable } from 'whitelabel';

interface Enable2FAForm {
  code2FA: string;
}

function Enable2FA() {
  const toast = useToast();
  const [, copyToClipboard] = useCopyToClipboard();
  const { handleSubmit, control, reset } = useForm<Enable2FAForm>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { data, isLoading: isLoadingData, isError } = useQuery([`twoFaData`], request2FaData);

  useEffect(() => {
    if (!isOpen) {
      reset();
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onSubmit = async (data: Enable2FAForm) => {
    if (!isOpen) return;
    setIsLoading(true);
    active2FaData(data.code2FA)
      .then(() => {
        toast({
          title: `2FA ativado com sucesso`,
          position: `top`,
          status: `success`,
          duration: 5000,
          isClosable: true,
        });
        onClose();
      })
      .catch((err) => {
        toast({
          title: `Atenção`,
          description: err.response?.data?.message || `Erro ao ativar 2FA`,
          position: `top`,
          status: `warning`,
          duration: 5000,
          isClosable: true,
        });
        setIsLoading(false);
      });
  };

  useKeyPressEvent(`Enter`, handleSubmit(onSubmit) as any);

  return (
    <>
      <CustomButton onClick={onOpen} variant="solid" colorScheme="brand" leftIcon={<FiCheck />}>
        Ativar 2FA
      </CustomButton>
      <Modal size={`md`} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Box p={5}>
            <Text textAlign={`center`}>AUTENTICAÇÃO DE DOIS FATORES (2FA)</Text>
            <Divider my={5} />
            <Text fontSize={`xs`}>
              1. Instale o Google Authenticator (iOS - Android) no seu celular.
              <br />
              2. No aplicativo, clique no icone de &quot;+&quot;.
              <br />
              3. Selecione &quot;Ler código QR&quot; e aponte a câmera para o QR Code abaixo.
            </Text>

            <Text fontSize={`sm`} mt={5}>
              ESCANEIE O QR CODE
            </Text>
            <Divider mb={5} />
            {isError ? (
              <Text fontSize={`sm`} color={`red.500`}>
                Erro ao carregar dados
              </Text>
            ) : isLoadingData ? (
              <Spinner />
            ) : (
              <VStack alignItems={`center`} spacing={5}>
                <QRCode value={data?.otpauth_url} logoImage={getVariable(`logo`)} size={256} removeQrCodeBehindLogo />
                <Box textAlign={`center`}>
                  <HStack justifyContent={`center`} spacing={1}>
                    <Text fontSize={`xs`} color={`gray.500`}>
                      CÓDIGO
                    </Text>
                    <Tooltip label="Copiar código" placement="top" hasArrow>
                      <IconButton
                        color={`gray.500`}
                        aria-label=""
                        variant="ghost"
                        icon={<FiCopy />}
                        size="xs"
                        onClick={() => {
                          copyToClipboard(data?.otpauth_url as string);
                          toast({
                            title: `Código copiado`,
                            position: `top`,
                            status: `success`,
                            duration: 1500,
                            isClosable: true,
                          });
                        }}
                      />
                    </Tooltip>
                  </HStack>
                  <Code px={5}>{data?.base64}</Code>
                </Box>
              </VStack>
            )}

            <Text fontSize={`sm`} mt={5}>
              VERIFICAÇÃO
            </Text>
            <Divider mb={5} />
            <Text fontSize={`sm`} mb={5}>
              Para salvar as configurações, digite o código de verificação gerado no aplicativo.
            </Text>
            <InputForm
              control={control}
              name="code2FA"
              label="Código de autenticação"
              type="text"
              isDisabled={isLoading || isLoadingData}
              placeholder="Código de autenticação"
              labelProps={{
                fontSize: 10,
                textColor: `gray.500`,
              }}
              rules={{
                required: `Informe o código de autenticação`,
              }}
            />

            <Divider my={5} />
            <HStack justifyContent={`END`}>
              <CustomButton leftIcon={<FiX />} isLoading={isLoading} onClick={onClose}>
                Fechar
              </CustomButton>

              <CustomButton
                leftIcon={<FiCheck />}
                isLoading={isLoading || isLoadingData}
                colorScheme={`brand`}
                variant="solid"
                onClick={handleSubmit(onSubmit)}
              >
                Verificar e Ativar
              </CustomButton>
            </HStack>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Enable2FA;
