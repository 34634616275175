// Chakra imports
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Divider,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';

import { generatePaymentsForCharge, getCharge } from 'services/api.service';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { maskCurrency } from 'utils/number';
import { BilletPixStatusRender, ChargeStatus, CreditCardStatusRender, StatusRender } from '.';
import { ChargeType, ItemChargeStatus } from 'types/Charge';
import CustomButton from 'components/CustomButton';
import { RepeatIcon } from '@chakra-ui/icons';
import { MdOutlineLink } from 'react-icons/md';
import Devedor from './Devedor';
import SeeQrCode from './SeeQrCode';
import ListItens from './ChargeItens';
import Webhooks from './Webhooks';

export default function Charge() {
  const { id } = useParams<{ id: string }>();
  const toast = useToast();

  const { data, refetch, isFetching } = useQuery(['getCharge', id], () => getCharge(id));

  const itens = useMemo(
    () => [
      {
        title: 'Id de Referência',
        content: data?.referenceId,
      },

      {
        title: 'Nome do cliente',
        content: data?.CustomerClient?.name,
      },

      {
        title: 'Valor da Cobrança',
        content: maskCurrency(data?.amount),
      },

      {
        title: 'Tipo',
        content: ChargeType[data?.chargeType],
      },

      {
        title: 'Status',
        content: <StatusRender status={data?.status} />,
      },
      {
        content: (
          <>
            {data?.urlPayment ? (
              <Stack
                direction={{
                  base: 'column',
                  md: 'row',
                }}
                spacing={2}
              >
                <CustomButton
                  variant={'outline'}
                  onClick={() => {
                    window.open(data?.urlPayment || '', '_blank');
                  }}
                >
                  Abrir
                </CustomButton>
                <CustomButton
                  variant="solid"
                  onClick={() => {
                    navigator.clipboard.writeText(data?.urlPayment || '');
                    toast({
                      title: 'Link copiado',
                      status: 'success',
                      duration: 3000,
                      isClosable: true,
                    });
                  }}
                >
                  Copiar Link
                </CustomButton>
              </Stack>
            ) : (
              '---'
            )}
          </>
        ),
        title: 'Link de pagamento',
      },
      {
        title: 'Criação da cobrança',
        content: DateTime.fromISO(data?.createdAt).toFormat(`dd/MM/yyyy - HH:mm:ss`),
      },
      {
        title: 'Data de Pagamento',
        content: data?.payedAt ? DateTime.fromISO(data?.payedAt).toFormat(`dd/MM/yyyy - HH:mm:ss`) : '---',
      },
      {
        title: 'Expiração da cobrança',
        content: data?.expireAt ? DateTime.fromISO(data?.expireAt).toFormat(`dd/MM/yyyy - HH:mm:ss`) : '---',
      },
    ],
    [data, toast],
  );

  if (!data) {
    return <div>Carregando...</div>;
  }

  const handleGenerate = (type: 'pix' | 'billet') => {
    generatePaymentsForCharge(data.id, type)
      .then(() => {
        toast({
          title: 'Sucesso',
          description: `${type === 'pix' ? 'Pix' : 'Boleto'} gerado com sucesso`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        refetch();
      })
      .catch((err) => {
        toast({
          title: 'Erro',
          description: err.response?.data?.message || 'Erro ao gerar cobrança',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <Box>
      <SimpleGrid mb="20px" columns={{ md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
        <Card px={3}>
          <CardHeader p={2} mt={3}>
            <HStack
              spacing={3}
              justify="space-between"
              flexDirection={{ base: 'column', md: 'row' }}
              align="center"
              w="100%"
            >
              <Box>
                <Box fontSize="2xl" fontWeight="semibold">
                  Detalhes da cobrança
                </Box>
                <small>
                  Transação: <Badge size="small">{data?.id}</Badge> Sequência:{' '}
                  <Badge size="small">{data?.sequence}</Badge>{' '}
                </small>
              </Box>
              <Box>
                <ButtonGroup>
                  <Button
                    size="sm"
                    colorScheme="brand"
                    ml={3}
                    rightIcon={<RepeatIcon />}
                    rounded={'sm'}
                    isLoading={isFetching}
                    onClick={() =>
                      refetch().then(() => {
                        toast({
                          title: 'Sucesso',
                          description: 'Cobrança atualizada com sucesso',
                          status: 'success',
                          duration: 3000,
                          isClosable: true,
                        });
                      })
                    }
                  >
                    Atualizar
                  </Button>
                </ButtonGroup>
              </Box>
            </HStack>
          </CardHeader>
          <Divider color="gray.300" />
          <SimpleGrid mb="20px" columns={{ sm: 1, md: 2, lg: 3, xl: 4 }} spacing={5}>
            {itens.map((item) => (
              <Box key={item.title} boxShadow="none" p="2" px={2}>
                <Box fontSize="md" fontWeight="semibold" color="gray.600">
                  {item.title}
                </Box>
                <Box fontSize="md">{item.content || `---`}</Box>
              </Box>
            ))}
          </SimpleGrid>

          <Devedor person={data.person} shipping={data.shipping} />
          <ListItens itens={data?.itens || []} />

          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={5}>
            <Box bg="gray.100" p={4} borderRadius="md">
              <Text fontWeight="bold">Informações PIX</Text>
              {data?.Pix.length === 0 && (
                <>
                  <Text>Não há informações de PIX para esta cobrança</Text>
                  <Button size="sm" colorScheme="brand" ml={3} rounded={'sm'} onClick={() => handleGenerate('pix')}>
                    Gerar Pix
                  </Button>
                </>
              )}
              {data?.Pix.map((pix) => (
                <Box key={pix.id} p={2} borderWidth="1px" borderRadius="md" mt={2}>
                  <VStack spacing={3} align={'start'}>
                    <Text>
                      <b>ID:</b> {pix.id}
                    </Text>{' '}
                    <Text>
                      <b>Criado em:</b> {DateTime.fromISO(pix.createdAt).toFormat(`dd/MM/yyyy - HH:mm:ss`)}
                    </Text>
                    <Text>
                      <b>Status:</b> {BilletPixStatusRender(pix.status)}
                    </Text>
                    <Text>
                      <b>TxId:</b> {pix.referenceIntegrationId}
                    </Text>
                    <Text>
                      <b>EndToEndId:</b> {pix.endToEndId}
                    </Text>
                    {pix.status === ItemChargeStatus.PENDING && pix.qrCode && (
                      <>
                        <Text translate="no" mb={4}>
                          <b>Código QR:</b>
                          <Button
                            rounded={'sm'}
                            size="sm"
                            colorScheme="brand"
                            variant={'outline'}
                            ml={3}
                            mr={3}
                            leftIcon={<MdOutlineLink />}
                            onClick={() => {
                              navigator.clipboard.writeText(pix.qrCode);
                              toast({
                                title: 'Sucesso',
                                description: 'Link copiado com sucesso',
                                status: 'success',
                                duration: 3000,
                                isClosable: true,
                              });
                            }}
                          >
                            Copiar
                          </Button>
                          <SeeQrCode
                            data={{
                              qrCode: pix.qrCode,
                              amount: +data.amount,
                            }}
                          />
                        </Text>
                      </>
                    )}
                  </VStack>
                </Box>
              ))}
            </Box>
            <Box bg="gray.100" p={4} borderRadius="md">
              <Text fontWeight="bold">Informações Cartão de Crédito</Text>
              {data?.CreditCardPayment.length === 0 && (
                <Text>Não há informações de Cartão de Crédito para esta cobrança</Text>
              )}
              {data?.CreditCardPayment.map((creditCard) => (
                <Box key={creditCard.id} p={2} borderWidth="1px" borderRadius="md" mt={2}>
                  <VStack spacing={3} align={'start'}>
                    <Text>
                      <b>ID:</b> {creditCard.id}
                    </Text>{' '}
                    <Text>
                      <b>Criado em:</b> {DateTime.fromISO(creditCard.createdAt).toFormat(`dd/MM/yyyy - HH:mm:ss`)}
                    </Text>
                    <Text>
                      <b>Status:</b> {CreditCardStatusRender(creditCard.status)}
                    </Text>
                    <Text>
                      <b>Valor:</b> {maskCurrency(creditCard.amount)}
                    </Text>
                    {creditCard.amountRefunded && +creditCard.amountRefunded > 0 && (
                      <Text>
                        <b>Valor Devolvido:</b> {maskCurrency(creditCard.amountRefunded)}
                      </Text>
                    )}
                    <Text>
                      <b>Cobrança Adquirente:</b> {creditCard.tId}
                    </Text>
                    <Text>
                      <b>Id Adquirente:</b> {creditCard.paymentId}
                    </Text>
                    <Text>
                      <b>NSU:</b> {creditCard.nsu}
                    </Text>
                    <Text>
                      <b>Autorização:</b> {creditCard.authorizationCode}
                    </Text>
                    <Text>
                      <b>Cartão:</b> {creditCard.creditCard}
                    </Text>
                    <Text>
                      <b>Expiração:</b> {creditCard.expiration}
                    </Text>
                    <Text>
                      <b>Parcelas:</b> {creditCard.installments}
                    </Text>
                    <Text>
                      <b>Bandeira: </b>
                      {creditCard.brand}
                    </Text>
                    <Text>
                      <b>Provedor: </b>
                      {creditCard.type}
                    </Text>
                    <Text>
                      <b>Mensagem da API: </b>
                      {creditCard.returnMessage}
                    </Text>
                  </VStack>
                </Box>
              ))}
            </Box>
            <Box bg="gray.100" p={4} borderRadius="md">
              <Text fontWeight="bold">Informações Boleto</Text>
              {data?.Billet?.length === 0 && (
                <>
                  <Text>Não há informações de Boleto para esta cobrança</Text>
                  {data.status === ChargeStatus.PENDING && (
                    <Button
                      size="sm"
                      colorScheme="brand"
                      ml={3}
                      rounded={'sm'}
                      onClick={() => handleGenerate('billet')}
                    >
                      Gerar Boleto
                    </Button>
                  )}
                </>
              )}
              {data?.Billet?.map((billet) => (
                <Box key={billet.id} p={2} borderWidth="1px" borderRadius="md" mt={2}>
                  <VStack spacing={3} align={'start'}>
                    <Text>
                      <b>ID:</b> {billet.id}
                    </Text>
                    <Text>
                      <b>Criado em:</b> {DateTime.fromISO(billet.createdAt).toFormat(`dd/MM/yyyy - HH:mm:ss`)}
                    </Text>
                    <Text>
                      <b>Status:</b> {BilletPixStatusRender(billet.status)}
                    </Text>
                    <Text>
                      <b>Seu número:</b> {billet.billetId}
                    </Text>
                    <Text>
                      <b>Código:</b> {billet.billetCode}
                    </Text>
                    <Text translate="no">
                      <b>Código de Barras:</b> {}
                      {billet.billetDigit}
                      <Button
                        rounded={'sm'}
                        size="sm"
                        colorScheme="brand"
                        ml={3}
                        onClick={() => {
                          navigator.clipboard.writeText(billet.billetDigit);
                          toast({
                            title: 'Sucesso',
                            description: 'Boleto copiado com sucesso',
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                          });
                        }}
                      >
                        Copiar
                      </Button>
                    </Text>
                  </VStack>
                </Box>
              ))}
            </Box>
          </SimpleGrid>

          <Webhooks webhooks={data?.webhook || []} />
        </Card>
      </SimpleGrid>
    </Box>
  );
}
