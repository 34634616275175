import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { Select } from 'chakra-react-select';
import CustomButton from 'components/CustomButton';
import InputForm from 'components/InputForm';
import { queryClient } from 'index';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { createRecurrencyLink, findAllRecurrences } from 'services/api.service';

export type CreateRecurrencyForm = {
  customerId: string;
  title: string;
  description: string;
  image: string;
  selectedPlans: string[];
};

const CreateLinkRecurrence: React.FC<{}> = () => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, control, reset } = useForm<CreateRecurrencyForm>();

  const { data: recurrences } = useQuery({
    queryKey: ['findAllRecurrences'],
    queryFn: () =>
      findAllRecurrences({
        limit: 100,
        page: 0,
        filter: '',
      }),
  });

  const plansOptions = useMemo(() => {
    if (!recurrences?.registers) return [];
    return recurrences.registers.map((plan) => ({
      label: `${plan.description} - R$ ${plan.amount}`,
      value: plan.id,
    }));
  }, [recurrences]);

  const save = async (dataForm: CreateRecurrencyForm) => {
    setLoading(true);
    createRecurrencyLink({
      ...dataForm,
      itens: dataForm.selectedPlans.map((planId) => ({ planId })),
    })
      .then(() => {
        onClose();
        queryClient.refetchQueries({
          queryKey: ['findAllRecurrencys'],
          type: 'active',
        });
        toast({
          title: 'Sucesso',
          description: 'Link de recorrência criado com sucesso',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: 'Erro',
          description: err?.response?.data?.message || 'Erro ao criar Recorrência',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    reset({});
  }, [reset]);

  return (
    <>
      <CustomButton
        onClick={() => {
          onOpen();
          reset();
        }}
        variant="outline"
        colorScheme="brand"
        leftIcon={<FaPlus />}
      >
        <Text>Criar Link</Text>
      </CustomButton>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Criar Link de Recorrência</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3}>
                <InputForm
                  control={control}
                  name="title"
                  label="Título *"
                  type="text"
                  rules={{
                    required: 'Campo obrigatório',
                    maxLength: {
                      value: 200,
                      message: 'Máximo de 200 caracteres',
                    },
                  }}
                />
                <InputForm
                  control={control}
                  name="description"
                  label="Descrição"
                  type="text"
                />
                <InputForm
                  control={control}
                  name="image"
                  label="URL da Imagem"
                  type="text"
                />

                <FormControl>
                  <FormLabel>Planos *</FormLabel>
                  <Controller
                    control={control}
                    name="selectedPlans"
                    rules={{ required: 'Selecione pelo menos um plano' }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <Select
                          isMulti
                          options={plansOptions}
                          onChange={(newValue) => field.onChange(newValue.map((item) => item.value))}
                          value={plansOptions.filter((option) => field.value?.includes(option.value))}
                          placeholder="Selecione os planos"
                        />
                        {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                      </>
                    )}
                  />
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Adicionar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default CreateLinkRecurrence;
