import {
  Button,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import InputForm from 'components/InputForm';
import InputNumber from 'components/InputNumber';
import SelectForm from 'components/SelectForm';
import { queryClient } from 'index';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FaEdit } from 'react-icons/fa';
import { updateRecurrency } from 'services/api.service';
import { RecurrencyPeriod, RecurrencyPeriodDescription } from './recurrency-type';

type EditRecurrencyForm = {
  amount: string;
  description: string;
  period: RecurrencyPeriod;
};

interface EditRecurrenceProps {
  recurrence: {
    id: string;
    amount: string;
    description: string;
    period: RecurrencyPeriod;
  };
}

const EditRecurrence: React.FC<EditRecurrenceProps> = ({ recurrence }) => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, control, reset } = useForm<EditRecurrencyForm>();

  const save = async (dataForm: EditRecurrencyForm) => {
    setLoading(true);
    updateRecurrency(recurrence.id, {
      ...dataForm,
    })
      .then(() => {
        onClose();
        queryClient.refetchQueries({
          queryKey: ['findAllRecurrencys'],
          type: 'active',
        });
        toast({
          title: 'Sucesso',
          description: 'Recorrência atualizada com sucesso',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: 'Erro',
          description: err?.response?.data?.message || 'Erro ao atualizar Recorrência',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (isOpen) {
      reset({
        amount: recurrence.amount,
        description: recurrence.description,
        period: recurrence.period,
      });
    }
  }, [isOpen, recurrence, reset]);

  return (
    <>
      <IconButton
        aria-label="Editar recorrência"
        icon={<FaEdit />}
        onClick={onOpen}
        size="sm"
        rounded="md"
        variant="outline"
      />

      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Editar Recorrência</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3}>
                <InputForm
                  control={control}
                  name="description"
                  label="Nome da Recorrência *"
                  type="text"
                  rules={{
                    required: 'Campo obrigatório',
                  }}
                />
                <InputNumber
                  control={control}
                  name="amount"
                  label="Valor *"
                  rules={{
                    required: 'Campo obrigatório',
                  }}
                />
                <SelectForm
                  control={control}
                  name="period"
                  label="Período *"
                  options={Object.values(RecurrencyPeriod).map((period) => ({
                    label: RecurrencyPeriodDescription[period],
                    value: period,
                  }))}
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={onClose}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Salvar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default EditRecurrence;
