import { Box, Button, HStack, Link, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

const CookieConsent: React.FC<{
  onChange: (value: boolean) => void;
}> = ({ onChange }) => {
  const [hasConsent, setHasConsent] = useState(true);
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const toast = useToast();

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    setHasConsent(consent === 'true');
    onChange(consent === 'true');
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setHasConsent(true);
    onClose();
    onChange(true);
    toast({
      title: 'Preferências salvas',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'false');
    setHasConsent(false);
    onClose();
    onChange(false);
  };

  if (hasConsent || !isOpen) return null;

  return (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      bg="white"
      boxShadow="0 -2px 10px rgba(0,0,0,0.1)"
      p={4}
      zIndex={999}
    >
      <HStack
        justify="space-between"
        maxW="container.xl"
        mx="auto"
      >
        <Text fontSize="sm">
          Utilizamos cookies para melhorar sua experiência em nosso site. Ao continuar navegando, você concorda com a
          nossa{' '}
          <Link
            fontWeight="700"
            colorScheme="brand"
            href="/#/auth/privacy/default"
            target="_blank"
          >
            Política de Privacidade
          </Link>
          .
        </Text>
        <Button
          colorScheme="brand"
          size="sm"
          onClick={handleAccept}
        >
          Aceitar
        </Button>
        <Button
          variant="link"
          colorScheme="brand"
          size="xs"
          onClick={handleReject}
        >
          Rejeitar
        </Button>
      </HStack>
    </Box>
  );
};

export default CookieConsent;
