import { FormControl, FormErrorMessage, Input } from '@chakra-ui/react';
import React from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import FormLabelCustom from './FormLabel';

const InputNumber: React.FC<{
  control: Control<any>;
  label: string;
  autoFocus?: boolean;
  name: string;
  disabled?: boolean;
  percentage?: boolean;
  placeholder?: string;
  rules?: RegisterOptions<any>;
}> = ({ control, label, rules, name, percentage, placeholder, disabled, autoFocus }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, name, value }, fieldState: { invalid, error } }) => (
        <FormControl isInvalid={invalid} autoFocus={autoFocus}>
          <FormLabelCustom
            name={label}
            label={label}
            props={{}}
          ></FormLabelCustom>
          <NumericFormat
            decimalScale={2}
            prefix={percentage ? '' : 'R$ '}
            suffix={percentage ? ' %' : ''}
            name={name}
            value={value}
            disabled={disabled}
            onValueChange={(values) => {
              if (disabled) {
                return;
              }
              onChange(values.floatValue);
            }}
            inputMode={'numeric'}
            style={{
              height: '31px',
              borderRadius: '1px',
              backgroundColor: 'white',
            }}
            decimalSeparator=","
            thousandSeparator="."
            placeholder={placeholder}
            customInput={Input as any}
            fixedDecimalScale={true}
            allowLeadingZeros={true}
          />
          <FormErrorMessage>{error?.message || 'Campo inválido'}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export default InputNumber;
