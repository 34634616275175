import {
  Box,
  Button,
  Divider,
  HStack,
  Link,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
  VStack,
  useMediaQuery,
  useSteps,
  useToast,
} from '@chakra-ui/react';
import InputForm from 'components/InputForm';
import InputMaskForm from 'components/InputMask';
import SelectForm from 'components/SelectForm';
import { DateTime } from 'luxon';
import React, { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { FaArrowLeft, FaArrowRight, FaCreditCard } from 'react-icons/fa';
import api, { payWithCreditCard } from 'services/api.service';
import { validCpf } from 'services/validDocument';
import { calcularParcelasMaximas, maskCurrency } from 'utils/currency';
import { onlyNumbers } from 'utils/number';
import { InstallmentsConfig } from './types/ChargeTypes';

const steps = [{ title: 'Identificação' }, { title: 'Endereço' }, { title: 'Pagamento' }];

interface PagSeguroResponse {
  status: string;
  authenticationStatus: string;
  id: string;
}

interface CepResponse {
  erro: string;
  cep: string;
  logradouro: string;
  complemento: string;
  unidade: string;
  bairro: string;
  localidade: string;
  uf: string;
  estado: string;
  regiao: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

export interface FormCardCreditCard {
  cardNumber: string;
  cardExpiration: string;
  cardCvv: string;
  cardHolderName: string;
  cardHolderCpf: string;
  installments: number;
  name: string;
  document: string;
  email: string;
  phone: string;
  zipCode: string;
  street: string;
  number: string;
  complement: string;
  district: string;
  city: string;
  state: string;
  pagSeguro3dsId?: string;
  method: 'CREDIT_CARD' | 'DEBIT_CARD';
}

const PagSeguroCard: React.FC<{
  id: string;
  method: 'CREDIT_CARD' | 'DEBIT_CARD';
  amount: number;
  feeType?: 'FROM_PAYER' | 'FROM_CUSTOMER';
  refetch: () => void;
  installmentsConfig: InstallmentsConfig;
}> = ({ id, feeType, amount, installmentsConfig, method, refetch }) => {
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');
  const toast = useToast();
  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 1,
    count: steps.length,
  });

  const [cep, setCep] = React.useState<CepResponse>();

  const [isLoading, setIsLoading] = React.useState(false);
  const { control, handleSubmit, setValue, setError } = useForm<FormCardCreditCard>({
    defaultValues: {
      installments: 1,
    },
    progressive: true,
    shouldFocusError: true,
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      window.alert('Recaptcha has not been loaded');
      return;
    }

    return await executeRecaptcha('PAYMENT_CREDIT_CARD');
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  const calculateInstallment = (amount: number, installment: number) => {
    if (method === 'DEBIT_CARD') {
      return amount;
    }

    if ((feeType ?? installmentsConfig.installmentsType) === 'FROM_PAYER') {
      const fee = (installmentsConfig.installments[installment]?.fee ?? 1) / 100;
      return amount / (1 - fee);
    }

    return amount;
  };

  const onSubmit = async (formData: FormCardCreditCard) => {
    if (activeStep !== 3) {
      goToNext();
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    if (amount < 2000 && method === 'CREDIT_CARD') {
      const token = await handleReCaptchaVerify();
      payWithCreditCard(id, {
        ...formData,
        cardNumber: onlyNumbers(formData.cardNumber),
        cardHolderCpf: onlyNumbers(formData.cardHolderCpf),
        recaptchaToken: token,
        installments: +formData.installments,
        state: cep?.uf,
        zipCode: onlyNumbers(formData.zipCode),
        phone: onlyNumbers(formData.phone),
        document: onlyNumbers(formData.document),
        method,
      })
        .then(() => {
          refetch();
          setIsLoading(false);
          toast({
            title: 'Pagamento realizado com sucesso',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });
        })
        .catch(onError);

      return;
    }

    await handleReCaptchaVerify()
      .then(async (token) => {
        await api
          .post<{ session: string; env: string }>(`/public/charges/${id}/pagseguro/session`, {
            recaptchaToken: token,
          })
          .then(async (response) => {
            window.PagSeguro.setUp({
              session: response.data.session,
              env: response.data.env,
            });
          });
      })
      .catch(onError);

    const phone = onlyNumbers(formData.phone);

    const request = {
      data: {
        customer: {
          name: formData.cardHolderName,
          email: formData.email,
          phones: [
            {
              country: '55',
              area: phone.substring(0, 1),
              number: phone.substring(2),
              type: 'MOBILE',
            },
          ],
        },
        paymentMethod: {
          type: method,
          installments: +formData.installments,
          card: {
            number: onlyNumbers(formData.cardNumber),
            expMonth: formData.cardExpiration.split('/')[0],
            expYear: formData.cardExpiration.split('/')[1],
            holder: {
              name: formData.cardHolderName,
            },
          },
        },
        amount: {
          value: (calculateInstallment(amount, +formData.installments) * 100).toFixed(0),
          currency: 'BRL',
        },
        billingAddress: {
          street: formData.street,
          number: formData.number,
          complement: formData.complement,
          regionCode: cep?.uf,
          country: 'BRA',
          city: formData.city,
          postalCode: onlyNumbers(formData.zipCode),
        },
        shippingAddress: {
          street: formData.street,
          number: formData.number,
          complement: formData.complement,
          regionCode: cep?.uf,
          country: 'BRA',
          city: formData.city,
          postalCode: onlyNumbers(formData.zipCode),
        },
        dataOnly: false,
      },
    };

    window.PagSeguro.authenticate3DS(request)
      .then(async (result: PagSeguroResponse) => {
        if (result.status === 'AUTH_NOT_SUPPORTED') {
          toast({
            title: 'Autenticação não suportada. Utilize outro cartão',
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
          setIsLoading(false);
        } else if (result.status === 'AUTH_FLOW_COMPLETED') {
          const token = await handleReCaptchaVerify();
          payWithCreditCard(id, {
            ...formData,
            cardNumber: onlyNumbers(formData.cardNumber),
            cardHolderCpf: onlyNumbers(formData.cardHolderCpf),
            recaptchaToken: token,
            installments: +formData.installments,
            pagSeguro3dsId: result.id,
            state: cep?.uf,
            zipCode: onlyNumbers(formData.zipCode),
            phone: onlyNumbers(formData.phone),
            document: onlyNumbers(formData.document),
            method,
          })
            .then(() => {
              refetch();
              setIsLoading(false);
              toast({
                title: 'Pagamento realizado com sucesso',
                status: 'success',
                duration: 2000,
                isClosable: true,
              });
            })
            .catch(onError);
        } else {
          toast({
            title: 'Erro ao realizar pagamento',
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err instanceof window.PagSeguro.PagSeguroError) {
          console.log(err);
          console.log(err.detail);
        }
        console.log(err);

        setIsLoading(false);
        toast({
          title: 'Erro ao realizar pagamento',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      });

    //const token = await handleReCaptchaVerify();
  };

  const onError = async (error) => {
    setIsLoading(false);
    toast({
      title: error?.response?.data?.message ?? 'Erro ao realizar pagamento',
      status: 'error',
      duration: 2000,
      isClosable: true,
    });
  };

  let maxQuantiyParcelas = calcularParcelasMaximas(+amount, installmentsConfig?.maxInstallments ?? 12);

  const onBlurCep = async (e: React.FocusEvent<HTMLInputElement>) => {
    const zipCode = e.target.value;
    if (zipCode.length === 9) {
      const response = await api.get<CepResponse>(`https://viacep.com.br/ws/${onlyNumbers(zipCode) ?? zipCode}/json/`);

      if (response.data.erro === 'true') {
        toast({
          title: 'CEP inválido',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
        setError('zipCode', {
          type: 'manual',
          message: 'CEP inválido',
        });

        return;
      }

      setValue('street', response.data.logradouro);
      setValue('district', response.data.bairro);
      setValue('city', response.data.localidade);
      setValue('state', response.data.estado);

      setCep(response.data);
    }
  };

  return (
    <VStack>
      <Stepper
        size="lg"
        colorScheme="brand"
        index={activeStep}
        mb={5}
        orientation={isLargerThan800 ? 'horizontal' : 'vertical'}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      <Text
        fontSize="lg"
        fontWeight="bold"
        textAlign="center"
      >
        {method === 'CREDIT_CARD' ? 'Cartão de crédito' : 'Cartão de débito'}
      </Text>

      <Divider mb={4} />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ maxWidth: 500, width: '100%' }}
      >
        {activeStep === 1 && (
          <>
            <VStack spacing={3}>
              <InputForm
                type="text"
                name="name"
                placeholder="Insira seu nome"
                control={control}
                label="Nome"
                rules={{
                  required: 'Campo obrigatório',
                  validate: {
                    valid: (value) => {
                      return value.split(' ').length > 1 || 'Nome inválido';
                    },
                  },
                }}
              />
              <InputMaskForm
                type="text"
                name="document"
                placeholder="999.999.999-99"
                control={control}
                mask="999.999.999-99"
                label="Documento"
                rules={{
                  required: 'Campo obrigatório',
                  minLength: {
                    value: 11,
                    message: 'CPF inválido',
                  },
                  validate: {
                    valid: (value) => {
                      value = onlyNumbers(value);

                      return validCpf(value) || 'Documento inválido';
                    },
                  },
                }}
              />

              <InputForm
                type="email"
                name="email"
                placeholder="email@email.com"
                control={control}
                label="E-mail"
                rules={{
                  required: 'Campo obrigatório',
                }}
              />

              <InputMaskForm
                type="tel"
                mask="(99) 99999-9999"
                name="phone"
                placeholder="(99) 99999-9999"
                control={control}
                label="Telefone"
                rules={{
                  required: 'Campo obrigatório',
                }}
              />

              <Button
                width="100%"
                mt={3}
                type="submit"
                colorScheme="brand"
                rightIcon={<FaArrowRight />}
              >
                Próximo
              </Button>
            </VStack>
          </>
        )}
        {activeStep === 2 && (
          <>
            <VStack spacing={3}>
              <InputMaskForm
                name="zipCode"
                onBlur={onBlurCep}
                type="text"
                mask="99999-999"
                placeholder="99999-999"
                control={control}
                label="CEP"
                rules={{
                  required: 'Campo obrigatório',
                  minLength: {
                    value: 9,
                    message: 'CEP inválido',
                  },
                }}
              />

              <InputForm
                type="text"
                name="street"
                placeholder="Insira o nome da rua"
                control={control}
                label="Rua"
                rules={{
                  required: 'Campo obrigatório',
                }}
              />

              <InputForm
                type="text"
                name="number"
                placeholder="Insira o número"
                control={control}
                label="Número"
                rules={{
                  required: 'Campo obrigatório',
                }}
              />

              <InputForm
                type="text"
                name="complement"
                placeholder="Insira o complemento"
                control={control}
                label="Complemento"
                rules={{
                  required: 'Campo obrigatório',
                }}
              />

              <InputForm
                type="text"
                name="district"
                placeholder="Insira o bairro"
                control={control}
                label="Bairro"
                rules={{
                  required: 'Campo obrigatório',
                }}
              />

              <InputForm
                type="text"
                name="city"
                placeholder="Insira a cidade"
                control={control}
                label="Cidade"
                isDisabled
                rules={{
                  required: 'Campo obrigatório',
                }}
              />

              <InputForm
                type="text"
                name="state"
                isDisabled
                placeholder="Insira o estado"
                control={control}
                label="Estado"
                rules={{
                  required: 'Campo obrigatório',
                }}
              />

              <HStack width="100%">
                <Button
                  width="100%"
                  mt={3}
                  colorScheme="brand"
                  onClick={() => goToPrevious()}
                  leftIcon={<FaArrowLeft />}
                >
                  Voltar
                </Button>
                <Button
                  width="100%"
                  mt={3}
                  type="submit"
                  colorScheme="brand"
                  rightIcon={<FaArrowRight />}
                >
                  Próximo
                </Button>
              </HStack>
            </VStack>
          </>
        )}

        {activeStep === 3 && (
          <VStack spacing={3}>
            <InputForm
              type="text"
              name="cardHolderName"
              placeholder="Insira o nome do titular"
              control={control}
              label="Nome do titular"
              rules={{
                required: 'Campo obrigatório',
                validate: {
                  valid: (value) => {
                    return value.split(' ').length > 1 || 'Nome inválido';
                  },
                },
              }}
            />
            <InputMaskForm
              type="text"
              name="cardHolderCpf"
              placeholder="999.999.999-99"
              control={control}
              mask="999.999.999-99"
              label="CPF do titular"
              rules={{
                required: 'Campo obrigatório',
                minLength: {
                  value: 11,
                  message: 'CPF inválido',
                },
                validate: {
                  valid: (value) => {
                    value = onlyNumbers(value);

                    return validCpf(value) || 'Documento inválido';
                  },
                },
              }}
            />

            <Divider />

            <InputMaskForm
              type="text"
              name="cardNumber"
              placeholder="XXXX XXXX XXXX XXXX"
              control={control}
              mask="9999 9999 9999 9999"
              label="Número do cartão"
              rules={{
                required: 'Campo obrigatório',
                validate: {
                  valid: (value) => {
                    value = onlyNumbers(value);
                    return value.length === 16 || 'Número do cartão inválido';
                  },
                },
              }}
            />
            <HStack width="100%">
              <InputMaskForm
                type="text"
                name="cardExpiration"
                mask="99/9999"
                placeholder="MM/YYYY"
                control={control}
                label="Data de expiração"
                rules={{
                  required: 'Campo obrigatório',
                  minLength: {
                    value: 7,
                    message: 'Data inválida',
                  },
                  maxLength: {
                    value: 7,
                    message: 'Data inválida',
                  },
                  validate: {
                    valid: (value) => {
                      const [month, year] = value.split('/');
                      return parseInt(month) <= 12 && parseInt(month) > 0 && parseInt(year) >= DateTime.now().year;
                    },
                  },
                }}
              />

              <InputMaskForm
                type="text"
                name="cardCvv"
                mask="9999"
                placeholder="CVV"
                control={control}
                label="CVV"
                rules={{
                  required: 'Campo obrigatório',
                  minLength: {
                    value: 3,
                    message: 'Código inválido',
                  },
                  maxLength: {
                    value: 4,
                    message: 'Código inválido',
                  },
                }}
              />
            </HStack>
            {method === 'CREDIT_CARD' && (
              <SelectForm
                name="installments"
                label="Parcelas"
                control={control}
                rules={{
                  required: 'Campo obrigatório',
                }}
                options={[
                  ...Array.from({ length: maxQuantiyParcelas }, (_, i) => {
                    const installment = i + 1;
                    const newAmount = calculateInstallment(amount, installment);
                    const finalMonthly = newAmount / installment;

                    return {
                      label: `${installment}x - ${maskCurrency(finalMonthly)} = ${maskCurrency(newAmount)}`,
                      value: installment.toString(),
                    };
                  }),
                ]}
              />
            )}
            <Divider />
            <Text
              fontSize="sm"
              textAlign="center"
            >
              Taxas de juros podem ser aplicadas na modalidade de pagamento de crédito.
            </Text>
            <Divider />
            <Text
              fontSize="sm"
              textAlign="center"
            >
              <>
                Ao clicar em pagar, você concorda com os{' '}
                <Link
                  href="/termos-de-uso"
                  isExternal
                  colorScheme="brand"
                >
                  termos de uso
                </Link>{' '}
                e{' '}
                <Link
                  href="/politica-de-privacidade"
                  isExternal
                >
                  política de privacidade
                </Link>
                .
              </>
            </Text>
            <HStack width="100%">
              <Button
                width="100%"
                mt={3}
                colorScheme="brand"
                onClick={() => goToPrevious()}
                leftIcon={<FaArrowLeft />}
              >
                Voltar
              </Button>
              <Button
                width="100%"
                mt={3}
                variant="solid"
                isLoading={isLoading}
                colorScheme="brand"
                type="submit"
                leftIcon={<FaCreditCard />}
              >
                Pagar
              </Button>
            </HStack>
          </VStack>
        )}
      </form>
    </VStack>
  );
};

export default PagSeguroCard;
