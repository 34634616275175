import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  HStack,
  Button,
  useToast,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import InputForm from 'components/InputForm';
import InputMaskForm from 'components/InputMask';
import { queryClient } from 'index';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FaPen } from 'react-icons/fa';
import { editClient, findClientById } from 'services/api.service';
import { validCpf } from 'services/validDocument';
import { onlyNumbers } from 'utils/string';

export type EditClientForm = {
  document: string;
  email: string;
  phone: string;
  name: string;
};

const EditClient: React.FC<{ clientId: string }> = ({ clientId }) => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, control, reset, setValue } = useForm<EditClientForm>();
  useQuery(['findClientById', { id: clientId }], () => findClientById(clientId), {
    enabled: !!clientId && !!isOpen,
    onSuccess: (data) => {
      setValue('name', data.name);
      setValue('document', data.document);
      setValue('email', data.email);
      setValue('phone', data.phone);
    },
  });

  const save = async (dataForm: EditClientForm) => {
    setLoading(true);
    editClient(clientId, {
      ...dataForm,
      document: onlyNumbers(dataForm.document),
      phone: onlyNumbers(dataForm.phone),
    })
      .then(() => {
        onClose();
        queryClient.refetchQueries({
          queryKey: ['findAllClients'],
          type: 'active',
        });
        toast({
          title: 'Sucesso',
          description: 'Cliente alterado com sucesso',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: 'Erro',
          description: err?.response?.data?.message || 'Erro ao criar Cliente',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    reset({});
  }, [reset]);

  return (
    <>
      <Button
        onClick={() => {
          onOpen();
          reset();
        }}
        size="sm"
        rounded="sm"
        variant="outline"
        colorScheme="brand"
      >
        <FaPen />
        <Text ml={2}>Editar</Text>
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Criar Cliente</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3}>
                <InputForm
                  control={control}
                  name="name"
                  label="Nome *"
                  placeholder="Fulano Ciclano"
                  type="text"
                  rules={{
                    required: 'Campo obrigatório',
                  }}
                />
                <InputMaskForm
                  mask="999.999.999-99"
                  control={control}
                  label="CPF"
                  name="document"
                  size="sm"
                  isRequired={true}
                  type="tel"
                  placeholder="CPF"
                  rules={{
                    validate: (value) => {
                      if (!value) return true;

                      return validCpf(onlyNumbers(value)) || 'CPF inválido';
                    },
                  }}
                  required
                />
                <InputForm control={control} name="email" label="Email" placeholder="cliente@email.com" type="email" />
                <InputMaskForm
                  control={control}
                  name="phone"
                  label="Telefone"
                  type="tel"
                  mask="(99) 99999-9999"
                  placeholder="Telefone"
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button colorScheme="green" isLoading={loading} onClick={handleSubmit(save)}>
                  Editar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default EditClient;
