import {
  Badge,
  Box,
  Divider,
  Grid,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { findInstallmentsConfigCustom } from 'services/api.service';
import { InstallmentsConfig } from 'services/types/installmensts.config';
import { maskCurrency } from 'utils/number';
import { calculateFee } from './CalculatedFee';
import { OriginTaxesDescription } from './Fees';

interface Props {
  isOpen: boolean;
  onlyOnline?: boolean;
}

const FeeDetails = ({ config, amount }: { config: InstallmentsConfig; amount: string }) => (
  <Box
    bg="white"
    borderRadius="lg"
    boxShadow="md"
    p={6}
  >
    <VStack
      align="stretch"
      spacing={4}
    >
      <Text
        fontSize="lg"
        fontWeight="bold"
        color="brand.500"
      >
        Configuração do Método de Pagamento
      </Text>

      <Box
        p={4}
        bg="brand.50"
        borderRadius="md"
        borderWidth="1px"
        borderColor="brand.200"
      >
        <VStack
          align="stretch"
          spacing={1}
        >
          <Text
            fontSize="sm"
            color="brand.700"
          >
            Taxa a ser cobrada
          </Text>
          <Text
            fontSize="2xl"
            fontWeight="bold"
            color="brand.700"
          >
            {maskCurrency(
              calculateFee({
                type: config.type,
                amount: config.amount,
                value: amount,
                maxFixed: config.maxFixed,
                minFixed: config.minFixed,
              }),
            )}
          </Text>
          <Text
            fontSize="sm"
            color="brand.600"
          >
            {config.type === 'PERCENTUAL'
              ? `${config.amount}% sobre ${maskCurrency(amount)}${
                  config.maxFixed && parseFloat(config.maxFixed) > 0 ? `, máximo ${maskCurrency(config.maxFixed)}` : ''
                }`
              : `${maskCurrency(config.amount)} fixo por venda efetuada.`}
          </Text>

          <Divider my={2} />

          <HStack
            justify="space-between"
            mt={2}
          >
            <VStack
              align="start"
              spacing={0}
            >
              <Text
                fontSize="sm"
                color="gray.600"
              >
                Valor Bruto:
              </Text>
              <Text
                fontSize="md"
                fontWeight="semibold"
              >
                {maskCurrency(parseFloat(amount))}
              </Text>
            </VStack>
            <Text
              fontSize="md"
              color="gray.600"
            >
              -
            </Text>
            <VStack
              align="start"
              spacing={0}
            >
              <Text
                fontSize="sm"
                color="gray.600"
              >
                Taxas:
              </Text>
              <Text
                fontSize="md"
                fontWeight="semibold"
                color="red.500"
              >
                {maskCurrency(
                  calculateFee({
                    type: config.type,
                    amount: config.amount,
                    value: amount,
                    maxFixed: config.maxFixed,
                    minFixed: config.minFixed,
                  }),
                )}
              </Text>
            </VStack>
            <Text
              fontSize="md"
              color="gray.600"
            >
              =
            </Text>
            <VStack
              align="start"
              spacing={0}
            >
              <Text
                fontSize="sm"
                color="gray.600"
              >
                Valor Líquido:
              </Text>
              <Text
                fontSize="md"
                fontWeight="bold"
                color="green.500"
              >
                {maskCurrency(
                  parseFloat(amount) -
                    calculateFee({
                      type: config.type,
                      amount: config.amount,
                      value: amount,
                      maxFixed: config.maxFixed,
                      minFixed: config.minFixed,
                    }),
                )}
              </Text>
            </VStack>
          </HStack>
        </VStack>
      </Box>

      <Grid
        templateColumns="repeat(2, 1fr)"
        gap={4}
      >
        <>
          {parseFloat(config.minFixed) > 0 && (
            <VStack align="stretch">
              <Text fontWeight="semibold">Valor Mínimo:</Text>
              <Text>{maskCurrency(parseFloat(config.minFixed))}</Text>
            </VStack>
          )}
          {parseFloat(config.maxFixed) > 0 && (
            <VStack align="stretch">
              <Text fontWeight="semibold">Valor Máximo:</Text>
              <Text>{maskCurrency(parseFloat(config.maxFixed))}</Text>
            </VStack>
          )}
        </>
        <VStack align="stretch">
          <Text fontWeight="semibold">Tipo:</Text>
          <Text>{config.type === 'PERCENTUAL' ? 'Percentual' : 'Valor Fixo'}</Text>
        </VStack>
        <VStack align="stretch">
          <Text fontWeight="semibold">Origem:</Text>
          <Text>{OriginTaxesDescription[config.origin]}</Text>
        </VStack>
        <VStack align="stretch">
          <Text fontWeight="semibold">Liberação em dias:</Text>
          {config.releaseInDays === 0 ? (
            <Badge colorScheme="brand">À vista</Badge>
          ) : (
            <Badge colorScheme="brand">{config.releaseInDays} dias</Badge>
          )}
        </VStack>
        <VStack align="stretch">
          <Text fontWeight="semibold">Antecipação:</Text>
          <Text>{config.antecipationActive ? 'Ativa' : 'Inativa'}</Text>
        </VStack>
      </Grid>
    </VStack>
  </Box>
);

const LoadingSkeleton = () => (
  <Box
    bg="white"
    borderRadius="lg"
    boxShadow="md"
    p={6}
  >
    <VStack
      align="stretch"
      spacing={4}
    >
      {/* Header */}
      <Box
        h="24px"
        w="60%"
        bg="gray.100"
        borderRadius="md"
        animation="pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite"
      />

      {/* Fee Box */}
      <Box
        p={4}
        bg="gray.50"
        borderRadius="md"
        borderWidth="1px"
        borderColor="gray.200"
      >
        <VStack
          align="stretch"
          spacing={3}
        >
          <Box
            h="16px"
            w="40%"
            bg="gray.100"
            borderRadius="md"
          />
          <Box
            h="32px"
            w="30%"
            bg="gray.100"
            borderRadius="md"
          />
          <Box
            h="16px"
            w="70%"
            bg="gray.100"
            borderRadius="md"
          />
        </VStack>
      </Box>

      {/* Grid Items */}
      <Grid
        templateColumns="repeat(3, 1fr)"
        gap={4}
      >
        {[...Array(6)].map((_, i) => (
          <VStack
            key={i}
            align="stretch"
            spacing={2}
          >
            <Box
              h="16px"
              w="60%"
              bg="gray.100"
              borderRadius="md"
            />
            <Box
              h="16px"
              w="40%"
              bg="gray.100"
              borderRadius="md"
            />
          </VStack>
        ))}
      </Grid>
    </VStack>
  </Box>
);

export const InstallmentCalculator = ({ isOpen, onlyOnline }: Props) => {
  const [amount, setAmount] = useState<string>('');
  const [paymentMethod, setPaymentMethod] = useState<string>('');

  const { data: config, isLoading } = useQuery({
    queryKey: ['installmentsConfigCustom', paymentMethod],
    queryFn: () => findInstallmentsConfigCustom(paymentMethod),
    enabled: isOpen && !!paymentMethod,
  });

  const calculateInstallments = (installmentType: 'FROM_PAYER' | 'FROM_RECEIVER') => {
    const value = parseFloat(amount);
    if (!value || isNaN(value)) return [];

    const installmentsArray = [];
    const MIN_INSTALLMENT_VALUE = 5;
    const maxInstallments = config?.maxInstallments ?? 0;

    for (let i = 1; i <= maxInstallments; i++) {
      const installmentFee = (config?.installments?.[i.toString()]?.fee || 0) / 100;

      // Cálculo ajustado para FROM_PAYER
      if (installmentType === 'FROM_PAYER') {
        // Valor que precisamos cobrar para receber o valor desejado após as taxas
        const totalValueWithFee = value / (1 - installmentFee);
        const installmentValue = totalValueWithFee / i;

        // Pula esta opção de parcelamento se o valor da parcela for menor que o mínimo
        if (installmentValue < MIN_INSTALLMENT_VALUE) continue;

        installmentsArray.push({
          number: i,
          fee: installmentFee * 100,
          installmentValue: installmentValue,
          totalValue: totalValueWithFee,
          netValue: value, // Valor líquido será exatamente o valor desejado
        });
      } else {
        // Lógica original para FROM_RECEIVER
        const installmentValue = value / i;
        const installmentWithFee = installmentValue;

        if (installmentWithFee < MIN_INSTALLMENT_VALUE) continue;

        installmentsArray.push({
          number: i,
          fee: installmentFee * 100,
          installmentValue: installmentWithFee,
          totalValue: value,
          netValue: value * (1 - installmentFee),
        });
      }
    }
    return installmentsArray;
  };

  const InstallmentTable = ({ installmentType }: { installmentType: 'FROM_PAYER' | 'FROM_RECEIVER' }) => (
    <Box>
      <Grid
        templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' }}
        gap={4}
        w="100%"
      >
        {calculateInstallments(installmentType).map((item) => (
          <Box
            key={item.number}
            bg="white"
            borderRadius="lg"
            boxShadow="md"
            p={4}
            transition="transform 0.2s"
            _hover={{ transform: 'translateY(-2px)' }}
          >
            <VStack
              spacing={3}
              align="stretch"
            >
              <HStack justify="space-between">
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  color="brand.500"
                >
                  {item.number}x
                </Text>
                <Badge colorScheme="brand">{item.fee.toFixed(2)}% juros</Badge>
              </HStack>

              <Divider />

              <VStack
                spacing={2}
                align="stretch"
              >
                <HStack justify="space-between">
                  <Text
                    fontSize="sm"
                    color="gray.500"
                  >
                    Valor da Parcela
                  </Text>
                  <Text fontWeight="semibold">{maskCurrency(item.installmentValue)}</Text>
                </HStack>

                <HStack justify="space-between">
                  <Text
                    fontSize="sm"
                    color="gray.500"
                  >
                    Valor Total
                  </Text>
                  <Text fontWeight="semibold">{maskCurrency(item.totalValue)}</Text>
                </HStack>
                <Divider />
                <HStack justify="space-between">
                  <Text
                    fontSize="sm"
                    color="gray.500"
                  >
                    Você receberá
                  </Text>
                  <Text fontWeight="semibold">{maskCurrency(item.netValue)}</Text>
                </HStack>
                <HStack justify="space-between">
                  <Text
                    fontSize="sm"
                    color="gray.500"
                  >
                    Liberação em dias
                  </Text>
                  <Text fontWeight="semibold">{config?.releaseInDays} dias</Text>
                </HStack>
              </VStack>
            </VStack>
          </Box>
        ))}
      </Grid>
    </Box>
  );

  return (
    <VStack
      spacing={4}
      align="stretch"
      w="100%"
      maxW="800px"
    >
      <Box>
        <Text mb={2}>Método de Pagamento:</Text>
        <RadioGroup
          onChange={setPaymentMethod}
          value={paymentMethod}
        >
          <HStack align="start">
            {!onlyOnline && (
              <Box
                borderWidth="1px"
                borderRadius="md"
                p={4}
                w="50%"
              >
                <Text
                  fontWeight="bold"
                  mb={2}
                >
                  Pagamentos Físicos
                </Text>
                <Stack>
                  <Radio value="DEBIT_CARD">Cartão de Débito</Radio>
                  <Radio value="DEBIT_VISA_MASTER">Débito Visa/Master</Radio>
                  <Radio value="DEBIT_ELO">Débito Elo</Radio>
                  <Radio value="CREDIT_CARD">Cartão de Crédito</Radio>
                  <Radio value="CREDIT_VISA_MASTER">Crédito Visa/Master</Radio>
                  <Radio value="CREDIT_ELO">Crédito Elo</Radio>
                  <Radio value="PIX">Pix</Radio>
                  <Radio value="VALE_ALIMENTACAO">Vale Alimentação</Radio>
                  <Radio value="VALE_REFEICAO">Vale Refeição</Radio>
                </Stack>
              </Box>
            )}

            <Box
              borderWidth="1px"
              borderRadius="md"
              w={onlyOnline ? '100%' : '50%'}
              p={4}
            >
              <Text
                fontWeight="bold"
                mb={2}
              >
                Pagamentos Online
              </Text>
              <Stack>
                <Radio value="INTEGRATION_TICKET">Boleto</Radio>
                <Radio value="INTEGRATION_PIX">Pix</Radio>
                <Radio value="INTEGRATION_CREDIT_CARD">Cartão de Crédito</Radio>
                <Radio value="INTEGRATION_DEBIT_CARD">Cartão de Débito</Radio>
              </Stack>
            </Box>
          </HStack>
        </RadioGroup>
      </Box>
      <Box>
        <Text mb={2}>Valor da compra:</Text>

        <NumericFormat
          decimalScale={2}
          prefix={'R$ '}
          name={'amount'}
          value={amount}
          disabled={false}
          onValueChange={(values) => {
            setAmount(values.value);
          }}
          inputMode={'numeric'}
          style={{
            height: '50px',
            borderRadius: '5px',
            backgroundColor: 'white',
          }}
          decimalSeparator=","
          thousandSeparator="."
          placeholder={'Digite o valor'}
          customInput={Input as any}
          fixedDecimalScale={true}
          allowLeadingZeros={true}
        />
        <Text
          fontSize="sm"
          mt={2}
          color="gray.500"
        >
          O valor mínimo para calcular as taxas é de R$ 5,00
        </Text>
      </Box>
      {isLoading && paymentMethod && parseFloat(amount) > 5 && <LoadingSkeleton />}
      {amount && parseFloat(amount) > 5 && config && !isLoading && (
        <>
          {['INTEGRATION_CREDIT_CARD', 'CREDIT_CARD', 'CREDIT_VISA_MASTER', 'CREDIT_ELO'].includes(config.origin) ? (
            <Tabs
              isFitted
              variant="enclosed"
            >
              <TabList mb="1em">
                <Tab>Assumir as taxas</Tab>
                <Tab>Repassar taxas ao cliente</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Text
                    mb={4}
                    fontSize="sm"
                    color="gray.600"
                  >
                    Nesta modalidade, você assume as taxas de parcelamento
                  </Text>
                  <InstallmentTable installmentType="FROM_RECEIVER" />
                </TabPanel>
                <TabPanel>
                  <Text
                    mb={4}
                    fontSize="sm"
                    color="gray.600"
                  >
                    Nesta modalidade, o cliente paga as taxas de parcelamento.
                    <br />
                    Informe o valor abaixo na máquina caso deseje repassar as taxas ao cliente.
                  </Text>
                  {<InstallmentTable installmentType="FROM_PAYER" />}
                </TabPanel>
              </TabPanels>
            </Tabs>
          ) : (
            <FeeDetails
              config={config}
              amount={amount}
            />
          )}
        </>
      )}
    </VStack>
  );
};
