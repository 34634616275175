import { Box, Card, Divider, Heading, Icon, SimpleGrid, Text, useToast, VStack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import CustomButton from 'components/CustomButton';
import { MdFingerprint, MdNotifications, MdSecurity, MdVpnKey } from 'react-icons/md';
import { accountFeatures, changeFeatureNotification } from 'services/api.service';
import Enable2FA from '../Enable2FA';
import ChangePasswordCustomer from './ChangePasswordCustomer';
import DeleteAccount from './DeleteAccount';
import EnableSafeCode from './EnableSafeCode';

const AccountSettings = () => {
  const { data, refetch } = useQuery(['features'], accountFeatures);

  const toast = useToast();

  const handleNotifications = async (type: 'push' | 'email') => {
    try {
      await changeFeatureNotification(type);
      refetch();
      toast({
        title: 'Sucesso',
        description: `Notificações por ${type === 'email' ? 'e-mail' : 'push'} ${
          type === 'email'
            ? data?.emailsEnabled
              ? 'desativadas'
              : 'ativadas'
            : data?.notificationsEnabled
            ? 'desativadas'
            : 'ativadas'
        } com sucesso`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erro',
        description: 'Não foi possível alterar as configurações de notificação',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const SecurityCard = ({ title, icon, children }: { title: string; icon: any; children: React.ReactNode }) => (
    <Card
      flexDirection="column"
      w="100%"
      p={4}
      boxShadow="sm"
      transition="all 0.2s"
      _hover={{ boxShadow: 'md' }}
    >
      <Box
        display="flex"
        alignItems="center"
        mb={3}
      >
        <Icon
          as={icon}
          fontSize="24px"
          mr={2}
          color="blue.500"
        />
        <Text fontWeight="bold">{title}</Text>
      </Box>
      <Divider mb={3} />
      {children}
    </Card>
  );

  return (
    <Card
      flexDirection="column"
      w="100%"
      p={6}
    >
      <Heading
        fontSize="2xl"
        mb={6}
      >
        Configurações da Conta
      </Heading>
      <Text
        fontWeight="bold"
        fontSize="lg"
        mb={4}
        color="blue.600"
      >
        Segurança e Notificações
      </Text>
      <VStack
        spacing={6}
        align="stretch"
      >
        <SecurityCard
          title="Senha"
          icon={MdVpnKey}
        >
          <ChangePasswordCustomer />
        </SecurityCard>

        <SecurityCard
          title="Notificações"
          icon={MdNotifications}
        >
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            spacing={6}
          >
            <VStack
              align="start"
              spacing={3}
            >
              <Text fontWeight="semibold">Notificações por E-mail</Text>
              <Text
                fontSize="sm"
                color="gray.600"
              >
                Receba notificações de transações, saques e depósitos em sua conta.
              </Text>
              <CustomButton
                onClick={() => handleNotifications('email')}
                colorScheme={data?.emailsEnabled ? 'red' : 'blue'}
              >
                {data?.emailsEnabled ? 'Desativar' : 'Ativar'} E-mail
              </CustomButton>
            </VStack>

            <VStack
              align="start"
              spacing={3}
            >
              <Text fontWeight="semibold">Notificações Push</Text>
              <Text
                fontSize="sm"
                color="gray.600"
              >
                Receba notificações de transações, saques e depósitos em sua conta.
              </Text>
              <CustomButton
                onClick={() => handleNotifications('push')}
                colorScheme={data?.notificationsEnabled ? 'red' : 'blue'}
              >
                {data?.notificationsEnabled ? 'Desativar' : 'Ativar'} Push
              </CustomButton>
            </VStack>
          </SimpleGrid>
        </SecurityCard>

        <SecurityCard
          title="Senha Mestre"
          icon={MdSecurity}
        >
          <Text
            fontSize="sm"
            color="gray.600"
            mb={3}
          >
            A senha mestre é uma camada extra de segurança para sua conta. Será solicitada sempre que você realizar uma
            transação de risco.
          </Text>
          {data && <EnableSafeCode isEnabled={data?.masterPasswordEnabled} />}
        </SecurityCard>

        <SecurityCard
          title="Autenticação em Dois Fatores (2FA)"
          icon={MdFingerprint}
        >
          <Text
            fontSize="sm"
            color="gray.600"
            mb={3}
          >
            A autenticação de dois fatores é uma camada extra de segurança para autenticar sua conta.
          </Text>
          {data && !data?.twoFaEnabled && <Enable2FA />}
          {data && data?.twoFaEnabled && (
            <Text
              color="green.500"
              fontWeight="semibold"
            >
              2FA de autenticação ATIVADO
            </Text>
          )}
        </SecurityCard>

        <DeleteAccount />
      </VStack>
    </Card>
  );
};

export default AccountSettings;
