import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Button,
  useDisclosure,
  Tooltip,
  Badge,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { SelectOptionType } from 'components/FormRemoteSelectInput';
import { queryClient } from 'index';
import { DateTime } from 'luxon';
import React from 'react';
import { FaEye, FaMailBulk } from 'react-icons/fa';
import { IoRefresh } from 'react-icons/io5';
import {
  cancelRecurrencePayment,
  createNewRecurrencePaymentRequest,
  doneRecurrencePayment,
  findClientPayments,
  newChargeForGeneratedRecurrence,
  sendMailRecurrencePaymentRequest,
} from 'services/api.service';
import { GeneratesRecurrence } from 'services/types/RecurrencyPayments.entity';
import { maskCurrency } from 'utils/number';

export type CreateRecurrencyForm = {
  amount: string;
  description: string;
  initDate: string;
  period: SelectOptionType;
  endDate: string;
};

enum StatusDescription {
  PENDING = 'Pendente',
  CONFIRMED = 'Confirmado',
  CANCELED = 'Cancelado',
  INADIMPLANT = 'Inadimplente',
}

const ShowPayments: React.FC<{
  payments?: GeneratesRecurrence[];
  recurrenceClientId: string;
  name: string;
  amount: string;
}> = ({ payments, recurrenceClientId, name, amount }) => {
  const toast = useToast();
  const { mutateAsync: sendEmail } = useMutation(sendMailRecurrencePaymentRequest);

  const { mutateAsync: done } = useMutation(doneRecurrencePayment);
  const { mutateAsync: cancel } = useMutation(cancelRecurrencePayment);
  const { mutateAsync: newCharge } = useMutation(newChargeForGeneratedRecurrence);

  const refresh = async () => {
    await queryClient.invalidateQueries([`findPaymentsRecurrency`, recurrenceClientId]);
  };

  const handleSendEmail = async (recurrenceId: string) => {
    try {
      await sendEmail(recurrenceId);
      refresh();
      toast({
        title: 'E-mail enviado com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: isAxiosError(error) ? error?.response?.data?.message : 'Erro ao enviar e-mail',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDone = async (recurrenceId: string) => {
    try {
      await done(recurrenceId);
      refresh();
      toast({
        title: 'Pagamento concluído com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: isAxiosError(error) ? error?.response?.data?.message : 'Erro ao concluir pagamento',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCancel = async (recurrenceId: string) => {
    try {
      await cancel(recurrenceId);
      refresh();
      toast({
        title: 'Pagamento cancelado com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: isAxiosError(error) ? error?.response?.data?.message : 'Erro ao cancelar pagamento',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleNewCharge = async (recurrenceId: string) => {
    try {
      await newCharge(recurrenceId);
      refresh();
      toast({
        title: 'Cobrança gerada com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: isAxiosError(error) ? error?.response?.data?.message : 'Erro ao gerar cobrança',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <TableContainer>
      <Table variant="striped" size="sm">
        <Thead>
          <Tr>
            <Th isNumeric>Descrição</Th>
            <Th isNumeric>Valor</Th>
            <Th isNumeric>Status</Th>
            <Th>Criação</Th>
            <Th>Vencimento</Th>
            <Th>Data de pagamento</Th>
            <Th>Ações</Th>
          </Tr>
        </Thead>
        <Tbody>
          {payments?.map((item) => (
            <Tr key={item.id}>
              <Td>{item.charge?.description ?? name ?? 'Pagamento manual'}</Td>
              <Td isNumeric> {maskCurrency(item.charge?.amount ?? amount)}</Td>
              <Td isNumeric>
                <Badge
                  colorScheme={item.status === 'CANCELED' ? 'red' : item.status === 'CONFIRMED' ? 'green' : 'orange'}
                >
                  {StatusDescription[item.status]}
                </Badge>
              </Td>
              <Td>{item?.date ? DateTime.fromISO(item?.date).toFormat('dd/MM/yyyy HH:mm') : '---'}</Td>
              <Td>
                {item?.charge?.expireAt ? DateTime.fromISO(item?.charge?.expireAt).toFormat('dd/MM/yyyy HH:mm') : '---'}
              </Td>
              <Td>
                {item?.charge?.payedAt ? DateTime.fromISO(item?.charge?.payedAt).toFormat('dd/MM/yyyy HH:mm') : '---'}
              </Td>

              <Td>
                <HStack>
                  <Tooltip label="Disparar e-mail de cobrança" aria-label="Disparar e-mail de cobrança">
                    <IconButton
                      isDisabled={item.status !== 'PENDING'}
                      aria-label="Disparar e-mail de cobrança"
                      icon={<FaMailBulk />}
                      size="sm"
                      colorScheme="brand"
                      onClick={() => {
                        handleSendEmail(item.id);
                      }}
                    />
                  </Tooltip>
                  <Tooltip label="Atualizar cobrança" aria-label="Atualizar cobrança">
                    <IconButton
                      aria-label="Atualizar cobrança"
                      icon={<IoRefresh />}
                      isDisabled={item.status !== 'PENDING'}
                      size="sm"
                      colorScheme="brand"
                      onClick={() => {
                        handleNewCharge(item.id);
                      }}
                    />
                  </Tooltip>
                  <Tooltip label="Concluir cobrança" aria-label="Concluir cobrança">
                    <IconButton
                      aria-label="Concluir cobrança"
                      icon={<CheckIcon />}
                      size="sm"
                      isDisabled={!['PENDING', 'INADIMPLANT'].includes(item.status)}
                      colorScheme="brand"
                      onClick={() => {
                        handleDone(item.id);
                      }}
                    />
                  </Tooltip>
                  <Tooltip label="Cancelar cobrança" aria-label="Cancelar cobrança">
                    <IconButton
                      aria-label="Cancelar cobrança"
                      icon={<CloseIcon />}
                      isDisabled={!['PENDING', 'INADIMPLANT'].includes(item.status)}
                      size="sm"
                      colorScheme="brand"
                      onClick={() => {
                        handleCancel(item.id);
                      }}
                    />
                  </Tooltip>
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const ShowPaymentsRecurrence: React.FC<{
  clientRecurrencyId: string;
  oncloseMainModal: () => void;
  recurrencyName: string;
  amount: string;
}> = ({ clientRecurrencyId, oncloseMainModal, recurrencyName, amount }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { data, isLoading } = useQuery(
    [`findPaymentsRecurrency`, clientRecurrencyId],
    () => findClientPayments(clientRecurrencyId),
    {
      enabled: isOpen,
    },
  );

  const { mutateAsync: newRecurrencePayment, isLoading: isLoadingNewRecurrence } = useMutation(
    createNewRecurrencePaymentRequest,
  );

  const refreshData = async () => {
    await queryClient.invalidateQueries(['findPaymentsRecurrency', clientRecurrencyId]);
  };

  const handleCreateNewRecurrencePayment = async (recurrenceId: string, withoutCharge = false) => {
    try {
      await newRecurrencePayment({ id: recurrenceId, withoutCharge });
      toast({
        title: 'Pagamento gerado com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      refreshData();
    } catch (error) {
      toast({
        title: isAxiosError(error) ? error?.response?.data?.message : 'Erro ao gerar pagamento',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Tooltip label="Consultar pagamentos">
        <Button
          onClick={() => {
            onOpen();
          }}
          size="sm"
          rounded="sm"
          variant="outline"
          colorScheme="brand"
        >
          <FaEye />
        </Button>
      </Tooltip>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={() => {
            onClose();
            oncloseMainModal();
          }}
          size="4xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Consultar pagamentos de - {data?.client?.name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ShowPayments
                payments={data?.generatesRecurrences}
                recurrenceClientId={clientRecurrencyId}
                name={recurrencyName}
                amount={amount}
              />
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={isLoading}
                  onClick={() => {
                    onClose();
                    oncloseMainModal();
                  }}
                >
                  Fechar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={isLoading || isLoadingNewRecurrence}
                  onClick={() => {
                    handleCreateNewRecurrencePayment(clientRecurrencyId);
                  }}
                >
                  Criar cobrança
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={isLoading || isLoadingNewRecurrence}
                  onClick={() => {
                    handleCreateNewRecurrencePayment(clientRecurrencyId, true);
                  }}
                >
                  Lançar pagamento
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ShowPaymentsRecurrence;
