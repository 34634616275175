import React from 'react';

// chakra imports
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  Icon,
  useColorModeValue,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import Content from 'components/sidebar/components/Content';
import { renderThumb, renderTrack, renderView } from 'components/scrollbar/Scrollbar';
import { Scrollbars } from 'react-custom-scrollbars-2';

// Assets
import { IoMenuOutline } from 'react-icons/io5';

function Sidebar(props: { routes: RoutesType[]; closeSidebar: () => void; [x: string]: any }) {
  const { routes } = props;

  let variantChange = '0.2s linear';
  let shadow = useColorModeValue('14px 17px 40px 4px rgba(112, 144, 176, 0.08)', 'unset');
  // Chakra Color Mode
  let sidebarBg = useColorModeValue('brand.900', 'navy.800');
  let sidebarMargins = '0px';
  let borderTopRightRadius = '10px';
  let borderBottomRightRadius = '10px';

  // SIDEBAR
  return (
    <Box display={{ sm: 'none', xl: 'block' }} position="fixed" minH="100%">
      <Box
        bg={sidebarBg}
        transition={variantChange}
        w="300px"
        h="100vh"
        m={sidebarMargins}
        borderTopRightRadius={borderTopRightRadius}
        borderBottomRightRadius={borderBottomRightRadius}
        minH="100%"
        boxShadow={shadow}
      >
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={renderView}
        >
          <Content routes={routes} closeSidebar={props.closeSidebar} />
        </Scrollbars>
      </Box>
    </Box>
  );
}

// FUNCTIONS
export function SidebarResponsive(props: { routes: RoutesType[] }) {
  let sidebarBackgroundColor = useColorModeValue('brand.900', 'navy.800');
  let menuColor = useColorModeValue('gray.400', 'white');
  // // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const { routes } = props;
  // let isWindows = navigator.platform.startsWith("Win");
  //  BRAND

  return (
    <Flex display={{ sm: 'flex', xl: 'none' }} alignItems="center">
      <Flex ref={btnRef} onClick={onOpen} _hover={{ cursor: 'pointer' }}>
        <Icon as={IoMenuOutline} color={menuColor} />
      </Flex>
      {isOpen && (
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          placement={document.documentElement.dir === 'rtl' ? 'right' : 'left'}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent w="300px" maxW="300px" bg={sidebarBackgroundColor}>
            <DrawerCloseButton
              zIndex="3"
              style={{ color: 'white' }}
              mt="var(--safe-area-inset-top)"
              onClick={onClose}
              _focus={{ boxShadow: 'none' }}
              _hover={{ boxShadow: 'none' }}
            />
            <DrawerBody maxW="300px" px="0rem" pb="0">
              <Scrollbars
                autoHide
                renderTrackVertical={renderTrack}
                renderThumbVertical={renderThumb}
                renderView={renderView}
              >
                <Content routes={routes} closeSidebar={onClose} />
              </Scrollbars>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </Flex>
  );
}
// PROPS

export default Sidebar;
