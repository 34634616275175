import {
  Button,
  Divider,
  HStack,
  Link,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import InputForm from 'components/InputForm';
import InputMaskForm from 'components/InputMask';
import SelectForm from 'components/SelectForm';
import { DateTime } from 'luxon';
import React, { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { FaCreditCard } from 'react-icons/fa';
import api, { findInstallmentsConfig, infoAccount, payWithCreditCardRecharge } from 'services/api.service';
import { validCpf } from 'services/validDocument';
import { calcularParcelasMaximas, maskCurrency } from 'utils/currency';
import { onlyNumbers } from 'utils/number';

interface PagSeguroResponse {
  status: string;
  authenticationStatus: string;
  id: string;
}

export interface FormCardCreditCard {
  cardNumber: string;
  cardExpiration: string;
  cardCvv: string;
  cardHolderName: string;
  cardHolderCpf: string;
  installments: number;
  pagSeguro3dsId?: string;
  method: 'CREDIT_CARD';
}

const PagSeguroCard: React.FC<{
  method: 'CREDIT_CARD';
  amount: number;
  isOpen: boolean;
  onClose: () => void;
}> = ({ amount, method, isOpen, onClose }) => {
  const toast = useToast();
  const { data: account } = useQuery({
    queryKey: ['account'],
    queryFn: () => infoAccount(),
    enabled: !!isOpen,
  });

  const { data: installmentsConfig } = useQuery({
    queryKey: ['installmentsConfig'],
    queryFn: () => findInstallmentsConfig(),
  });

  const [isLoading, setIsLoading] = React.useState(false);
  const { control, handleSubmit } = useForm<FormCardCreditCard>({
    defaultValues: {
      installments: 1,
    },
    progressive: true,
    shouldFocusError: true,
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      window.alert('Recaptcha has not been loaded');
      return;
    }

    return await executeRecaptcha('PAYMENT_CREDIT_CARD');
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  const calculateInstallment = (amount: number, installment: number) => {
    const fee = installmentsConfig?.installments?.[installment]?.fee ?? 1;
    let newAmount = amount + (amount * fee) / 100;

    return { fee, amount: newAmount };
  };

  const handlePayWithCreditCard = async (formData: FormCardCreditCard, pagSeguro3dsId: string | null) => {
    const token = await handleReCaptchaVerify();
    payWithCreditCardRecharge({
      ...formData,
      cardNumber: onlyNumbers(formData.cardNumber),
      cardHolderCpf: onlyNumbers(formData.cardHolderCpf),
      recaptchaToken: token,
      installments: +formData.installments,
      pagSeguro3dsId,
      method,
      amount,
    })
      .then(() => {
        setIsLoading(false);
        onClose();
        toast({
          title: 'Pagamento realizado com sucesso',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(onError);
  };

  const onSubmit = async (formData: FormCardCreditCard) => {
    setIsLoading(true);

    if (amount <= 1000) {
      await handlePayWithCreditCard(formData, null);
      return;
    }

    await handleReCaptchaVerify()
      .then(async (token) => {
        await api
          .post<{ session: string; env: string }>(`/charges/pagseguro/session`, {
            recaptchaToken: token,
          })
          .then(async (response) => {
            window.PagSeguro.setUp({
              session: response.data.session,
              env: response.data.env,
            });
          });
      })
      .catch(onError);
    const phone = account?.CustomerContact[0]?.contact;
    const address = account?.CustomerAddress[0];
    const request = {
      data: {
        customer: {
          name: formData.cardHolderName,
          email: account?.email,
          phones: [
            {
              country: '55',
              area: phone?.substring(0, 1),
              number: phone?.substring(2),
              type: 'MOBILE',
            },
          ],
        },
        paymentMethod: {
          type: method,
          installments: +formData.installments,
          card: {
            number: onlyNumbers(formData.cardNumber),
            expMonth: formData.cardExpiration.split('/')[0],
            expYear: formData.cardExpiration.split('/')[1],
            holder: {
              name: formData.cardHolderName,
            },
          },
        },
        amount: {
          value: (calculateInstallment(amount, +formData.installments).amount * 100).toFixed(0),
          currency: 'BRL',
        },
        billingAddress: {
          street: address?.address,
          number: address?.number_address,
          complement: address?.complement,
          regionCode: address?.Cep.state.uf,
          country: 'BRA',
          city: address?.Cep.City.name,
          postalCode: onlyNumbers(address?.Cep.cep),
        },
        shippingAddress: {
          street: address?.address,
          number: address?.number_address,
          complement: address?.complement,
          country: 'BRA',
          city: address?.Cep.City.name,
          postalCode: onlyNumbers(address?.Cep.cep),
        },
        dataOnly: false,
      },
    };

    window.PagSeguro.authenticate3DS(request)
      .then(async (result: PagSeguroResponse) => {
        if (result.status === 'AUTH_NOT_SUPPORTED') {
          toast({
            title: 'Autenticação não suportada. Utilize outro cartão',
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }
        if (result.status === 'AUTH_FLOW_COMPLETED') {
          await handlePayWithCreditCard(formData, result.id);
        } else {
          toast({
            title: 'Erro ao realizar pagamento',
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        if (err instanceof window.PagSeguro.PagSeguroError) {
          console.log(err);
          console.log(err.detail);
        }
        setIsLoading(false);
        toast({
          title: 'Erro ao realizar pagamento',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      });

    //const token = await handleReCaptchaVerify();
  };

  const onError = async (error) => {
    setIsLoading(false);
    toast({
      title: error?.response?.data?.message ?? 'Erro ao realizar pagamento',
      status: 'error',
      duration: 2000,
      isClosable: true,
    });
  };

  let maxQuantiyParcelas = calcularParcelasMaximas(+amount, installmentsConfig?.maxInstallments ?? 12);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text
            fontSize="lg"
            fontWeight="bold"
            textAlign="center"
          >
            {method === 'CREDIT_CARD' ? 'Cartão de crédito' : 'Cartão de débito'}
          </Text>
        </ModalHeader>
        <VStack mb={2}>
          <Text
            fontSize="sm"
            textAlign="center"
          >
            Taxas de juros podem ser aplicadas na modalidade de pagamento de crédito.
          </Text>

          <Text>Recarga de {maskCurrency(amount)}</Text>

          <Divider mb={4} />

          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ maxWidth: 500, width: '100%' }}
          >
            <VStack spacing={3}>
              <InputForm
                type="text"
                name="cardHolderName"
                placeholder="Insira o nome do titular"
                control={control}
                label="Nome do titular"
                rules={{
                  required: 'Campo obrigatório',
                  validate: {
                    valid: (value) => {
                      return value.split(' ').length > 1 || 'Nome inválido';
                    },
                  },
                }}
              />
              <InputMaskForm
                type="text"
                name="cardHolderCpf"
                placeholder="999.999.999-99"
                control={control}
                mask="999.999.999-99"
                label="CPF do titular"
                rules={{
                  required: 'Campo obrigatório',
                  minLength: {
                    value: 11,
                    message: 'CPF inválido',
                  },
                  validate: {
                    valid: (value) => {
                      value = onlyNumbers(value);

                      return validCpf(value) || 'Documento inválido';
                    },
                  },
                }}
              />

              <Divider />

              <InputMaskForm
                type="text"
                name="cardNumber"
                placeholder="XXXX XXXX XXXX XXXX"
                control={control}
                mask="9999 9999 9999 9999"
                label="Número do cartão"
                rules={{
                  required: 'Campo obrigatório',
                  validate: {
                    valid: (value) => {
                      value = onlyNumbers(value);
                      return value.length === 16 || 'Número do cartão inválido';
                    },
                  },
                }}
              />
              <HStack width="100%">
                <InputMaskForm
                  type="text"
                  name="cardExpiration"
                  mask="99/9999"
                  placeholder="MM/YYYY"
                  control={control}
                  label="Data de expiração"
                  rules={{
                    required: 'Campo obrigatório',
                    minLength: {
                      value: 7,
                      message: 'Data inválida',
                    },
                    maxLength: {
                      value: 7,
                      message: 'Data inválida',
                    },
                    validate: {
                      valid: (value) => {
                        const [month, year] = value.split('/');
                        return parseInt(month) <= 12 && parseInt(month) > 0 && parseInt(year) >= DateTime.now().year;
                      },
                    },
                  }}
                />

                <InputMaskForm
                  type="text"
                  name="cardCvv"
                  mask="9999"
                  placeholder="CVV"
                  control={control}
                  label="CVV"
                  rules={{
                    required: 'Campo obrigatório',
                    minLength: {
                      value: 3,
                      message: 'Código inválido',
                    },
                    maxLength: {
                      value: 4,
                      message: 'Código inválido',
                    },
                  }}
                />
              </HStack>
              {method === 'CREDIT_CARD' && (
                <SelectForm
                  name="installments"
                  label="Parcelas"
                  control={control}
                  rules={{
                    required: 'Campo obrigatório',
                  }}
                  options={[
                    ...Array.from({ length: maxQuantiyParcelas }, (_, i) => {
                      const installment = i + 1;
                      const { amount: newAmount, fee } = calculateInstallment(amount, installment);
                      const finalMonthly = newAmount / installment;

                      return {
                        label: `${installment}x - ${maskCurrency(finalMonthly)} = ${maskCurrency(
                          newAmount,
                        )} - (${fee.toFixed(2)}%)`,
                        value: installment.toString(),
                      };
                    }),
                  ]}
                />
              )}
              <Divider />
              <Text
                fontSize="sm"
                textAlign="center"
              >
                Taxas de juros podem ser aplicadas na modalidade de pagamento de crédito.
              </Text>
              <Divider />
              <Text
                fontSize="sm"
                textAlign="center"
              >
                <>
                  Ao clicar em pagar, você concorda com os{' '}
                  <Link
                    href="/termos-de-uso"
                    isExternal
                    colorScheme="brand"
                  >
                    termos de uso
                  </Link>{' '}
                  e{' '}
                  <Link
                    href="/politica-de-privacidade"
                    isExternal
                  >
                    política de privacidade
                  </Link>
                  .
                </>
              </Text>
              <HStack width="100%">
                <Button
                  width="100%"
                  mt={3}
                  variant="solid"
                  isLoading={isLoading}
                  colorScheme="brand"
                  type="submit"
                  leftIcon={<FaCreditCard />}
                >
                  Recarregar
                </Button>
              </HStack>
            </VStack>
          </form>
        </VStack>
      </ModalContent>
    </Modal>
  );
};

export default PagSeguroCard;
