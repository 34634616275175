import { FormControl, FormControlProps, FormErrorMessage, FormLabelProps } from '@chakra-ui/react';
import { AsyncProps, AsyncSelect } from 'chakra-react-select';
import { PropsWithoutRef, useEffect, useState } from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { useDebounce } from 'react-use';
import FormLabelCustom from './FormLabel';

export type SelectOptionType = {
  label: string;
  value: string;
};

type LoadDataFnArgs = {
  value: string;
  cb(params: SelectOptionType[]): void;
};

interface FormSelectInputProps extends PropsWithoutRef<Omit<AsyncProps<any, any, any>, `onChange`>> {
  name: string;
  label: string;
  control: Control<any, object>;
  outerProps?: PropsWithoutRef<FormControlProps>;
  labelProps?: PropsWithoutRef<FormLabelProps>;
  loadDataFn: (params: LoadDataFnArgs) => void;
  onChange?: (v: SelectOptionType) => void;
  registerOptions?: RegisterOptions;
  isClearable?: boolean;
  filters?: any;
}

function FormRemoteSelectInput({
  name,
  label,
  outerProps = {},
  labelProps = {},
  control,
  onChange: customOnChange,
  loadDataFn,
  registerOptions,
  filters,
  ...props
}: FormSelectInputProps) {
  const [state, setLoadState] = useState<LoadDataFnArgs>();

  useDebounce(
    () => {
      if (state) loadDataFn(state);
    },
    500,
    [state],
  );

  useEffect(() => {
    if (state) loadDataFn(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <Controller
      name={name}
      control={control}
      rules={registerOptions}
      render={({ field: { onChange, ...field }, formState: { isSubmitting }, fieldState: { invalid, error } }) => (
        <FormControl {...outerProps} isInvalid={invalid} isDisabled={isSubmitting}>
          <FormLabelCustom label={label} name={name} props={labelProps} />

          <AsyncSelect
            {...field}
            {...props}
            defaultOptions
            id={name}
            loadOptions={(v: string, cb: any) => {
              setLoadState({ value: v, cb });
            }}
            onChange={(v: any) => {
              if (customOnChange) {
                onChange(v);
                return customOnChange(v);
              }
              return onChange(v);
            }}
            placeholder="Clique para selecionar"
            loadingMessage={() => `Buscando...`}
            escapeClearsValue
            noOptionsMessage={() => `Nenhuma opção encontrada`}
          />

          <FormErrorMessage>{error && error.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
}

export default FormRemoteSelectInput;
