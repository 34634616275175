import { FormControl, FormControlProps, FormErrorMessage, FormLabelProps, Input, InputProps } from '@chakra-ui/react';
import { ChangeEventHandler, PropsWithoutRef } from 'react';
import { Control, Controller, FieldValues, RegisterOptions, UseFormSetValue } from 'react-hook-form';
import { onlyNumbers } from 'utils/number';
import { maskPixKey, unMaskPixKey } from 'utils/pix';
import FormLabelCustom from './FormLabel';

interface FormInputProps<T extends FieldValues> extends PropsWithoutRef<InputProps> {
  name: string;
  label: string;
  control: Control<T, object>;
  outerProps?: PropsWithoutRef<FormControlProps>;
  labelProps?: PropsWithoutRef<FormLabelProps>;
  registerOptions?: RegisterOptions;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  setValue: UseFormSetValue<T>;
}

function PixKeyInput({
  name,
  label,
  outerProps = {},
  labelProps = {},
  control,
  registerOptions = {},
  setValue,
  ...props
}: FormInputProps<any>) {
  return (
    <Controller
      name={name}
      control={control}
      rules={registerOptions}
      defaultValue={``}
      render={({ field, formState: { isSubmitting }, fieldState: { error } }) => (
        <FormControl {...outerProps} isInvalid={!!error}>
          <FormLabelCustom label={label} name={name} props={labelProps} />

          <Input
            {...field}
            {...props}
            id={name}
            isDisabled={isSubmitting}
            onChange={(v) => {
              /*
               * Se o valor anterior do campo for uma chave pix CPF/CNPJ ou telefone
               * Então removemos todos os caracteres especiais para não buggar a mascara
               */
              return field.onChange(
                maskPixKey(
                  [`CPF`, `CNPJ`, `PHONE`].includes(unMaskPixKey(field.value).type)
                    ? onlyNumbers(v.target.value)
                    : v.target.value,
                ).masked,
              );
            }}
          />

          <FormErrorMessage>{error && error.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
}

export default PixKeyInput;
