// Chakra imports
import { Badge, Box, ButtonGroup, IconButton, SimpleGrid, Tooltip, VisuallyHidden } from '@chakra-ui/react';

import { useEffect, useMemo, useRef, useState } from 'react';
import { findAllInsurances } from 'services/api.service';
import { Column } from 'react-table';
import { DateTime } from 'luxon';
import TableComponent from 'components/TableComponent';
import CreateInsurrance from './components/CreateInsurance';
import { MdPrint } from 'react-icons/md';
import { queryClient } from 'index';
import { maskCurrency } from 'utils/number';
import PrintInsurances, { InsuranceData } from './components/PrintInsurances';
import { useReactToPrint } from 'react-to-print';
import CustomButton from 'components/CustomButton';

export enum InsurancesStatus {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  CANCELED = 'CANCELED',
}

export const InsuranceStatusRender: React.FC<{ status: InsurancesStatus }> = ({ status }) => {
  if (status === InsurancesStatus.PENDING) return <Badge colorScheme="orange">Pendente</Badge>;
  if (status === InsurancesStatus.CANCELED) return <Badge colorScheme="red">Cancelado</Badge>;

  if (status) return <Badge colorScheme="green">Confirmado</Badge>;
};

export default function Insurances() {
  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [insurance, setInsurance] = useState<InsuranceData | null>(null);
  const columns = useMemo(
    () =>
      [
        {
          Header: 'Seguro',
          accessor: 'model.name',
        },
        {
          Header: 'Cliente',
          accessor: 'customer.name',
        },
        {
          Header: 'Segurado',
          accessor: 'holder.name',
        },
        {
          Header: 'Data Início',
          accessor: 'startDate',
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat('dd/MM/yyyy');
          },
        },
        {
          Header: 'Data Fim',
          accessor: 'endDate',
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat('dd/MM/yyyy');
          },
        },
        {
          Header: 'Valor',
          accessor: 'price',
          Cell: ({ value }) => {
            return maskCurrency(value);
          },
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({ value }) => {
            return <InsuranceStatusRender status={value} />;
          },
        },

        {
          accessor: 'createdAt',
          Header: 'Data de Cadastro',
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat('dd/MM/yyyy HH:mm:ss');
          },
        },

        {
          Header: 'Ações',
          accessor: 'actions',
        },
      ] as Column[],
    [],
  );

  useEffect(() => {
    if (insurance) {
      handlePrint();
      setTimeout(() => {
        setInsurance(null);
      }, 1000);
    }
  }, [handlePrint, insurance]);

  const actions = (row: any) => (
    <ButtonGroup>
      {row.hash && row.status === 'CONFIRMED' && (
        <>
          <Tooltip label="Imprimir comprovante">
            <IconButton
              aria-label="Apertar"
              rounded="md"
              size="sm"
              variant="outline"
              icon={<MdPrint />}
              onClick={() => setInsurance(row)}
            />
          </Tooltip>
        </>
      )}
    </ButtonGroup>
  );

  // Chakra Color Mode
  return (
    <Box>
      <VisuallyHidden>
        <PrintInsurances data={insurance} ref={componentRef} />
      </VisuallyHidden>
      <SimpleGrid mb="20px" columns={{ sm: 1 }} spacing={{ base: '20px', xl: '20px' }}>
        <TableComponent
          label="Seguros"
          columnsData={columns}
          filterable={['companyId']}
          right={
            <>
              <ButtonGroup>
                <CreateInsurrance />

                <CustomButton
                  onClick={() =>
                    queryClient.refetchQueries({
                      queryKey: ['findAllIntegrations'],
                      type: 'active',
                    })
                  }
                >
                  Atualizar
                </CustomButton>
              </ButtonGroup>
            </>
          }
          queryFn={findAllInsurances}
          queryKey="findAllInsurances"
          actions={actions}
        />
      </SimpleGrid>
    </Box>
  );
}
