export const cep = (value: string) => {
  value = value.replace(/D/g, ''); //Remove tudo o que não é dígito
  value = value.replace(/^(\d{5})(\d)/, '$1-$2'); //Esse é tão fácil que não merece explicações
  return value;
};
export const soNumeros = (value: string) => {
  return value.replace(/\D/g, '');
};
export const telefone = (value: string) => {
  value = value.replace(/\D/g, ''); //Remove tudo o que não é dígito
  value = value.replace(/^(\d\d)(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
  value = value.replace(/(\d{4})(\d)/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
  return value;
};
export const cpf = (value: string) => {
  value = value.replace(/\D/g, ''); //Remove tudo o que não é dígito
  value = value.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
  value = value.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
  //de novo (para o segundo bloco de números)
  value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
  return value;
};

export const maskCnpj = (value: string) => {
  value = value.replace(/\D/g, ''); //Remove tudo o que não é dígito
  value = value.replace(/^(\d{2})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
  value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); //Coloca um ponto entre o terceiro e o quarto dígitos
  //de novo (para o segundo bloco de números)
  value = value.replace(/\.(\d{3})(\d)/, '.$1/$2'); //Coloca uma barra entre o terceiro e o quarto dígitos
  value = value.replace(/(\d{4})(\d)/, '$1-$2'); //Coloca um hífen depois do bloco de quatro dígitos
  return value;
};

export const maskDocument = (value: string) => {
  if (!value) {
    return '';
  }
  if (value.length <= 11) {
    return cpf(value);
  } else {
    return maskCnpj(value);
  }
};

export const mdata = (value: string) => {
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d)/, '$1/$2');
  value = value.replace(/(\d{2})(\d)/, '$1/$2');

  value = value.replace(/(\d{2})(\d{2})$/, '$1$2');
  return value;
};
export const mcc = (value: string) => {
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{4})(\d)/g, '$1 $2');
  value = value.replace(/^(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3');
  value = value.replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3 $4');
  return value;
};
