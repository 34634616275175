import {
  Card,
  HStack,
  Heading,
  IconButton,
  Kbd,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import CustomButton from 'components/CustomButton';
import { useTwoFaHelper } from 'contexts/TwoFaCheckContext';
import { useState } from 'react';
import { FaCopy, FaEye, FaEyeSlash, FaKey, FaLock } from 'react-icons/fa';
import { MdRefresh } from 'react-icons/md';
import { createCredentials, getCredentials } from 'services/api.service';
import { getVariable } from 'whitelabel';

const CredentialsAPI = () => {
  const toast = useToast();
  const [hidden, setHidden] = useState(true);
  const {
    isOpen: isOpenNewCredentials,
    onOpen: onOpenNewCredentials,
    onClose: onCloseNewCredentials,
  } = useDisclosure();

  const [data, setData] = useState(null);

  const { requestTwoFa } = useTwoFaHelper();

  const handleFindCredentials = async () => {
    const twofa = await requestTwoFa();

    if (!twofa?.id) {
      return false;
    }

    return getCredentials(twofa.id).then((info) => {
      setData(info);
      setHidden(false);
      toast({
        title: 'Credenciais encontradas com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      return info;
    });
  };
  const checkGenerateCredentials = async () => {
    const credentials = await handleFindCredentials();

    if (credentials === false) {
      return;
    }
    if (!credentials) {
      newCredentials();
    } else {
      onOpenNewCredentials();
    }
  };

  const newCredentials = () => {
    createCredentials().then((data) => {
      setData(data);
      onCloseNewCredentials();
      setHidden(false);
      toast({
        title: 'Credenciais geradas com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    });
  };

  const copy = (value: string) => {
    navigator.clipboard.writeText(value).then(() => {
      toast({
        title: 'Copiado com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    });
  };

  return (
    <Card p={4}>
      <Modal
        onClose={onCloseNewCredentials}
        isOpen={isOpenNewCredentials}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Gerar novas Credenciais</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Deseja realmente gerar novas credenciais? As credenciais atuais serão
              <Text
                as="span"
                fontWeight="bold"
              >
                {' '}
                permanentemente excluídas
              </Text>
              . Esta ação não pode ser desfeita.
            </Text>
          </ModalBody>
          <ModalFooter>
            <CustomButton
              colorScheme="brand"
              onClick={onCloseNewCredentials}
            >
              Cancelar
            </CustomButton>
            <CustomButton
              colorScheme="red"
              onClick={newCredentials}
            >
              Confirmar
            </CustomButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <HStack
        justify="space-between"
        mb={4}
      >
        <Heading fontSize="xl">Credenciais da API</Heading>
        <HStack spacing={2}>
          <CustomButton
            leftIcon={hidden ? <FaEye /> : <FaEyeSlash />}
            onClick={handleFindCredentials}
            variant="outline"
            colorScheme="brand"
            tooltip={hidden ? 'Exibir credenciais' : 'Ocultar credenciais'}
          >
            {hidden ? 'Exibir' : 'Ocultar'}
          </CustomButton>

          <CustomButton
            leftIcon={<MdRefresh />}
            onClick={checkGenerateCredentials}
            variant="outline"
            colorScheme="brand"
            tooltip="Gerar novas credenciais"
          >
            Gerar Credenciais
          </CustomButton>
        </HStack>
      </HStack>

      <Card
        variant="outline"
        p={4}
        mb={4}
      >
        <VStack
          align="stretch"
          spacing={4}
        >
          <HStack>
            <FaKey />
            <Text fontWeight="medium">Key:</Text>
            {hidden ? (
              <Text color="gray.500">••••••••••••••••</Text>
            ) : (
              <HStack>
                <Kbd fontSize="sm">{data?.clientId}</Kbd>
                <Tooltip label="Copiar Key">
                  <IconButton
                    size="sm"
                    onClick={() => copy(data?.clientId)}
                    rounded="sm"
                    variant="ghost"
                    colorScheme="brand"
                    aria-label="Copiar Key"
                    icon={<FaCopy />}
                  />
                </Tooltip>
              </HStack>
            )}
          </HStack>

          <HStack>
            <FaLock />
            <Text fontWeight="medium">Secret:</Text>
            {hidden ? (
              <Text color="gray.500">••••••••••••••••</Text>
            ) : (
              <HStack>
                <Kbd fontSize="sm">{data?.clientSecret}</Kbd>
                <Tooltip label="Copiar Secret">
                  <IconButton
                    size="sm"
                    onClick={() => copy(data?.clientSecret)}
                    rounded="sm"
                    variant="ghost"
                    colorScheme="brand"
                    aria-label="Copiar Secret"
                    icon={<FaCopy />}
                  />
                </Tooltip>
              </HStack>
            )}
          </HStack>
        </VStack>
      </Card>

      <Card
        variant="outline"
        p={4}
      >
        <Heading
          size="sm"
          mb={3}
        >
          Documentação
        </Heading>
        <Link
          href={getVariable('docs')}
          target="_blank"
          color="brand.500"
          display="inline-flex"
          alignItems="center"
          _hover={{ textDecoration: 'none', color: 'brand.600' }}
        >
          <Text>Acessar documentação {getVariable('name')}</Text>
        </Link>
      </Card>
    </Card>
  );
};

export default CredentialsAPI;
