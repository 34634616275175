import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Link,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import CustomButton from 'components/CustomButton';
import { useEffect, useRef, useState } from 'react';
import api from 'services/api.service';

export default function PixelConfigure() {
  const [pixelId, setPixelId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const toast = useToast();
  const [isValid, setIsValid] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  useEffect(() => {
    const fetchPixelId = async () => {
      const response = await api.get('customers/tracking?type=PIXEL');

      setPixelId(response.data?.value);
    };
    fetchPixelId();
  }, []);

  // Validação do ID do Pixel
  const validatePixelId = (value: string) => {
    const pixelRegex = /^\d{15,16}$/;
    return pixelRegex.test(value);
  };

  const handlePixelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPixelId(value);
    setIsValid(value === '' || validatePixelId(value));
  };

  const handleSubmit = async () => {
    if (!validatePixelId(pixelId)) {
      toast({
        title: 'ID do Pixel inválido',
        description: 'O ID do Pixel deve conter 15 ou 16 dígitos.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);

    try {
      await api.post('customers/tracking', {
        type: 'PIXEL',
        value: pixelId,
      });

      toast({
        title: 'Pixel configurado com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erro ao configurar o pixel',
        description: 'Por favor, tente novamente.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true);

    try {
      await api.delete('customers/tracking/PIXEL');

      toast({
        title: 'Pixel removido com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setPixelId('');
    } catch (error) {
      toast({
        title: 'Erro ao remover o pixel',
        description: 'Por favor, tente novamente.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Box mt={4}>
      <HStack
        justify="space-between"
        mb={4}
      >
        <Text
          fontSize="lg"
          fontWeight="medium"
        >
          Configurar Pixel do Facebook
        </Text>
        {pixelId && (
          <Badge
            colorScheme="green"
            variant="subtle"
          >
            Configurado
          </Badge>
        )}
      </HStack>

      <VStack
        spacing={4}
        align="stretch"
      >
        <Text
          fontSize="sm"
          color="gray.500"
        >
          O pixel é uma ferramenta de rastreamento que permite acompanhar o comportamento dos usuários no checkout.
          <Link
            href="https://www.facebook.com/business/help/952192354843755"
            isExternal
            ml={1}
            color="blue.500"
          >
            Saiba mais <ExternalLinkIcon mx="2px" />
          </Link>
        </Text>

        <FormControl isInvalid={!isValid}>
          <FormLabel>ID do Pixel</FormLabel>
          <Input
            type="text"
            isDisabled={isLoading || isDeleting}
            size="sm"
            value={pixelId}
            onChange={handlePixelChange}
            placeholder="Digite o ID do seu pixel (15-16 dígitos)"
          />
        </FormControl>

        <HStack spacing={2}>
          <CustomButton
            colorScheme="blue"
            isLoading={isLoading}
            onClick={handleSubmit}
            isDisabled={!isValid}
          >
            Salvar
          </CustomButton>
          <CustomButton
            colorScheme="red"
            variant="outline"
            isLoading={isDeleting}
            onClick={onOpen}
            isDisabled={!pixelId}
          >
            Remover
          </CustomButton>
        </HStack>
      </VStack>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Remover Pixel</AlertDialogHeader>
            <AlertDialogBody>
              Tem certeza que deseja remover o Pixel? Esta ação não poderá ser desfeita.
            </AlertDialogBody>
            <AlertDialogFooter>
              <CustomButton onClick={onClose}>Cancelar</CustomButton>
              <CustomButton
                colorScheme="red"
                onClick={() => {
                  onClose();
                  handleDelete();
                }}
              >
                Remover
              </CustomButton>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
