import { ICardPaymentBrickPayer, ICardPaymentFormData } from '@mercadopago/sdk-react/bricks/cardPayment/type';
import axios from 'axios';
import { AUTHSTORAGE_KEY } from 'contexts/useAuth';
import { FormCardCreditCard } from 'layouts/charge/PagSeguroCard';
import { ChargePublic } from 'layouts/charge/types/ChargeTypes';
import { Charge, InstantQrCode } from 'types/Charge';
import { ConsultCep } from 'types/ConsultCep';
import { ConsultCnpj } from 'types/ConsultCnpj';
import { DashboardResponse } from 'types/dashboard';
import { Movement } from 'types/Movement';
import { CreateHolderForm } from 'views/access/insurances/components/CreateHolder';
import { Withdrawal } from 'views/access/withdrawals';
import { ShowQrCode } from 'views/access/withdrawals/types/QRcode/qrcode.type';
import { CardList } from './types/cards.type';
import { CustomerInfo } from './types/customer';
import { InstallmentsConfig } from './types/installmensts.config';
import { Onboarding } from './types/onboarding.type';
import { RecurrenceResponse } from './types/Recurrence.entity';
import { RecurrencyLink, RecurrencyLinkItemGet } from './types/recurrency-link';
import { RecurrencyClientPayments } from './types/RecurrencyPayments.entity';

export interface RechargeFormCardCreditCard {
  cardNumber: string;
  cardExpiration: string;
  cardCvv: string;
  cardHolderName: string;
  cardHolderCpf: string;
  installments: number;
  amount: number;
  pagSeguro3dsId?: string;
  method: 'CREDIT_CARD' | 'DEBIT_CARD';
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('Authorization'),
  },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401 && !error.config.baseURL.includes('/customer')) {
      localStorage.removeItem(AUTHSTORAGE_KEY);
      window.location.href = '/#/auth';
    }
    return Promise.reject(error);
  },
);

interface Orders {
  id: number;
  customerName: string;
  providerName: string;
  status: string;
  serviceType: string;
  amount: number;
  on: Date;
}

export const validateUserCode = async (code: string) => {
  return api.post<{ id: string }>('customers/validateCode', { code }).then((res) => res.data);
};

export const registerDevice = async (deviceId: string) => {
  return api.post('customers/device', { deviceId }).then((res) => res.data);
};

export const loginCustomerService = async (email: string, password: string, captchaKey: string, token: string) => {
  return api.post('/auth/customer', { email, password, captchaKey, twoFa: token }).then((res) => res.data);
};

export const deleteWithdrawal = (id: string) => {
  return api.delete(`/withdrawals/${id}`);
};

export const updateCustomerService = () => {
  return api.get('/auth/update').then((res) => res.data);
};

export const getAllowCardRecharge = async () => {
  return api.get<boolean>('/charges/allow-card-recharge').then((res) => res.data);
};

export const findClientById = async (id: string) => {
  return api
    .get<{
      id: string;
      name: string;
      document: string;
      email: string;
      phone: string;
    }>(`/clients/${id}`)
    .then((res) => res.data);
};

export const createClient = async (data: { email: string; phone: string; name: string; document: string }) => {
  return api.post(`clients`, data).then((res) => res.data);
};

export const editClient = async (
  id: string,
  data: {
    email: string;
    phone: string;
    name: string;
    document: string;
  },
) => {
  return api.put(`clients/${id}`, data).then((res) => res.data);
};

export const realizarLevantamentoApi = async (data: { recurrencyId: string; amount: number }) => {
  return api.post(`recurrences/${data.recurrencyId}/withdraw`, data).then((res) => res.data);
};

export const createRecurrency = async (data: { amount: string; description: string }) => {
  return api.post(`recurrences`, data).then((res) => res.data);
};

export const findClientPayments = async (id: string) => {
  return api.get<RecurrencyClientPayments>(`recurrences/payments/${id}`).then((res) => res.data);
};

export const removeClientFromRecurrence = async (id: string) => {
  return api.delete(`recurrences/clients/${id}`).then((res) => res.data);
};

export const createNewRecurrencePaymentRequest = async ({
  id,
  withoutCharge,
}: {
  id: string;
  withoutCharge: boolean;
}) => {
  return api.post(`recurrences/payments/${id}/charge`, { withoutCharge }).then((res) => res.data);
};
export const sendMailRecurrencePaymentRequest = async (id: string) => {
  return api.post(`recurrences/generated/${id}/mail`).then((res) => res.data);
};
export const doneRecurrencePayment = async (id: string) => {
  return api.put(`recurrences/generated/${id}/done`).then((res) => res.data);
};

export const cancelRecurrencePayment = async (id: string) => {
  return api.delete(`recurrences/generated/${id}`).then((res) => res.data);
};

export const newChargeForGeneratedRecurrence = async (id: string) => {
  return api.put(`recurrences/generated/${id}`).then((res) => res.data);
};

export const linkWalletToRecurrencyApi = async ({ id, walletId }: { id: string; walletId: string }) => {
  return api.put(`recurrences/${id}/wallet/${walletId}`).then((res) => res.data);
};

export const findAllWallets = async (filter: { limit: number; page: number; filter: string }) => {
  return api
    .get(`wallets`, {
      params: filter,
    })
    .then((res) => res.data);
};

export const addClientToRecurrence = async (
  recurrenceId: string,
  data: { clientId: string; initDate: string; period: string; endDate: string },
) => {
  return api.post(`recurrences/${recurrenceId}/add`, data).then((res) => res.data);
};

export const updateUser = (
  id: string,
  data: {
    email: string;
    password: string;
    name: string;
    companyId: string;
  },
) => {
  return api.put(`users/${id}`, data).then((res) => res.data);
};

export const deleteCustomerTaxes = (customerId: string, taxId: string) => {
  return api.delete(`customers/${customerId}/taxes/${taxId}`).then((res) => res.data);
};

export const getMovement = (id: string) => {
  return api.get<Movement>(`movements/${id}`).then((res) => res.data);
};

export const getRecurrence = async (id: string) => {
  return api.get<RecurrenceResponse>(`recurrences/${id}`).then((res) => res.data);
};

export const deleteTax = (id: string) => {
  return api.delete(`taxes/${id}`).then((res) => res.data);
};
export const ativeUserService = (id: string, ative: boolean) => {
  return api.put(`users/${id}/ative`, { ative }).then((res) => res.data);
};

export interface Customer {
  id: string;
  name: string;
  document_number: string;
  email: string;
  status: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface CustomerListResponse {
  pages: number;
  registers: Customer[];
}

export const findAllParticipants = ({
  limit = 20,
  filter = '',
  page = 0,
}: {
  limit?: number;
  page?: number;
  filter?: string;
}) => {
  return api
    .get<CustomerListResponse>('withdrawals/participants', {
      params: {
        limit,
        page,
        filter,
      },
    })
    .then((res) => res.data);
};

export const createWithdrawal = (authorizationId: string, data: any) => {
  return api
    .post<any>('withdrawals', data, {
      headers: {
        'x-security-code': authorizationId,
      },
    })
    .then((res) => res.data);
};

export const createWithdrawalQRCode = async (
  authorizationId: string,
  data: {
    amount: number;
    description: string;
    qrcode: string;
  },
) => {
  return api
    .post<any>('withdrawals/qrcode', data, {
      headers: {
        'x-security-code': authorizationId,
      },
    })
    .then((res) => res.data);
};
export const createWithdrawalAntecipate = (data: any) => {
  return api.post<any>('withdrawals/antecipate', data).then((res) => res.data);
};

export const createTransfer = (authorizationId: string, data: any) => {
  return api
    .post<any>('withdrawals/transfer', data, {
      headers: {
        'x-security-code': authorizationId,
      },
    })
    .then((res) => res.data);
};

export const createCharge = async (data: { amount: number; description: string; document: string; name: string }) => {
  return api.post<any>('charges', data).then((res) => res.data);
};

export const validateQRCode = async (qrcode: string) => {
  return api.post<ShowQrCode>('withdrawals/validate', { qrcode }).then((res) => res.data);
};
export const validateBillet = async (billet: string) => {
  return api.post<ShowQrCode>('withdrawals/validate-billet', { billet }).then((res) => res.data);
};

export const transferAmountAnotherCustomer = (customerId: string, data: any) => {
  return api.post<any>(`customers/${customerId}/transfer`, data).then((res) => res.data);
};

export const findAllClientsFilter = ({ filter, limit }: { limit: number; filter: string }) => {
  return api
    .get('customers/simple', {
      params: {
        limit,
        filter,
      },
    })
    .then((res) => res.data);
};

export const findCnpj = (cnpj: string) => {
  return api.get<ConsultCnpj>(`customers/cnpj/${cnpj}`).then((res) => res.data);
};

export const findCep = (cep: string) => {
  return api.get<ConsultCep>(`ceps/${cep}`).then((res) => res.data);
};

export const changePasswordCustomer = (customerId: string, password: string, currentPassword: string) => {
  return api
    .put(`customers/${customerId}/changePassword`, {
      password,
      currentPassword,
    })
    .then((res) => res.data);
};

export const findAllTerminals = (filters: {
  limit: number;
  page: number;
  filter: string;
  orderBy: { id: string; desc: boolean }[];
}) => {
  return api
    .get<{ total: number; registers: any[] }>('maquinetas', {
      params: filters,
    })
    .then((res) => res.data);
};

export const findAllWithdrawers = ({ limit, page, filter }: { limit: number; page: number; filter: string }) => {
  return api
    .get<{ registers: Withdrawal[] }>('withdrawals', {
      params: {
        limit,
        filter,
        page,
      },
    })
    .then((res) => res.data);
};

export const updateWithdrawel = (
  id: string,
  data: {
    amount: number;
  },
) => {
  return api.put<Withdrawal>(`withdrawals/${id}`, data).then((res) => res.data);
};

export const findWithdrawel = (id: string) => {
  return api.get<Withdrawal>(`withdrawals/${id}`).then((res) => res.data);
};

export const findAllMovements = (
  filter: {
    limit: number;
    customerId: string;
    page: number;
    maquinetaId?: string;
    filter: string;
  },
  exportPdf = false,
) => {
  return api
    .post<Orders>(
      'movements/all',
      {
        ...filter,
        exportPdf,
      },
      {
        responseType: exportPdf ? 'blob' : 'json',
      },
    )
    .then((res) => res.data);
};

export const findAllClients = (
  filter: {
    limit: number;
    page: number;
    maquinetaId?: string;
    filter: string;
  },
  exportPdf = false,
) => {
  return api
    .post(
      'clients/all',
      {
        ...filter,
        exportPdf,
      },
      {
        responseType: exportPdf ? 'blob' : 'json',
      },
    )
    .then((res) => res.data);
};

export const findAllRecurrences = async (
  filter: {
    limit: number;
    page: number;
    filter: string;
  },
  exportPdf = false,
) => {
  return api
    .post(
      'recurrences/all',
      {
        ...filter,
        exportPdf,
      },
      {
        responseType: exportPdf ? 'blob' : 'json',
      },
    )
    .then((res) => res.data);
};

export const findAllRecurrencesClients = async (filter: {
  limit: number;
  customerId: string;
  page: number;
  maquinetaId?: string;
  filter: string;
}) => {
  return api
    .get('recurrences/clients', {
      params: {
        ...filter,
      },
    })
    .then((res) => res.data);
};

export const findAllBalances = (params: { limit: number; customerId?: string; page: number; filter: string }) => {
  return api
    .get<{
      pages: number;
      registers: any[];
    }>('balances', {
      params,
    })
    .then((res) => res.data);
};

export const findAllBalancesPending = (params: {
  limit: number;
  customerId?: string;
  page: number;
  filter: string;
}) => {
  return api
    .get<{
      pages: number;
      registers: any[];
    }>('balances-pending', {
      params,
    })
    .then((res) => res.data);
};

export const dashboardCustomer = (filter: any) => {
  return api.get<DashboardResponse>(`dashboard/customer`, { params: filter }).then((res) => res.data);
};

export const deleteCustomerAccount = (id: string) => {
  return api.delete(`accounts/${id}`).then((res) => res.data);
};

export const deleteCustomer = async (authorizationId: string) => {
  return api
    .delete(`customers`, {
      headers: {
        'x-security-code': authorizationId,
      },
    })
    .then((res) => res.data);
};
export const accountFeatures = async () => {
  return api
    .get<{
      twoFaEnabled: boolean;
      masterPasswordEnabled: boolean;
      notificationsEnabled: boolean;
      emailsEnabled: boolean;
    }>(`customers/features`)
    .then((res) => res.data);
};

export const changeFeatureNotification = async (type: 'push' | 'email') => {
  return api.post(`customers/features`, { type }).then((res) => res.data);
};

export const setMasterPassword = async ({
  masterPassword,
  previousMasterPassword,
}: {
  masterPassword: string;
  previousMasterPassword?: string;
}) => {
  return api
    .post(`customers/master-password`, {
      masterPassword,
      previousMasterPassword,
    })
    .then((res) => res.data);
};

export const getCredentials = async (authorizationId: string) => {
  return api
    .get<{
      clientId: string;
      clientSecret: string;
    }>(`customers/credentials`, {
      headers: {
        'x-security-code': authorizationId,
      },
    })
    .then((res) => res.data);
};

export const createCredentials = () => {
  return api.post(`customers/credentials`).then((res) => res.data);
};

export const getAccounts = ({ page = 0, limit = 10, ...params }: any) => {
  return api.get(`accounts`, { params: { ...params, page, limit } }).then((res) => res.data);
};

export const getAntecipationValue = () => {
  return api.get<{ amount: number; amount_total: number }>(`balances-pending/antecipation`).then((res) => res.data);
};

export const getCharge = async (id: string) => {
  return api.get<Charge>(`charges/${id}`).then((res) => res.data);
};

export const findAllCharges = (
  filter: {
    limit: number;
    customerId: string;
    page: number;
    maquinetaId?: string;
    filter: string;
  },
  exportPdf = false,
) => {
  return api
    .post<Orders>(
      'charges/all',
      { ...filter, exportPdf },
      {
        responseType: exportPdf ? 'blob' : 'json',
      },
    )
    .then((res) => res.data);
};

export const getChargeFromPublic = async (id: string) => {
  return api.get<ChargePublic>(`public/charges/${id}`).then((res) => res.data);
};

export const registerBilletForCharge = (id: string, recaptchaToken: string) => {
  return api.put<Charge>(`public/charges/${id}/register-billet`, { recaptchaToken }).then((res) => res.data);
};

export const generatePaymentsForCharge = async (id: string, type: 'pix' | 'billet' | 'instant') => {
  return api.post<Charge>(`charges/${id}/${type}`).then((res) => res.data);
};

export const generateInstantCharge = async (data: { amount: number; expiration: number }) => {
  return api.post<InstantQrCode>(`charges/instant`, data).then((res) => res.data);
};

export const processPaymentMercadoPago = (
  id: string,
  data: ICardPaymentFormData<ICardPaymentBrickPayer> & {
    recaptchaToken: string;
    description?: string;
    email: string;
    document: string;
    name: string;
  },
) => {
  return api.post<Charge>(`public/charges/${id}/mercado-pago`, data).then((res) => res.data);
};

export const payWithCreditCard = async (id: string, data: FormCardCreditCard & { recaptchaToken: string }) => {
  return api.post<Charge>(`public/charges/${id}/credit-card`, data).then((res) => res.data);
};

export const payWithCreditCardRecharge = async (data: RechargeFormCardCreditCard & { recaptchaToken: string }) => {
  return api.post<Charge>(`charges/credit-card`, data).then((res) => res.data);
};

export const infoAccount = async () => {
  return api.get<CustomerInfo>(`customers/info`).then((res) => res.data);
};

export const findInstallmentsConfig = async () => {
  return api.get<InstallmentsConfig>(`charges/installmentsConfigRecharge`).then((res) => res.data);
};

export const findInstallmentsConfigCustom = async (paymentMethod: string) => {
  return api
    .get<InstallmentsConfig>(`charges/installmentsConfig?paymentMethod=${paymentMethod}`)
    .then((res) => res.data);
};

export const initializePaymentMercadoPago = (id: string) => {
  return api
    .get<{
      publicKey: string;
    }>(`public/charges/${id}/mercado-pago`)
    .then((res) => res.data);
};

export const estornarPix = (chargeId: string) => {
  return api.put(`charges/${chargeId}/pix/refund`).then((res) => res.data);
};
export const request2FaData = () => {
  return api.get<{ otpauth_url: string; base64: string }>('customers/twoFa').then((res) => res.data);
};

export const active2FaData = (token) => {
  return api.post('customers/twoFa', { token }).then((res) => res.data);
};

export const createInsurance = async (data: {
  holderId: string;
  startDate: string;
  modelId: string;
  endDate: string;
}) => {
  return api.post(`insurances`, data).then((res) => res.data);
};

export const createHolder = async (data: CreateHolderForm) => {
  return api.post(`insurances/holders`, data).then((res) => res.data);
};

export const findAllHolders = ({
  limit = 20,
  filter = '',
  page = 0,
}: {
  limit?: number;
  page?: number;
  filter?: string;
}) => {
  return api
    .get<CustomerListResponse>('insurances/holders', {
      params: {
        limit,
        page,
        filter,
      },
    })
    .then((res) => res.data);
};

export const findAllInsurancesModels = ({
  limit = 20,
  filter = '',
  page = 0,
}: {
  limit?: number;
  page?: number;
  filter?: string;
}) => {
  return api
    .get<CustomerListResponse>('insurances/models', {
      params: {
        limit,
        page,
        filter,
      },
    })
    .then((res) => res.data);
};

export const findAllInsurances = ({
  limit,
  page,
  companyId,
  filter,
}: {
  limit: number;
  companyId: string;
  page: number;
  filter: string;
}) => {
  return api
    .get<Orders>('insurances', {
      params: { filter, limit, page, companyId },
    })
    .then((res) => res.data);
};
export const cancelInsurance = async ({ id }: { id: string }) => {
  return api.delete(`insurances/${id}`).then((res) => res.data);
};

export const findCards = async ({ limit = 10, page = 0 }: { limit: number; page: number } | undefined) => {
  return api.post<CardList>(`cards/all`, { limit, page }).then((res) => res.data);
};
export const findAllMovementsCard = ({
  limit = 20,
  filter = '',
  page = 0,
  companyId,
  customerId,
  cardId,
}: {
  limit?: number;
  page?: number;
  filter?: string;
  companyId?: string;
  customerId?: string;
  cardId: string;
}) => {
  return api
    .get<any>(`cards/${cardId}/movements`, {
      params: {
        limit: +limit,
        page: +page,
        companyId,
        customerId,
        filter,
      },
    })
    .then((res) => res.data);
};

export const startOnboarding = async (data: { captchaToken: string; type: 'PF' | 'PJ' }) => {
  return api
    .post<{
      id: string;
    }>('onboarding-customer', data)
    .then((res) => res.data);
};

export type NextOnboarding = {
  step: 'credentials' | 'company' | 'personal' | 'address' | 'documents' | 'selfie' | 'complete';
  [key: string]: any;
};

export const nextOnboarding = async (id: string, data: NextOnboarding) => {
  return api
    .put<{
      id: string;
    }>(`onboarding-customer/${id}`, data)
    .then((res) => res.data);
};

export const findOnboarding = async (id: string) => {
  return api.get<Onboarding>(`onboarding-customer/${id}`).then((res) => res.data);
};

export const findAllRecurrencyLinks = async (filter: { limit: number; page: number; filter: string }) => {
  return api.post<RecurrencyLink>(`recurrency-links/all`, filter).then((res) => res.data);
};

export const createRecurrencyLink = async (data: {
  title: string;
  description: string;
  image: string;
  itens: { planId: string }[];
}) => {
  return api.post<RecurrencyLink>(`recurrency-links`, data).then((res) => res.data);
};

export const updateRecurrencyLink = async (id: string, data: { title: string; description: string }) => {
  return api.put<RecurrencyLink>(`recurrency-links/${id}`, data).then((res) => res.data);
};

export const findRecurrencyLink = async (id: string) => {
  return api.get<RecurrencyLinkItemGet>(`recurrency-links/${id}`).then((res) => res.data);
};

export const findRecurrencyLinkPublic = async (id: string) => {
  return api.get<RecurrencyLinkItemGet>(`recurrency-links/public/${id}`).then((res) => res.data);
};

export const addPlanToRecurrencyLink = async (id: string, data: { planId: string }) => {
  return api.post<RecurrencyLink>(`recurrency-links/${id}/item`, data).then((res) => res.data);
};

export const removePlanFromRecurrencyLink = async (id: string, data: { planId: string }) => {
  return api.delete<RecurrencyLink>(`recurrency-links/${id}/item/${data.planId}`).then((res) => res.data);
};

export const updateRecurrency = (id: string, data: any) => {
  return api.put(`/recurrences/${id}`, data);
};

export const generateRecurrencyLink = async (id: string, data: { name: string; email: string; document: string }) => {
  return api
    .post<{
      link: string;
    }>(`recurrency-links/${id}/generate`, data)
    .then((res) => res.data);
};

export default api;
