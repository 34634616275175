import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import CustomButton from 'components/CustomButton';
import InputForm from 'components/InputForm';
import { queryClient } from 'index';
import { useForm } from 'react-hook-form';
import { FaEdit } from 'react-icons/fa';
import { findRecurrencyLink, updateRecurrencyLink } from 'services/api.service';

interface EditLinkDetailsProps {
  linkId: string;
}

type FormData = {
  title: string;
  description: string;
  image: string;
};

const EditLinkDetails: React.FC<EditLinkDetailsProps> = ({ linkId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { control, handleSubmit, reset } = useForm<FormData>();

  const { data: link } = useQuery({
    queryKey: ['findRecurrencyLink', linkId],
    queryFn: () => findRecurrencyLink(linkId),
    enabled: isOpen,
    onSuccess: (data) => {
      reset({
        title: data.title,
        description: data.description || '',
        image: data.image || '',
      });
    },
  });

  const handleEditDetails = async (data: FormData) => {
    try {
      await updateRecurrencyLink(linkId, data);
      queryClient.invalidateQueries(['findRecurrencyLink', linkId]);
      toast({
        title: 'Sucesso',
        description: 'Detalhes atualizados com sucesso',
        status: 'success',
      });
      onClose();
    } catch (error: any) {
      toast({
        title: 'Erro',
        description: error?.response?.data?.message || 'Erro ao atualizar detalhes',
        status: 'error',
      });
    }
  };

  return (
    <>
      <Tooltip label="Editar detalhes">
        <IconButton
          icon={<FaEdit />}
          aria-label="Editar detalhes"
          size="sm"
          colorScheme="blue"
          onClick={onOpen}
        />
      </Tooltip>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar Detalhes do Link</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack spacing={4}>
              <InputForm
                type="text"
                control={control}
                name="title"
                label="Título *"
                rules={{
                  required: 'Campo obrigatório',
                  maxLength: {
                    value: 200,
                    message: 'Máximo de 200 caracteres',
                  },
                }}
              />

              <InputForm
                control={control}
                name="description"
                label="Descrição"
                type="text"
              />

              <InputForm
                control={control}
                name="image"
                label="URL da Imagem"
                type="text"
              />

              <CustomButton
                colorScheme="green"
                w="100%"
                onClick={handleSubmit(handleEditDetails)}
              >
                Salvar
              </CustomButton>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditLinkDetails;
