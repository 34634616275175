import { Button, Tooltip } from '@chakra-ui/react';
import React, { JSXElementConstructor, ReactElement } from 'react';

const CustomButton: React.FC<{
  onClick: () => void;
  children: JSX.Element | string | React.ReactNode;
  leftIcon?: ReactElement<any, string | JSXElementConstructor<any>>;
  colorScheme?: string;
  tooltip?: string;
  isDisabled?: boolean;
  variant?: 'outline' | 'solid' | 'ghost' | 'link' | 'unstyled';
  isLoading?: boolean;
  w?: string;
}> = ({ variant = 'outline', children, colorScheme = 'brand', ...props }) => {
  return (
    <>
      {props.tooltip ? (
        <Tooltip label={props.tooltip}>
          <Button
            {...props}
            size="sm"
            rounded={'md'}
            variant={variant}
            isLoading={props.isLoading}
            colorScheme={colorScheme}
            width={props.w ?? { base: '100%', md: 'auto' }}
          >
            {children}
          </Button>
        </Tooltip>
      ) : (
        <Button
          {...props}
          size="sm"
          rounded={'md'}
          variant={variant}
          isLoading={props.isLoading}
          colorScheme={colorScheme}
          width={props.w ?? { base: '100%', md: 'auto' }}
        >
          {children}
        </Button>
      )}
    </>
  );
};

export default CustomButton;
