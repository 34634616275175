// Chakra imports
import { Badge, Box, Flex, SimpleGrid } from '@chakra-ui/react';

import { useMemo } from 'react';
import { findAllClients } from 'services/api.service';
import { Column } from 'react-table';
import { DateTime } from 'luxon';
import TableComponent from 'components/TableComponent';
import CreateClient from './components/CreateClient';
import EditClient from './components/EditClient';

export default function Clients() {
  const columns = useMemo(
    () =>
      [
        {
          Header: 'Nome',
          accessor: 'name',
        },
        {
          Header: 'CPF',
          accessor: 'document',
        },
        {
          Header: 'Email',
          accessor: 'email',
        },
        {
          Header: 'Telefone',
          accessor: 'phone',
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({ value }: { value: string }) => {
            return value === 'ACTIVE' ? (
              <Badge colorScheme="green">Ativo</Badge>
            ) : (
              <Badge colorScheme="red">Inativo</Badge>
            );
          },
        },

        {
          accessor: 'createdAt',
          Header: 'Data',
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat('dd/MM/yyyy HH:mm:ss');
          },
        },
        {
          Header: 'Ações',
          accessor: 'actions',
        },
      ] as Column[],
    [],
  );

  const actions = (row: any) => (
    <Flex align="center" justify="flex-start" w="100%">
      <EditClient clientId={row.id} />
    </Flex>
  );

  // Chakra Color Mode
  return (
    <Box>
      <SimpleGrid mb="20px" columns={{ sm: 1 }} spacing={{ base: '20px', xl: '20px' }}>
        <TableComponent
          label="Clientes Cadastrados"
          columnsData={columns}
          right={<CreateClient />}
          queryFn={findAllClients}
          queryKey="findAllClients"
          actions={actions}
        />
      </SimpleGrid>
    </Box>
  );
}
