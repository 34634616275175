import { Box, Divider, Heading, Stack } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { InstallmentCalculatorModal } from 'components/InstallmentCalculatorModal';
import useAuth from 'contexts/useAuth';
import AccountSettings from './components/AccountSettings';
import CredentialsAPI from './components/CredentialsAPI';
import PixelConfigure from './components/PixelConfigure';

export default function Settings() {
  const { userData } = useAuth();

  return (
    <Box
      maxW="1200px"
      mx="auto"
    >
      <Card
        flexDirection="column"
       
        shadow="lg"
        borderRadius="xl"
      >
        <Box mb={8}>
          <Heading
            size="md"
            mb={2}
          >
            Olá, {userData?.name}
          </Heading>
        </Box>

        <Stack spacing={8}>
          <Box>
            <Heading
              size="md"
              mb={4}
            >
              Calculadora de Parcelas
            </Heading>
            <InstallmentCalculatorModal />
          </Box>

          <Divider />

          <AccountSettings />

          <Divider />

          <CredentialsAPI />

          <Divider />

          <Box>
            <Heading
              size="md"
              mb={4}
            >
              Configuração de Pixel
            </Heading>
            <PixelConfigure />
          </Box>
        </Stack>
      </Card>
    </Box>
  );
}
