export const maskCurrency = (value: number | string) => {
  if (!value) return 'R$ 0,00';

  if (typeof value === 'string') value = Number(value);
  return value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
};

export const onlyNumbers = (value: string) => {
  return value?.replace(/\D/g, '');
};
