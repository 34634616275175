// Chakra imports
import { Portal, Box, useDisclosure } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import { useState } from 'react';
import { matchPath, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import routes from 'routes';

// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
  const { ...rest } = props;
  const loc = useLocation();
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== '/access/full-screen-maps';
  };
  const getActiveRoute = (routes: RoutesType[]) => {
    let activeRoute = 'Dashboard';
    let link = '/access/dashboard';

    const findedRoute = routes.find((item) => {
      if (item.children) {
        return item.children.find((child) => {
          if (matchPath(loc.pathname, { path: child.layout + child.path })) {
            return child;
          }
          return null;
        });
      } else {
        if (matchPath(loc.pathname, { path: item.layout + item.path })) {
          return item;
        }
        return null;
      }
    });

    if (findedRoute) {
      if (findedRoute.children) {
        const findedChild = findedRoute.children.find((child) => {
          if (matchPath(loc.pathname, { path: child.layout + child.path })) {
            return child;
          }
          return null;
        });

        return {
          link: findedChild.path,
          name: findedChild.name,
          namePrevious: findedRoute.name,
        };
      }

      return {
        link: findedRoute.pathPrevious || findedRoute.path,
        name: findedRoute.name,
        namePrevious: findedRoute.namePrevious ?? findedRoute.name,
      };
    }

    return { link, name: activeRoute, namePrevious: activeRoute };
  };
  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
    const findedRoute = routes.find((item) => {
      if (item.children) {
        return item.children.find((child) => {
          if (window.location.href.indexOf(child.layout + child.path) !== -1) {
            return child;
          }
          return null;
        });
      }
      if (window.location.href.indexOf(item.layout + item.path) !== -1) {
        return item;
      }
      return null;
    });

    return findedRoute?.name;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route: RoutesType, key: any) => {
      if (route.layout === '/access') {
        if (route.children) {
          return route.children.map((prop: any, key: any) => {
            return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
          });
        }

        return <Route path={route.layout + route.path} component={route.component} key={key} />;
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();
  const { link, name, namePrevious } = getActiveRoute(routes);

  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar routes={routes} display="none" {...rest} closeSidebar={() => {}} />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          py={'50px'}
          w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
          maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                logoText={'Links'}
                brandText={name}
                brandLink={link}
                namePrevious={namePrevious}
                secondary={getActiveNavbar(routes)}
                message={getActiveNavbarText(routes)}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          {getRoute() ? (
            <Box mx="auto" p={{ base: '20px', md: '30px' }} pe="20px" minH="100vh" pt="40px">
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/" to="/access/dashboard" />
              </Switch>
            </Box>
          ) : null}
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
