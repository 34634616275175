import ReactGA from 'react-ga4';

const useAnalyticsEventTracker = (category = 'Acesso') => {
  const eventTracker = (action = 'Acesso', label = 'Connect') => {
    if (localStorage.getItem('cookieConsent') === 'true') {
      ReactGA.event({ category, action, label });
    }
  };
  return eventTracker;
};
export default useAnalyticsEventTracker;
