import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import CookieConsent from 'components/CookieConsent';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { findRecurrencyLinkPublic } from 'services/api.service';
import usePixel from 'usePixel';
import { maskDocument } from 'utils/document';
import { maskCurrency } from 'utils/number';
import { RecurrencyPeriodDescription } from 'views/access/recurrences/components/recurrency-type';
import { getVariable } from 'whitelabel';
import { PurchaseModal } from './components/purchase-modal';

// Custom Chakra theme
export default function LinkPage() {
  const { id } = useParams<{ id: string }>();

  const { data: link, isLoading } = useQuery({
    queryKey: ['findRecurrencyLink', id],
    queryFn: () => findRecurrencyLinkPublic(id),
    enabled: !!id,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPlanId, setSelectedPlanId] = useState<string>('');
  const pixel = usePixel();
  const [hasConsent, setHasConsent] = useState(false);
  const consented = (value: boolean) => {
    setHasConsent(value);
  };

  useEffect(() => {
    if (hasConsent && link?.pixelId) {
      pixel.configure(link.pixelId);
    }
  }, [link?.pixelId, hasConsent]);

  useEffect(() => {
    document.title = getVariable('name') + ' - ' + link?.title;
    let linkQuery = document.querySelector("link[rel~='icon']");
    if (!linkQuery) {
      linkQuery = document.createElement('link');
      linkQuery['rel'] = 'icon';
      document.getElementsByTagName('head')[0].appendChild(linkQuery);
    }
    linkQuery['href'] = getVariable('favicon');
  }, [link?.title]);

  const handleSelectPlan = (planId: string) => {
    pixel.eventTracker('link_click', {
      planId,
    });

    setSelectedPlanId(planId);
    onOpen();
  };

  if (isLoading) {
    return (
      <Container
        maxW="container.lg"
        py={10}
      >
        <VStack spacing={8}>
          <Skeleton
            height="200px"
            width="100%"
          />
          <Skeleton
            height="40px"
            width="60%"
          />
          <Skeleton
            height="20px"
            width="80%"
          />
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3 }}
            spacing={6}
            width="100%"
          >
            {[1, 2, 3].map((i) => (
              <Skeleton
                key={i}
                height="300px"
              />
            ))}
          </SimpleGrid>
        </VStack>
      </Container>
    );
  }

  if (!link) {
    return (
      <Container
        maxW="container.lg"
        py={10}
      >
        <VStack spacing={4}>
          <Heading size="lg">Link não encontrado</Heading>
          <Text>O link que você está procurando não existe ou foi removido.</Text>
        </VStack>
      </Container>
    );
  }

  return (
    <Box
      minH="100vh"
      bg="gray.50"
    >
      <Container
        maxW="container.lg"
        py={10}
      >
        <VStack
          spacing={8}
          align="stretch"
        >
          {/* Header Section */}
          <Flex
            direction={{ base: 'column', md: 'row' }}
            align="center"
            gap={8}
            bg="white"
            p={8}
            borderRadius="lg"
            shadow="sm"
          >
            {link.image && (
              <Box
                flexShrink={0}
                width={{ base: '100%', md: '300px' }}
              >
                <Image
                  src={link.image}
                  alt={link.title}
                  borderRadius="md"
                  objectFit="cover"
                  width="100%"
                  height="auto"
                />
              </Box>
            )}
            <Stack
              spacing={4}
              flex={1}
            >
              <Heading size="xl">{link.title}</Heading>
              {link.description && (
                <Text
                  fontSize="lg"
                  color="gray.600"
                >
                  {link.description}
                </Text>
              )}
            </Stack>
          </Flex>

          {/* Seller Details Section */}
          <Box
            bg="white"
            p={6}
            borderRadius="lg"
            shadow="sm"
          >
            <Flex
              direction={{ base: 'column', md: 'row' }}
              align={{ base: 'start', md: 'center' }}
              gap={4}
            >
              <Box>
                <Text
                  fontSize="sm"
                  color="gray.500"
                  mb={1}
                >
                  Vendedor
                </Text>
                <Text
                  fontSize="lg"
                  fontWeight="medium"
                >
                  {link.customer.name}
                </Text>
              </Box>
              <Box ml={{ base: 0, md: 8 }}>
                <Text
                  fontSize="sm"
                  color="gray.500"
                  mb={1}
                >
                  CNPJ/CPF
                </Text>
                <Text fontSize="md">{maskDocument(link.customer.document_number)}</Text>
              </Box>
            </Flex>
          </Box>

          {/* Plans Section */}
          <VStack
            spacing={4}
            align="stretch"
          >
            <Heading size="lg">Escolha seu plano</Heading>
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 3 }}
              spacing={6}
            >
              {link.itens.map((item) => (
                <Card
                  key={item.id}
                  variant="outline"
                  _hover={{
                    transform: 'translateY(-4px)',
                    shadow: 'md',
                  }}
                  transition="all 0.2s"
                >
                  <CardHeader pb={0}>
                    <VStack
                      align="stretch"
                      spacing={2}
                    >
                      <Heading size="md">{item.plan.description}</Heading>
                      <Flex
                        justify="space-between"
                        align="center"
                      >
                        <Text
                          fontSize="2xl"
                          fontWeight="bold"
                          color="green.500"
                        >
                          {maskCurrency(Number(item.plan.amount))}
                        </Text>
                        <Badge colorScheme="blue">{RecurrencyPeriodDescription[item.plan.period]}</Badge>
                      </Flex>
                    </VStack>
                  </CardHeader>
                  <CardBody>
                    <VStack spacing={4}>
                      {/* Aqui você pode adicionar mais detalhes do plano se necessário */}
                      <Button
                        colorScheme="green"
                        size="lg"
                        width="100%"
                        onClick={() => handleSelectPlan(item.plan.id)}
                      >
                        Comprar
                      </Button>
                    </VStack>
                  </CardBody>
                </Card>
              ))}
            </SimpleGrid>
          </VStack>

          {/* Footer Section */}
          <Box
            textAlign="center"
            py={4}
          >
            <Text color="gray.500">Pagamento processado de forma segura</Text>
          </Box>
        </VStack>
      </Container>
      <CookieConsent onChange={consented} />

      <PurchaseModal
        isOpen={isOpen}
        onClose={onClose}
        planId={selectedPlanId}
      />
    </Box>
  );
}
