import { useToast } from '@chakra-ui/react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import api, { loginCustomerService, registerDevice, updateCustomerService } from 'services/api.service';
import jwt_decode from 'jwt-decode';
import { Capacitor } from '@capacitor/core';
import OneSignal from 'onesignal-cordova-plugin';
import { DateTime } from 'luxon';
import { Device } from '@capacitor/device';

export const AUTHSTORAGE_KEY = `asdasd1as32d1as6d54wq6d132dsg1r`;

type AuthContextType = {
  userData: AuthUserData;
  signIn: (args: {
    username: string;
    token?: string;
    history: any;
    password: string;
    callbackUrl?: string;
    captchaKey: string;
  }) => Promise<AuthUserData>;
  signOut: (history: any) => Promise<void>;
  updateUserData: () => void;
};

export type AuthUserData = {
  isAuth: boolean;
  authorization: string;
  name: string;
  email: string;
  id: string;
  balance: number;
  amountLocked: number;
  balancePending: number;
  on: string;
  features: {
    pix: boolean;
    billet: boolean;
    transfer: boolean;
    withdraw: boolean;
  };
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export default function useAuth() {
  return useContext(AuthContext);
}

export function getAuthStorage(): AuthUserData {
  if (typeof localStorage === `undefined`) {
    console.log(`localStorage is not defined`);
    return {} as AuthUserData;
  }
  try {
    const localStorageData = localStorage.getItem(AUTHSTORAGE_KEY);

    return localStorageData ? JSON.parse(localStorageData) : ({ isAuth: false } as AuthUserData);
  } catch (error) {
    console.error(`localStorage get error`, error);
    return {} as AuthUserData;
  }
}

export function setAuthStorage(value: AuthUserData): void {
  return localStorage.setItem(AUTHSTORAGE_KEY, JSON.stringify(value));
}

export function delAuthStorage(): void {
  localStorage.removeItem(AUTHSTORAGE_KEY);
  localStorage.removeItem('Authorization');
}

export function AuthProvider({ children }: any) {
  const toast = useToast();
  const [userData, setUserData] = useState<AuthUserData | null>(getAuthStorage() ?? ({} as AuthUserData));

  const storeUserData = async (data: {
    authorization: string;
    email: string;
    isAuth: boolean;
    id: string;
    name: string;
    balance: number;
    amountLocked: number;
    balancePending: number;
    features: any;
  }) => {
    setUserData({ ...data, on: DateTime.now().toISO() });
    setAuthStorage({ ...data, on: DateTime.now().toISO() });
    localStorage.setItem('Authorization', data.authorization);
    api.defaults.headers.Authorization = 'Bearer ' + data.authorization;
  };

  const signIn = useCallback(
    async ({
      username,
      password,
      token,
      history,
      callbackUrl = `/access/default`,
      captchaKey,
    }: {
      username: string;
      history: any;
      token?: string;
      password: string;
      callbackUrl?: string;
      captchaKey: string;
    }): Promise<AuthUserData> => {
      const authResponse = await loginCustomerService(username, password, captchaKey, token);
      toast({
        title: 'Sucesso',
        description: 'Login realizado com sucesso',
        status: 'success',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });

      const data = {
        authorization: authResponse.token,
        email: authResponse.email,
        isAuth: true,
        id: authResponse.id,
        name: authResponse.name,
        balance: authResponse.balance,
        amountLocked: authResponse.amountLocked,
        balancePending: authResponse.balancePending,
        features: authResponse.features,
        twoFaEnabled: authResponse.twoFaEnabled,
        on: DateTime.now().toISO(),
      };
      await storeUserData(data);

      if (Capacitor.isNativePlatform()) {
        OneSignal.login(authResponse.id);
        const id = await Device.getId();
        await registerDevice(id.identifier).catch((error) => {
          console.error(error);
        });
      }

      history.push(callbackUrl);
      return data;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const signOut = useCallback(async (history: any) => {
    try {
      setUserData({} as AuthUserData);
      setAuthStorage({} as AuthUserData);
      delAuthStorage();
      api.defaults.headers.Authorization = null;
      history.push(`/auth`);
    } catch (error) {
      console.error(error);
      throw error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateUserData = useCallback(async () => {
    if (!userData?.isAuth || (userData.on && DateTime.fromISO(userData.on) > DateTime.now().minus({ minutes: 1 }))) {
      return;
    }

    try {
      const authResponse = await updateCustomerService();

      const data = {
        authorization: authResponse.token,
        email: authResponse.email,
        isAuth: true,
        id: authResponse.id,
        name: authResponse.name,
        balance: authResponse.balance,
        amountLocked: authResponse.amountLocked,
        features: authResponse.features,
        balancePending: authResponse.balancePending,
        twoFaEnabled: authResponse.twoFaEnabled,
      };
      storeUserData(data);
    } catch (error) {
      throw error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userData.isAuth !== undefined) {
      if (userData?.authorization) {
        const token = jwt_decode(userData.authorization) as any;
        if (token.exp) {
          const now = new Date();
          const exp = new Date(token.exp * 1000);
          if (now > exp) {
            delAuthStorage();
            window.location.href = '/#/auth';
          }
        }
      } else {
        window.location.href = '/#/auth';
      }
    }
  }, [userData]);

  useEffect(() => {
    updateUserData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.isAuth]);

  return (
    <AuthContext.Provider
      value={{
        userData,
        signIn,
        signOut,
        updateUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
