// Chakra imports
import { Box, Flex, Heading, useColorModeValue, useToast, VStack } from '@chakra-ui/react';
// Custom components
import DefaultAuth from 'layouts/auth/Default';
// Assets
import { getVariable } from 'whitelabel';
import InputMaskForm from 'components/InputMask';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { validCpf } from 'services/validDocument';
import { onlyNumbers } from 'utils/string';
import { useEffect, useState } from 'react';
import InputForm from 'components/InputForm';
import CustomButton from 'components/CustomButton';
import { findOnboarding, NextOnboarding, nextOnboarding } from 'services/api.service';
import { useMutation } from '@tanstack/react-query';

function HolderRegister() {
  const loc = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(loc.search);
  const id = params.get('id');

  const textColor = useColorModeValue('brand.700', 'white');
  const toast = useToast();
  const [validatedCnpj, setValidatedCnpj] = useState(false);
  const { control, handleSubmit, setValue } = useForm<{
    document: string;
    name: string;
    email: string;
    phone: string;
    motherName: string;
    birthDate: string;
  }>();
  const { isLoading: isLoadingSave, mutateAsync: nextOnboardingMutate } = useMutation(
    ['nextOnboarding'],
    (data: NextOnboarding) => nextOnboarding(id, data),
  );
  const { isLoading, mutateAsync } = useMutation([], () => findOnboarding(id));
  useEffect(() => {
    mutateAsync().then((response) => {
      if (response.holder) {
        setValidatedCnpj(true);
        setValue('document', response.holder.document);
        setValue('name', response.holder.name);
        setValue('email', response.holder.email);
        setValue('phone', response.holder.phone);
        setValue('motherName', response.holder.motherName);
        setValue('birthDate', response.holder.birthDate?.split('T')[0]);
      }
    });
  }, [mutateAsync, setValue]);

  const handleFindCnpj = async (document: string) => {
    document = onlyNumbers(document);
    if (!document) {
      return;
    }
    if (!validCpf(document)) {
      toast({
        title: 'CPF inválido',
        status: 'error',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
      return;
    }

    setValidatedCnpj(true);
  };

  const handleNext = (data: any) => {
    nextOnboardingMutate({
      step: 'personal',
      personal: {
        ...data,
        document: onlyNumbers(data.document),
        phone: onlyNumbers(data.phone),
      },
    }).then(() => {
      history.push(`/auth/register/address?id=${id}`);
    });
  };

  return (
    <DefaultAuth illustrationBackground={getVariable('banner')} image={getVariable('banner')}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        my={{ base: 'auto', lg: '150' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '30px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '0', md: '4vh' }}
        flexDirection="column"
      >
        <Box w="full" alignItems="center" display="flex" flexDirection="column">
          <Heading color={textColor} fontSize="24px" mb="10px" mt={10}>
            Dados do titular
          </Heading>
          <VStack spacing={4} alignItems="flex-start" w="100%">
            {isLoading && <p>Carregando...</p>}
            {!isLoading && (
              <>
                <InputMaskForm
                  isDisabled={validatedCnpj}
                  control={control}
                  rules={{ required: 'Campo obrigatório' }}
                  placeholder="123.456.789-99"
                  label="CPF"
                  mask="999.999.999-99"
                  name="document"
                  type="tel"
                  onBlur={(e) => handleFindCnpj(e.target.value)}
                />
                <InputForm
                  control={control}
                  label="Nome"
                  name="name"
                  isDisabled={!validatedCnpj}
                  type="text"
                  placeholder="Informe seu nome"
                  rules={{ required: 'Campo obrigatório' }}
                />

                <InputForm
                  control={control}
                  label="Email"
                  name="email"
                  rules={{ required: 'Campo obrigatório' }}
                  isDisabled={!validatedCnpj}
                  placeholder="Informe seu e-mail"
                  type="email"
                />
                <InputForm
                  control={control}
                  label="Nome da mãe"
                  name="motherName"
                  rules={{ required: 'Campo obrigatório' }}
                  isDisabled={!validatedCnpj}
                  placeholder="Nome da mãe"
                  type="text"
                />
                <InputForm
                  control={control}
                  label="Data de nascimento"
                  name="birthDate"
                  rules={{ required: 'Campo obrigatório' }}
                  isDisabled={!validatedCnpj}
                  placeholder="Data de nascimento"
                  type="date"
                />
                <InputMaskForm
                  control={control}
                  label="Telefone"
                  placeholder="Informe seu telefone"
                  mask="(99) 99999-9999"
                  rules={{ required: 'Campo obrigatório' }}
                  isDisabled={!validatedCnpj}
                  name="phone"
                  type="tel"
                />
              </>
            )}
            <CustomButton
              isDisabled={!validatedCnpj}
              isLoading={isLoading || isLoadingSave}
              onClick={handleSubmit(handleNext)}
              variant="solid"
            >
              Continuar
            </CustomButton>
          </VStack>
        </Box>
      </Flex>
    </DefaultAuth>
  );
}

export default HolderRegister;
