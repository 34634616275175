import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import React, { useEffect, useMemo } from 'react';
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import { maskCurrency } from 'utils/number';
import MiniStatistics from './card/MiniStatistics';

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }: any, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || (defaultRef as any);

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <Checkbox ref={resolvedRef} {...rest} />
    </>
  );
});

export default function TableList(props: {
  columnsData: any[];
  itensData: any[];
  actions: any;
  pageCount?: number;
  setFilter: any;
  enableSelect?: boolean;
  setSelectedRows?: any;
  aggregateSelection?: {
    key: string;
    label: string;
  }[];
}) {
  const { columnsData, itensData, actions, pageCount, setFilter, enableSelect, setSelectedRows, aggregateSelection } =
    props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const tableData = useMemo(() => itensData, [itensData]);

  const tableInstance = useTable<{ id: string }>(
    {
      columns,
      manualPagination: true,
      manualSortBy: true,
      initialState: { pageIndex: 0, pageSize: 20 },
      data: tableData || [],
      autoResetPage: false,
      pageCount: pageCount || 0,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      enableSelect &&
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row, ...props }: any) => {
              return (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} isChecked={row.isSelected} select />
                </div>
              );
            },
          },
          ...columns,
        ]);
    },
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy, selectedRowIds },
  } = tableInstance;

  useEffect(() => {
    setFilter((prev: any) => ({
      ...prev,
      pageIndex,
      pageSize,
      orderBy: sortBy,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize, sortBy]);

  useEffect(() => {
    setSelectedRows &&
      setSelectedRows(
        tableData
          .map((item: any, i) => {
            if (selectedRowIds[i]) {
              return item.id;
            }
            return null;
          })
          .filter((item: any) => item !== null),
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIds]);

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  return (
    <>
      <Box overflowY="auto">
        <HStack>
          {aggregateSelection?.map(({ key, label }) => {
            return (
              <MiniStatistics
                name={label}
                value={maskCurrency(
                  tableData
                    .map((item: any, i) => {
                      if (selectedRowIds[i]) {
                        return item;
                      }
                      return null;
                    })
                    .filter((item: any) => item !== null)
                    .reduce((acc, cur) => {
                      return acc + Math.abs(+cur[key]);
                    }, 0) || 0,
                )}
              />
            );
          })}
        </HStack>
        {tableData?.length === 0 && (
          <Flex justify="center" align="center" h="100%" w="100%" minH={{ sm: '100px', lg: '200px' }} color="gray.500">
            <Text>Nenhum registro encontrado</Text>
          </Flex>
        )}
        {tableData?.length > 0 && (
          <Table {...getTableProps()} variant="striped" color="gray.500" mb="24px" overflow="hidden">
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        textAlign={column.id === 'actions' ? 'center' : 'left'}
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.500"
                      >
                        {column.render('Header') as string}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let data;
                      if (cell.column.id === 'actions') {
                        data = actions(cell.row.original);
                      } else {
                        data = (
                          <Text color={textColor} fontSize="sm" fontWeight="400">
                            {cell.render('Cell') as string}
                          </Text>
                        );
                      }

                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          p={1}
                          px={5}
                          fontSize={{ sm: '12px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        )}
      </Box>
      <Flex width="100%" textAlign="center" alignItems={'center'} justifyContent="center">
        <Flex
          alignItems="center"
          fontWeight="normal"
          direction={{
            base: 'column',
            md: 'row',
          }}
        >
          <HStack
            spacing={3}
            mb={{
              base: 3,
              md: 0,
            }}
            mr={{
              base: 0,
              md: 3,
            }}
          >
            <Button
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              size="xs"
              variant="outline"
              borderRadius="sm"
            >
              {'<<'}
            </Button>
            <Button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              size="xs"
              variant="outline"
              borderRadius="sm"
            >
              {'<'}
            </Button>
            <Button onClick={() => nextPage()} disabled={!canNextPage} size="xs" variant="outline" borderRadius="sm">
              {'>'}
            </Button>
            <Button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              size="xs"
              variant="outline"
              borderRadius="sm"
            >
              {'>>'}
            </Button>
          </HStack>
          <HStack spacing={3}>
            <span>
              Página{` `}
              <strong>
                {pageIndex + 1} de {pageOptions.length}
              </strong>
            </span>
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[20, 50, 100, 250, 500].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Exibir {pageSize}
                </option>
              ))}
            </select>
          </HStack>
        </Flex>
      </Flex>
    </>
  );
}
