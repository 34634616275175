import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Button,
  useToast,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import FormRemoteSelectInput, { SelectOptionType } from 'components/FormRemoteSelectInput';
import InputForm from 'components/InputForm';
import { queryClient } from 'index';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { createInsurance, findAllHolders, findAllInsurancesModels } from 'services/api.service';
import CreateHolder from './CreateHolder';
import { useMutation } from '@tanstack/react-query';
import SelectForm from 'components/SelectForm';
import { maskCurrency } from 'utils/number';
import CustomButton from 'components/CustomButton';

type CreateInsuranceForm = {
  startDate: string;
  endDate: string;
  holderId: SelectOptionType;
  optionCharge: SelectOptionType;
  modelId: SelectOptionType;
};

const CreateInsurrance = () => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, control, reset, getValues } = useForm<CreateInsuranceForm>();

  const { mutateAsync: listInsurances } = useMutation({
    mutationFn: (filter: string) => {
      return findAllInsurancesModels({ filter });
    },
  });

  const save = async (dataForm: CreateInsuranceForm) => {
    setLoading(true);
    createInsurance({
      ...dataForm,
      holderId: dataForm.holderId?.value,
      modelId: dataForm.modelId?.value,
    })
      .then(() => {
        onClose();
        queryClient.refetchQueries({
          queryKey: ['findAllInsurances'],
          type: 'active',
        });
        toast({
          title: 'Sucesso',
          description: 'Seguro adicionado com sucesso',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: 'Erro',
          description: err?.response?.data?.message || 'Erro ao criar Seguro',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  console.log(getValues());

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <>
      <CustomButton
        onClick={() => {
          onOpen();
          reset();
        }}
        variant="solid"
        leftIcon={<FaPlus />}
        colorScheme="brand"
      >
        Criar
      </CustomButton>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Criar seguro</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3}>
                <>
                  <FormRemoteSelectInput
                    control={control}
                    label="Segurado"
                    noOptionsMessage={() => {
                      return <>Nenhuma opção encontrada. </>;
                    }}
                    registerOptions={{
                      required: 'Modelo do seguro é obrigatório',
                    }}
                    loadDataFn={({ value, cb }) =>
                      findAllHolders({ filter: value }).then((retorno) => {
                        if (retorno?.registers?.length > 0) {
                          cb(
                            retorno.registers?.map((d: any) => ({
                              label: `${d.name} - ${d.document}`,
                              value: d.id,
                            })),
                          );
                        } else {
                          cb([]);
                        }
                      })
                    }
                    name="holderId"
                  />
                  <CreateHolder onClose={onClose} />
                </>
                <InputForm
                  control={control}
                  name="startDate"
                  placeholder="Data de início"
                  label="Data de início"
                  rules={{
                    required: 'Data de início é obrigatório',
                    validate: (value) => {
                      if (value <= getValues('endDate')) return true;
                      return 'Data de início não pode ser maior que a data de término';
                    },
                  }}
                  type="date"
                />
                <InputForm
                  control={control}
                  name="endDate"
                  placeholder="Data de término"
                  label="Data de término"
                  rules={{
                    required: 'Data de término é obrigatório',
                    validate: (value) => {
                      if (value >= getValues('startDate')) return true;
                      return 'Data de término não pode ser menor que a data de início';
                    },
                  }}
                  type="date"
                />

                <>
                  <FormRemoteSelectInput
                    control={control}
                    label="Modelo do seguro"
                    noOptionsMessage={() => {
                      return <>Nenhuma opção encontrada. </>;
                    }}
                    registerOptions={{
                      required: 'Modelo do seguro é obrigatório',
                    }}
                    loadDataFn={({ value, cb }) =>
                      listInsurances(value).then((retorno) => {
                        if (retorno?.registers?.length > 0) {
                          cb(
                            retorno.registers?.map((d: any) => ({
                              label: `${d.name} - Valor diário: ${maskCurrency(d.pricePerDay)}`,
                              value: d.id,
                            })),
                          );
                        } else {
                          cb([]);
                        }
                      })
                    }
                    name="modelId"
                  />
                </>
                <SelectForm
                  control={control}
                  name="optionCharge"
                  label="Tipo de cobrança"
                  options={[
                    {
                      label: 'Selecione...',
                      value: '',
                    },
                    {
                      label: 'Saldo da conta',
                      value: 'ACCOUNT_BALANCE',
                    },
                    {
                      label: 'Gerar cobrança',
                      value: 'GENERATE_CHARGE',
                    },
                  ]}
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button colorScheme="green" isLoading={loading} onClick={handleSubmit(save)}>
                  Adicionar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default CreateInsurrance;
