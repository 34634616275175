import ReactPixel from 'react-facebook-pixel';

const usePixel = () => {
  const eventTracker = (title: string, data: ReactPixel.Data) => {
    if (localStorage.getItem('cookieConsent') === 'true') {
      ReactPixel.track(title, data);
    }
  };
  const configure = (pixelId: string) => {
    console.log('configure', pixelId);
    ReactPixel.init(pixelId, {} as any, { autoConfig: true, debug: true });
    ReactPixel.pageView();
  };

  return { eventTracker, configure };
};
export default usePixel;
