import { useToast, Flex, VStack, Button, Link, Icon } from '@chakra-ui/react';
import InputForm from 'components/InputForm';
import useAuth from 'contexts/useAuth';
import React, { useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ForgetPassword from './ForgetPassword';
import { getVariable } from 'whitelabel';
import { FaWhatsapp } from 'react-icons/fa';
import { Capacitor } from '@capacitor/core';
import CustomButton from 'components/CustomButton';

const LoginInputs = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { control, handleSubmit, setValue } = useForm<{
    email: string;
    password: string;
    token?: string;
  }>();
  const [show2fa, setShow2fa] = useState(false);

  const toast = useToast();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { signIn } = useAuth();

  const handleLogin = async (dataLogin: { email: string; password: string; token?: string }) => {
    const captchaKey = await handleReCaptchaVerify();
    if (dataLogin.password.length < 6) {
      toast({
        title: 'Error',
        description: 'A senha deve ter no mínimo 8 caracteres',
        status: 'error',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
      return;
    }

    setLoading(true);

    await signIn({
      username: dataLogin.email,
      token: dataLogin.token,
      password: dataLogin.password,
      history,
      captchaKey,
    })
      .then(() => {
        setLoading(false);
        localStorage.setItem('email_credential', dataLogin.email);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status === 412) {
          setShow2fa(true);
        }
        console.error(error);
        toast({
          title: 'Error',
          description: error?.response?.data?.message || 'Erro ao realizar login',
          status: 'error',
          duration: 5000,
          position: 'top',
          isClosable: true,
        });
      });
  };

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(
    async (type = 'CUSTOMER_LOGIN') => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }

      return await executeRecaptcha(type);
      // Do whatever you want with the token
    },
    [executeRecaptcha],
  );

  const passwordRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    const savedLogin = localStorage.getItem('email_credential');

    if (savedLogin) {
      setValue('email', savedLogin);
      passwordRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isNative = Capacitor.isNativePlatform();

  return (
    <Flex
      zIndex="2"
      direction="column"
      w={{ base: '100%', md: '420px' }}
      maxW="100%"
      background="transparent"
      borderRadius="10"
      mx={{ base: 'auto', lg: 'unset' }}
      me="auto"
      mb={{ base: '20px', md: 'auto' }}
    >
      <form onSubmit={handleSubmit(handleLogin)}>
        <VStack spacing={5}>
          <InputForm
            control={control}
            label="E-mail:"
            name="email"
            autoFocus
            placeholder="seu@e-mail.com"
            beatify
            type="email"
          />
          <InputForm
            ref={passwordRef}
            control={control}
            label="Senha:"
            placeholder="*******"
            name="password"
            beatify
            type="password"
          />
          {show2fa ? (
            <InputForm
              control={control}
              label="2FA"
              placeholder="Caso ativo, digite o 2fa"
              name="token"
              beatify
              required={false}
              isRequired={false}
              rules={{
                required: false,
              }}
              type="text"
            />
          ) : null}
          <Button
            fontSize="md"
            loadingText="Entrando..."
            isLoading={loading}
            variant="solid"
            colorScheme="brand"
            fontWeight="900"
            w="100%"
            h="50"
            mb="24px"
            type="submit"
          >
            Entrar
          </Button>
        </VStack>
        <Flex justifyContent="start" align="center" mb="24px">
          <ForgetPassword />
        </Flex>
      </form>
      {isNative ? (
        <CustomButton variant="outline" onClick={() => history.push('/auth/register')}>
          Criar conta
        </CustomButton>
      ) : null}
      <Link
        fontSize="sm"
        mt={5}
        variant="link"
        size="sm"
        fontWeight="400"
        w="100%"
        mb="10px"
        href={getVariable('supportWhatsapp')}
        target="_blank"
        referrerPolicy="no-referrer"
        textAlign="center"
        verticalAlign={'middle'}
      >
        <Icon as={FaWhatsapp} /> Precisa de ajuda?
      </Link>
    </Flex>
  );
};

export default LoginInputs;
