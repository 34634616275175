import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  HStack,
  Button,
  useDisclosure,
  Box,
  Heading,
  Tooltip,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import React from 'react';
import { FaEye } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { findWithdrawel, getMovement } from 'services/api.service';
import { maskCurrency } from 'utils/number';
import SeeWithdrawel from 'views/access/withdrawals/components/SeeWithdrawel';
import { Balance, TypeRender } from '../balances';

const ShowBalance: React.FC<{ dado: Balance }> = ({ dado }) => {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: movementInfo } = useQuery(
    ['movementInfo', dado.referenceId, isOpen],
    () => getMovement(dado.referenceId),
    {
      enabled:
        isOpen &&
        !!dado.referenceId &&
        (dado?.type === 'MOVEMENT' || dado.type === 'LOCK' || dado.type === 'MOVEMENT_MAIN'),
    },
  );

  const { data: withdraw } = useQuery(
    ['movementInfo', dado.referenceId, isOpen],
    () => findWithdrawel(dado.referenceId),
    {
      enabled: isOpen && ['WITHDRAWAL', 'WITHDRAWAL_ANTECIPATION'].includes(dado?.type),
    },
  );

  return (
    <>
      <Button
        onClick={() => {
          onOpen();
        }}
        size="sm"
        rounded="sm"
        variant="outline"
        colorScheme="brand"
      >
        <FaEye />
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Consultar lançamento</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box>
                <Text fontWeight={'light'}>Cliente: </Text>
                <Text fontWeight={'semibold'}>{dado.Customer.name}</Text>
              </Box>
              <Box>
                <Text>Valor: </Text>
                <Text fontWeight={'semibold'}>{maskCurrency(dado.amount)}</Text>
              </Box>
              {dado.amountRefunded && (
                <Box>
                  <Text>Valor Devolvido: </Text>
                  <Text fontWeight={'semibold'}>{maskCurrency(dado.amountRefunded)}</Text>
                </Box>
              )}
              <Box>
                <Text>Descrição: </Text>
                <Text fontWeight={'semibold'}>{dado.description ?? '---'}</Text>
              </Box>
              <Box>
                <Text>Tipo: </Text>
                <Text fontWeight={'semibold'}>{TypeRender(dado.type)}</Text>
              </Box>
              <Box>
                <Text>Status: </Text>
                <Text fontWeight={'semibold'}>{dado.released ? 'Liberado' : 'Pendente'}</Text>
              </Box>
              <Box>
                <Text>Data de liberação: </Text>
                <Text fontWeight={'semibold'}>
                  {dado.releasedOn ? DateTime.fromISO(dado.releasedOn).toFormat('dd/MM/yyyy HH:mm') : '---'}
                </Text>
              </Box>
              <Heading mt={2} fontSize={'lg'}>
                Dados adicionais
              </Heading>
              {dado.type === 'WITHDRAWAL' && (
                <>
                  <HStack>
                    <SeeWithdrawel data={withdraw} />
                    <Text>Verificar saque</Text>
                  </HStack>
                </>
              )}
              {movementInfo && dado?.Customer?.id !== movementInfo?.Customer?.id && (
                <>
                  <Box>
                    <Text>Referênte de: </Text>
                    <Text fontWeight={'semibold'}>{movementInfo?.Customer.name}</Text>
                  </Box>
                </>
              )}
              {['MOVEMENT', 'LOCK', 'MOVEMENT_MAIN'].includes(dado.type) && movementInfo && dado && (
                <>
                  <Box>
                    <Text>Venda: </Text>
                    <Tooltip label="Ir para a Venda">
                      <Text
                        fontWeight={'semibold'}
                        borderBottom="1px #ccc dashed"
                        onClick={() => {
                          history.push(`/access/movement/${movementInfo?.id}`);
                        }}
                        cursor={'pointer'}
                      >
                        {movementInfo?.id}
                      </Text>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Text>Data da transação: </Text>
                    <Text fontWeight={'semibold'}>
                      {DateTime.fromISO(movementInfo?.createdAt).toFormat('dd/MM/yyyy HH:mm')}
                    </Text>
                  </Box>
                  <Box>
                    <Text>Valor da transação: </Text>
                    <Text fontWeight={'semibold'}>{maskCurrency(movementInfo?.amount)}</Text>
                  </Box>

                  <Box>
                    <Text>Retorno líquido </Text>
                    <Text fontWeight={'semibold'}>{((100 * +dado?.amount) / +movementInfo?.amount).toFixed(2)}%</Text>
                  </Box>
                </>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ShowBalance;
