import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import CustomButton from 'components/CustomButton';
import { FaCalculator } from 'react-icons/fa';
import { InstallmentCalculator } from './InstallmentCalculator';

export const InstallmentCalculatorModal = ({
  iconButton,
  onlyOnline,
}: {
  iconButton?: boolean;
  onlyOnline?: boolean;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {iconButton ? (
        <Tooltip label="Calcular taxas">
          <IconButton
            colorScheme="brand"
            icon={<FaCalculator size={20} />}
            onClick={onOpen}
            size="sm"
            variant="outline"
            rounded={'md'}
            aria-label="Calcular taxas"
          />
        </Tooltip>
      ) : (
        <CustomButton
          colorScheme="brand"
          leftIcon={<FaCalculator size={20} />}
          variant={'solid'}
          w="full"
          onClick={onOpen}
        >
          Calculadora de Taxas
        </CustomButton>
      )}
      {isOpen && (
        <Modal
          isOpen={isOpen}
          size={'2xl'}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Simulação de Taxas</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <InstallmentCalculator
                isOpen={isOpen}
                onlyOnline={onlyOnline}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
