import { TwoFaHelperProvider } from 'contexts/TwoFaCheckContext';
import { AuthProvider } from 'contexts/useAuth';
import { useEffect } from 'react';
import { getVariable } from 'whitelabel';
import { SplashScreen } from '@capacitor/splash-screen';
import { Capacitor } from '@capacitor/core';
import OneSignal from 'onesignal-cordova-plugin';
import { SafeArea } from '@capacitor-community/safe-area';
import '@capacitor-community/safe-area';

export default function MasterContext({ children }: any) {
  useEffect(() => {
    document.title = getVariable('name') + ' - Acesso';
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link['rel'] = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link['href'] = getVariable('favicon');
  }, []);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) SplashScreen.hide();
  }, []);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      SafeArea.enable({
        config: {
          customColorsForSystemBars: true,
          statusBarColor: '#F4F7FE',
          statusBarContent: 'dark',
          navigationBarColor: '#F4F7FE',
          navigationBarContent: 'dark',
        },
      });

      OneSignal.initialize(process.env.REACT_APP_ONE_SIGNAL_APP_ID);
      OneSignal.Notifications.addEventListener('click', async (e) => {
        let clickData = await e.notification;
        console.log('Notification Clicked : ' + clickData);
      });

      OneSignal.Notifications.requestPermission(true).then((success: Boolean) => {
        console.log('Notification permission granted ' + success);
      });
    }
  }, []);

  return (
    <div id="header">
      <AuthProvider>
        <TwoFaHelperProvider>{children}</TwoFaHelperProvider>
      </AuthProvider>
    </div>
  );
}
