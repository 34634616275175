// Chakra imports
import {
  Badge,
  Box,
  Card,
  CardHeader,
  Divider,
  HStack,
  Link,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import { getMovement } from 'services/api.service';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { maskCurrency } from 'utils/number';
import { Balance, MovementsType, MovementsTypeDescription } from 'types/Movement';
import { StatusRender } from '.';
import { TypeRender } from '../balances/balances';
import { maskDocument } from 'utils/document';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { queryClient } from 'index';

const BalancesForMovement: React.FC<{
  balances?: Balance[];
}> = ({ balances }) => {
  return (
    <TableContainer>
      <Table variant="striped" size="sm">
        <Thead>
          <Tr>
            <Th>Tipo</Th>
            <Th>Cliente</Th>
            <Th isNumeric>Valor</Th>
            <Th isNumeric>Valor estornado</Th>
            <Th>Data de Liberação</Th>
          </Tr>
        </Thead>
        <Tbody>
          {balances?.map((item) => (
            <Tr key={item.id}>
              <Td>{TypeRender(item.type)}</Td>
              <Td>{item.Customer?.name}</Td>
              <Td isNumeric> {maskCurrency(item?.amount)}</Td>
              <Td isNumeric>{item?.amountRefunded ? maskCurrency(item?.amountRefunded) : '---'}</Td>
              <Td>
                <HStack>
                  <Box mr={2}>
                    {item?.releasedOn
                      ? DateTime.fromISO(item?.releasedOn).toFormat(
                          !item?.deletedAt && !item?.released ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm',
                        )
                      : '---'}
                  </Box>
                  {item?.deletedAt ? (
                    <Badge colorScheme="red">CANCELADO</Badge>
                  ) : item?.released ? (
                    <Badge colorScheme="green">LIBERADO</Badge>
                  ) : (
                    <Badge colorScheme="orange">AGUARDANDO</Badge>
                  )}
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default function Movement() {
  const { id } = useParams<{ id: string }>();

  const { data } = useQuery(['getMovement', id], () => getMovement(id));
  const itens = useMemo(
    () => [
      {
        title: 'Id da transação',
        content: data?.transactionCode,
      },

      {
        title: 'Status',
        content: data?.status && StatusRender(data.status),
      },
      ...(data?.txId
        ? [
            {
              title: 'TxId',
              content: data?.txId,
            },
          ]
        : []),
      ...(data?.chargeId
        ? [
            {
              title: 'Cobrança',
              content: (
                <Link textDecoration={'underline'} href={`/#/access/charge/${data?.chargeId}`}>
                  {data?.chargeId}
                </Link>
              ),
            },
          ]
        : []),
      {
        title: 'Nome do cliente',
        content: data?.Customer?.name,
      },
      {
        title: 'Data da transação',
        content: DateTime.fromISO(data?.orderAt).toFormat(`dd/MM/yyyy - HH:mm:ss`),
      },
      {
        title: 'Valor Bruto',
        content: maskCurrency(data?.amount),
      },
      {
        title: 'Valor das Taxas',
        content: `${maskCurrency(data?.taxes)} (${
          +data?.feePercent || (100 - (+data?.amountLiquid / +data?.amount) * 100).toFixed(2)
        }%)`,
      },
      {
        title: 'Valor Liquído',
        content: maskCurrency(data?.amountLiquid),
      },
      {
        title: 'Valor Devolvido',
        content: maskCurrency(data?.amountRefunded),
      },
      {
        title: 'Tipo',
        content: MovementsTypeDescription[data?.type],
      },
      ...(['CREDIT_CARD', 'DEBIT_CARD'].includes(data?.type)
        ? [
            ...(data?.type === MovementsType.CREDIT_CARD
              ? [
                  {
                    title: 'Quantidade de parcelas',
                    content: `${data?.installmentCount}x`,
                  },
                ]
              : []),

            { title: 'Bandeira', content: data?.flagCard },
            {
              title: 'Cartão',
              content: data?.lastDigits ? data.firstDigits + '******' + data?.lastDigits : '---',
            },
          ]
        : []),

      ...(data?.Maquinetas?.number
        ? [
            {
              title: 'Id do Terminal',
              content: data?.Maquinetas?.number + ' - ' + data?.Maquinetas?.name,
            },
          ]
        : []),
      ...(data?.payerName
        ? [
            {
              title: 'Nome do pagador',
              content: data?.payerName,
            },
          ]
        : []),
      ...(data?.payerDocument
        ? [
            {
              title: 'Documento do pagador',
              content: maskDocument(data?.payerDocument),
            },
          ]
        : []),
    ],
    [data],
  );

  const handleRefresh = async () => {
    await queryClient.invalidateQueries({ queryKey: ['getMovement', id] });
  };

  return (
    <PullToRefresh onRefresh={handleRefresh}>
      <Box>
        <SimpleGrid mb="20px" columns={{ sm: 1 }}>
          <Card px={3}>
            <CardHeader>
              <Box fontSize="2xl" fontWeight="semibold">
                Detalhes da transação
              </Box>
              <small>Transação: #{data?.id}</small>
            </CardHeader>
            <SimpleGrid mb="20px" columns={{ sm: 1, md: 3, lg: 5, xl: 5 }} spacing={5}>
              {itens.map((item) => (
                <Box key={item.title} boxShadow="none" p="2" px={2}>
                  <Box fontSize="md" fontWeight="semibold" color="gray.600">
                    {item.title}
                  </Box>
                  <Box fontSize="md">{item.content}</Box>
                </Box>
              ))}
            </SimpleGrid>
            <Divider color="gray.300" />

            <Box py="3">
              <Box fontSize="md" mb={4} fontWeight="semibold" color="gray.600">
                Detalhes do Pagamento
              </Box>
              <BalancesForMovement balances={data?.balance} />
            </Box>
          </Card>
        </SimpleGrid>
      </Box>
    </PullToRefresh>
  );
}
