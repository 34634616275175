import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import CustomButton from 'components/CustomButton';
import InputNumber from 'components/InputNumber';
import { useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { FaCreditCard } from 'react-icons/fa';
import { getAllowCardRecharge } from 'services/api.service';
import PagSeguroCardPrivate from './PagSeguroCardPrivate';

const LoanCreditBalance = () => {
  const { isOpen, onOpen, onClose: onCloseModal } = useDisclosure();
  const { control, handleSubmit } = useForm<{
    value: number;
  }>();

  const [amount, setAmount] = useState(0);

  const { data: allowCardRecharge } = useQuery({
    queryKey: ['allowCardRecharge'],
    queryFn: () => getAllowCardRecharge(),
    refetchOnWindowFocus: false,
  });

  const onSubmit = (data: { value: number }) => {
    setAmount(data.value);
  };

  const onClose = () => {
    setAmount(0);
    onCloseModal();
  };

  return (
    <>
      {allowCardRecharge === true && (
        <CustomButton
          w="full"
          colorScheme="brand"
          variant="solid"
          leftIcon={<FaCreditCard size={20} />}
          onClick={onOpen}
        >
          Carregar Saldo
        </CustomButton>
      )}
      {amount > 0 && (
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA}
          container={{
            parameters: {
              badge: 'bottomright',
            },
          }}
          useEnterprise={true}
          scriptProps={{
            async: false, // optional, default to false,
            defer: false, // optional, default to false
            appendTo: 'head', // optional, default to "head", can be "head" or "body",
            nonce: undefined, // optional, default undefined
          }}
        >
          <PagSeguroCardPrivate
            method="CREDIT_CARD"
            amount={amount}
            isOpen={amount > 0}
            onClose={onClose}
          />
        </GoogleReCaptchaProvider>
      )}
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Carregar Saldo</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <InputNumber
                label="Valor"
                placeholder="Valor"
                control={control}
                name="value"
                autoFocus
                rules={{
                  required: 'Campo obrigatório',
                  min: {
                    value: 100,
                    message: 'Valor mínimo de R$ 100,00',
                  },
                }}
              />
              <Text
                fontSize="sm"
                color="gray.500"
              >
                Taxas serão cobradas no valor total do saldo carregado.
              </Text>
            </ModalBody>
            <ModalFooter>
              <CustomButton onClick={handleSubmit(onSubmit)}>Carregar</CustomButton>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default LoanCreditBalance;
