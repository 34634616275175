// Chakra imports
import { Box, SimpleGrid } from '@chakra-ui/react';

import { useMemo } from 'react';
import { findAllTerminals } from 'services/api.service';
import { Column } from 'react-table';
import { DateTime } from 'luxon';
import TableComponent from 'components/TableComponent';

export default function Terminals() {
  const columns = useMemo(
    () =>
      [
        {
          Header: 'Nome',
          accessor: 'name',
        },
        {
          Header: 'Serial',
          accessor: 'number',
        },
        {
          Header: 'Cliente',
          accessor: 'MaquinetasCustomers[0].Customer.name',
          disableSortBy: true,
        },
        {
          Header: 'Empresa',
          accessor: 'Company.name',
          disableSortBy: true,
        },
        {
          accessor: 'createdAt',
          Header: 'Data de criação',
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat('dd/MM/yyyy HH:mm:ss');
          },
        },

        {
          Header: 'Ações',
          accessor: 'actions',
        },
      ] as Column[],
    [],
  );

  // Chakra Color Mode
  return (
    <Box>
      <SimpleGrid mb="20px" columns={{ sm: 1 }} spacing={{ base: '20px', xl: '20px' }}>
        <TableComponent
          label="Máquinas"
          columnsData={columns}
          queryFn={findAllTerminals}
          queryKey="findAllTerminals"
          filterable={['customerId']}
          actions={() => {}}
          enableSelect={false}
        />
      </SimpleGrid>
    </Box>
  );
}
