import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  PinInput,
  PinInputField,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import CustomButton from 'components/CustomButton';
import React, { createContext, createRef, useContext, useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { useKeyPressEvent } from 'react-use';
import { validateUserCode } from 'services/api.service';

const pinInputProps = {
  _focus: {
    borderColor: `brand.500`,
    boxShadow: `none`,
  },
};

interface TwoFaCheckContextParams {
  requestTwoFa(): Promise<{
    id: string;
  } | null>;
}

const RenderTwoFactorInput: React.FC<{
  submit: (data: string) => Promise<void>;
  codeError: string;
  setCodeError: (value: string) => void;
  isLoading: boolean;
  isOpen: boolean;
}> = ({ submit, codeError, isLoading, setCodeError, isOpen }) => {
  useKeyPressEvent(`Enter`, submit as any);
  const firstPinInputRef = createRef<HTMLInputElement>();
  const [twoFa, setTwoFa] = useState(``);

  useEffect(() => {
    if (codeError && firstPinInputRef.current) {
      firstPinInputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeError]);

  const handleOK = (code: string) => {
    submit(code).then(() => {
      setTwoFa(``);
    });
  };

  useEffect(() => {
    if (isOpen) {
      firstPinInputRef.current?.focus();
    }
  }, [isOpen, firstPinInputRef]);

  return (
    <HStack w={`full`} justifyContent={`center`}>
      <PinInput
        placeholder="•"
        size={`lg`}
        value={twoFa}
        onChange={(v) => {
          setCodeError(undefined);
          setTwoFa(v);
        }}
        isInvalid={!!codeError}
        onComplete={handleOK}
        isDisabled={isLoading}
      >
        <PinInputField {...pinInputProps} ref={firstPinInputRef} autoFocus />
        <PinInputField {...pinInputProps} />
        <PinInputField {...pinInputProps} />
        <PinInputField {...pinInputProps} />
        <PinInputField {...pinInputProps} />
        <PinInputField {...pinInputProps} />
      </PinInput>
    </HStack>
  );
};

export const TwoFaCheckContext = createContext<TwoFaCheckContextParams>({} as any);

export const useTwoFaHelper = () => useContext(TwoFaCheckContext);

export const TwoFaHelperProvider = ({ children }) => {
  const [promiseFn, setPromiseFn] = useState<{
    resolve: (value: { id: string } | null) => void;
    reject: (reason?: any) => void;
  }>();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [codeError, setCodeError] = useState<string | undefined>();
  const { mutateAsync, isLoading } = useMutation(validateUserCode, {
    retry: false,
  });
  const toast = useToast();

  const requestTwoFa = (): Promise<{
    id: string;
  }> => {
    return new Promise<{
      id: string;
    }>(function handleConfirmation(resolve, reject) {
      setPromiseFn({ resolve, reject });
      onOpen();
    });
  };

  const handleOK = async (data: string) => {
    await mutateAsync(data)
      .then((res) => {
        if (res) {
          promiseFn?.resolve(res);
          onClose();
        } else {
          setCodeError(`Código inválido`);
        }
      })
      .catch((err) => {
        isAxiosError &&
          toast({
            title: `Erro`,
            isClosable: true,
            duration: 5000,
            description: err.response?.data?.message || `Erro ao validar código`,
            status: `error`,
          });
        setCodeError(`Código inválido`);
      });
  };

  const handleCancel = () => {
    promiseFn?.resolve(null);
    onClose();
  };

  const isMobile = window.innerWidth <= 768;

  return (
    <TwoFaCheckContext.Provider value={{ requestTwoFa }}>
      {children}
      {isOpen && !isMobile && (
        <Modal size={`md`} isOpen={isOpen} onClose={handleCancel}>
          <ModalOverlay />
          <ModalCloseButton />
          <ModalContent>
            <Box p={5}>
              <Text textAlign={`center`}>CÓDIGO DE SEGURANÇA</Text>
              <Divider my={5} />
              <Text fontSize={`sm`} mb={5}>
                Informe o seu código de segurança. Caso não possua, vá em Configurações de Segurança e ative a
                autenticação de senha.
              </Text>
              <RenderTwoFactorInput
                submit={handleOK}
                codeError={codeError}
                isLoading={isLoading}
                setCodeError={setCodeError}
                isOpen={isOpen}
              />

              <Divider my={5} />

              <HStack justifyContent={`end`}>
                <CustomButton leftIcon={<FiX />} onClick={handleCancel}>
                  Cancelar
                </CustomButton>
              </HStack>
            </Box>
          </ModalContent>
        </Modal>
      )}
      {isOpen && isMobile && (
        <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent pb="var(--safe-area-inset-bottom)">
            <DrawerCloseButton />
            <DrawerHeader>CÓDIGO DE SEGURANÇA</DrawerHeader>

            <DrawerBody>
              <Text mb={5}>
                Informe o seu código de segurança. Caso não possua, vá em Configurações de Segurança e ative a
                autenticação de senha.
              </Text>
              <RenderTwoFactorInput
                submit={handleOK}
                isLoading={isLoading}
                setCodeError={setCodeError}
                codeError={codeError}
                isOpen={isOpen}
              />
              <Divider my={5} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </TwoFaCheckContext.Provider>
  );
};
