/* eslint-disable */

import { Flex, Link, List, ListItem, Text, useColorModeValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getVariable } from 'whitelabel';
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';

export default function Footer() {
  const [isShow, setIsShow] = useState(true);
  let textColor = useColorModeValue('gray.400', 'white');
  let linkColor = useColorModeValue({ base: 'gray.400', lg: 'gray.400' }, 'white');

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }
    Keyboard.addListener('keyboardWillShow', (info) => {
      setIsShow(false);
    });

    Keyboard.addListener('keyboardWillHide', () => {
      setIsShow(true);
    });
    return () => {
      if (!Capacitor.isNativePlatform()) {
        return;
      }
      Keyboard.removeAllListeners();
    };
  }, []);
  return (
    <Flex
      alignSelf="center"
      zIndex="3"
      flexDirection={{
        base: 'column',
        lg: 'row',
      }}
      gap={{ base: '4', lg: '6' }}
      alignItems={{
        base: 'center',
        xl: 'start',
      }}
      justifyContent="space-between"
      px={{ base: '30px', md: '0px' }}
      pb="30px"
    >
      <Text
        color={textColor}
        textAlign={{
          base: 'center',
          xl: 'start',
        }}
        mb={{ base: '20px', lg: '0px' }}
      >
        {' '}
        &copy; {new Date().getFullYear()}
        <Text as="span" fontWeight="500" ms="4px">
          {getVariable('name')}
        </Text>
      </Text>
      <List display="flex">
        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link fontWeight="500" color={linkColor} href={getVariable('support')}>
            Suporte
          </Link>
        </ListItem>

        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link target="_blank" fontWeight="500" color={linkColor} href={getVariable('site')}>
            Site
          </Link>
        </ListItem>
        <ListItem>
          <Link fontWeight="500" color={linkColor} href="/#/auth/privacy/default">
            Política de Privacidade
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
}
