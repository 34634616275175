import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Box,
  useDisclosure,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import { ChargePerson, ChargeShipping } from 'types/Charge';
import { maskDocument } from 'utils/formatters';
const ShippingViewer: React.FC<{
  shipping: ChargeShipping;
}> = ({ shipping }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button size="sm" colorScheme="brand" rounded={'sm'} variant={'outline'} border={'sm'} onClick={() => onOpen()}>
        Exibir
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Endereço</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflow="auto">
            <Text fontSize="md" mb={4} fontWeight="bold">
              Dados de entrega
            </Text>
            <Text>
              <strong>Endereço: </strong>
              {shipping.street}
            </Text>
            <Text>
              <strong>Número: </strong>
              {shipping.number}
            </Text>
            <Text>
              <strong>Complemento: </strong>
              {shipping.complement}
            </Text>
            <Text>
              <strong>Bairro: </strong>
              {shipping.district}
            </Text>
            <Text>
              <strong>Cidade: </strong>
              {shipping.city}
            </Text>
            <Text>
              <strong>Estado: </strong>
              {shipping.state}
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="brand" mr={3} onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const Devedor: React.FC<{
  person?: ChargePerson;
  shipping?: ChargeShipping;
}> = ({ person, shipping }) => {
  return (
    <Box bg="gray.100" p={4} borderRadius="md" my={2}>
      <Text fontSize="md" mb={4} fontWeight="bold">
        Dados do pagador
      </Text>
      {person ? (
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Nome</Th>
                <Th>Documento</Th>
                <Th>E-mail</Th>
                <Th>Ip do usuário</Th>
                <Th>Dados de endereço</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr key={person.id}>
                <Td>{person.name}</Td>
                <Td>{maskDocument(person.document)}</Td>
                <Td>{person.email}</Td>
                <Td>{person.ip ?? '---'}</Td>
                {shipping ? <ShippingViewer shipping={shipping} /> : 'Não informado'}
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Text>Não informado</Text>
      )}
    </Box>
  );
};

export default Devedor;
